@use "src/Styles/_vars.scss" as c;

.groupe_container {
  background-color: c.$fond-page;
  padding: 0 7rem 2rem 7rem;
  &__title {
    .btn-blanc {
      margin-top: 5rem;
      height: 2.5rem;
      background-color: c.$fond-page-clair;
      color: c.$bleu-paris1;
      &:hover {
        background-color: c.$bleu-paris1 !important;
        color: white;
      }
    }
  }
  h1 {
    font-family: c.$font-gros-titres;
    color: c.$bleu-paris1;
    font-size: xx-large !important;
    padding-top: 4rem;
    padding-bottom: 2rem;
  }
  .groupe_main_section {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 5rem;
    .groupe_container_left {
      width: 30%;
      //----------- plutôt mettre une min-width pour le cas où il n'y a pas de groupes
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      h3 {
        font-size: large;
        padding: 1rem 0 1rem 0;
        text-transform: uppercase;
        color: c.$bleu-paris1;
        font-family: c.$font-sous-menu;
      }
      a {
        width: 90%;
        .evenement_container {
          width: 100%;
          background-color: c.$blanc;
          height: fit-content;
          margin: 0 0 1rem 0;
        }
      }
      .membership_container {
        width: 100%;
      }
      &__suggestion {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        &__button {
          display: flex;
          justify-content: center;
        }
        .btn-bleu {
          margin: 1rem 0;
        }
        .suggestions_mobile {
          display: none;
        }
        .suggestions_desktop {
          display: inline;
        }
      }
    }

    .groupe_container_right {
      width: 65%;
      box-shadow: 3px 4px 10px lightgrey;
      margin-left: 5%;
      background-color: c.$blanc;
      border-radius: 1rem;
      height: fit-content;
    }
  }
}
@media screen and (max-width: c.$medium) {
  .groupe_container .groupe_main_section .groupe_container_left {
    a {
      width: fit-content;
    }
  }
  .groupe_container {
    padding: 0 1rem 2rem;
    .groupe_main_section {
      flex-direction: column-reverse;
      .groupe_container_left {
        width: 100%;
        align-items: flex-start;
        &__suggestion {
          &__button {
            justify-content: flex-start;
          }
        }
      }
      .groupe_container_right {
        width: 100%;
        margin-left: 0;
        margin-bottom: 2rem;
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .groupe_container {
    &__title {
      flex-direction: column;
      h1 {
        padding-bottom: 0;
      }
      &__filtres {
        margin-top: 0 !important;
      }
    }
  }
}

@use "src/Styles/_vars.scss" as c;

.profil_show {
  background-color: c.$fond-page;
  // taille du menu et alignement en hauteur
  #header .menu__connected {
    height: 15rem;
    .profilPage__headerProfilBar {
      margin: 4rem 0;
    }
  }
  &__container__article.desktop {
    display: none;
  }
  // partie basse de la page
  &__container {
    padding: 13rem 7rem 2rem 7rem;
    display: flex;
    flex-direction: column;
    &-flex {
      margin-left: 7rem;
      display: grid;
      grid-auto-rows: auto 1fr;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 2rem;
      column-gap: 2rem;
      grid-template-areas:
        "formation experience"
        "competence experience";
      :nth-child(1) {
        grid-area: formation;
      }
      :nth-child(2) {
        grid-area: experience;
      }
      :nth-child(3) {
        grid-area: competence;
      }
    }
  }
  .btn-bleu {
    display: flex;
    align-self: center;
    margin: 4rem;
  }
}

.profil__item {
  height: fit-content;
  background-color: white;
  width: 30svw;
  box-shadow: 0px 2px 0.2px lightgrey;
  padding: 1rem;
  border-radius: 0.33rem;
  &__title {
    h1 {
      padding: 0.5rem 0 1rem 0;
      font-family: c.$font-gros-titres;
      font-size: xx-large !important;
      color: c.$bleu-paris1;
    }
  }
  &__container {
    img {
      height: 1rem;
      margin: 1svh 0;
    }
    &__block {
      h4 {
        font-size: large;
        font-weight: 700;
        margin-bottom: 0.5rem;
      }
      &-date {
        font-size: small;
        font-style: italic;
      }
      &-content {
        &-title {
          h5 {
            font-size: 1rem;
          }
        }
        &-line {
          margin: 0.25rem 0;
          h5,
          p {
            font-size: 0.85rem;
            line-height: normal;
          }
        }
      }
    }
    &-competence {
      border-radius: 2rem;
      border: 1px solid c.$bleu-paris1;
      width: fit-content;
      display: flex;
      align-items: center;
      height: fit-content;
      min-height: 2.5rem;
      margin: 0 2rem 0.5rem 0;
      padding: 0.5rem;
      // texte bouton compétence
      h4 {
        padding: 0 1rem;
        text-transform: uppercase;
        font-family: c.$font-global;
        font-weight: 700;
        margin-bottom: 0;
        color: c.$bleu-paris1;
        font-size: small;
      }
    }
  }
}

.highlight {
  color: c.$bleu-secondaire;
}

//modifications d'affichage en passant sur tablette
@media screen and (max-width: c.$medium) {
  .profil_show {
    #header .menu__connected {
      height: fit-content;
      background-color: c.$fond-page;
    }
    &__container {
      padding: 0 7rem 2rem 7rem;
    }
  }
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$small) {
  .profil_show {
    background-color: c.$fond-page;
    &__container {
      display: flex;
      padding: 0 10svw;
      justify-content: center;
      &-flex {
        display: flex;
        flex-direction: column;
        margin-left: 0;
      }
    }
  }
  .profil__item {
    width: 100%;
  }
}

@use "src/Styles/_vars.scss" as c;

.searchBar {
  position: relative;
  width: 100%;
  text-align: flex-start;
  margin-top: 3rem;
  margin-bottom: 10rem;
  padding: 0 12rem;
  .textContainer {
    font-size: 1.3rem;
  }
  //? Icône de recherche
  &__title {
    padding: 0.5rem 0 1rem 0;
    color: c.$blanc;
    font-family: c.$font-gros-titres;
    font-size: 4rem;
    text-align: left;
    font-weight: 600;
    margin-bottom: -1rem;
  }
  &__titleDisconnected {
    padding: 0.5rem 0 1rem 0;
    color: c.$blanc;
    font-family: c.$font-gros-titres;
    font-size: 4rem;
    text-align: left;
    font-weight: 600;
    margin-bottom: -1rem;
  }
  &__icon {
    position: relative;
    color: c.$gris-picto;
    font-size: 1.5rem;
    top: 2.5rem;
    display: flex;
    margin-left: 16%;
  }
  &__input {
    border-radius: 5rem;
    padding: 1rem 1rem 1rem 3rem;
    border: none;
    font-size: 1rem;
    width: 70%;
    // modification du placeholder pour tous les navigateurs
    &::-webkit-input-placeholder {
      /* Edge */
      color: c.$gris-picto;
      font-family: c.$font-global;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: c.$gris-picto;
      font-family: c.$font-global;
    }

    &::placeholder {
      color: c.$gris-picto;
      font-family: c.$font-global;
    }
  }
  &__text {
    color: white;
    margin-bottom: 0;
    width: 65%;
  }
}

@media screen and (max-width: c.$small) {
  .searchBar {
    padding: 0 2rem;
    margin-bottom: 0rem;
    &__titleDisconnected {
      font-size: 2rem;
      text-align: left;
    }
    &__text {
      width: 100%;
      padding-top: 0.5rem;
    }
    .textContainer {
      margin: 1rem 0 2rem;
    }
  }
}

@media screen and (max-width: c.$medium) {
  .searchBar {
    margin-top: 1rem;
    &__title {
      color: c.$bleu-paris1;
      font-size: large !important;
    }
  }
}

@use "src/Styles/_vars.scss" as c;

.brouillon__container {
  background-color: #fff;
  padding-right: 1vw !important;
  max-width: 90%;
  &__dialog {
    font-size: 14px;
    &-footer {
      margin-top: 1%;
      button.p-button.p-component.p-button-rounded.p-button-info {
        background: c.$bleu-paris1;
        border: 1px solid c.$bleu-paris1;
      }
      button.p-button.p-component.p-button-rounded.p-button-danger {
        background: transparent;
        color: c.$bleu-paris1;
        border: 1px solid c.$bleu-paris1;
      }
    }
    p {
      margin-left: 1vw;
    }
  }
}

.p-dialog .p-dialog-content {
  margin: 0rem 0rem 0rem 0rem;
  // padding-right: 1vw !important;
}

button.p-button-rounded {
  span {
    font-size: 14px;
  }
}

.p-toast-icon-close {
  border: none !important;
}

@media screen and (max-width: c.$small) {
  .brouillon__container {
    &__dialog {
      font-size: 12px;
      &-footer {
        margin-top: 2%;
      }
    }
  }
  button.p-button-rounded {
    margin-top: 2% !important;

    float: right;
    span {
      font-size: 10px;
    }
  }
}

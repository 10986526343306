@use "src/Styles/_vars.scss" as c;

.candidature_envoyee {
  .candidatures {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, auto));
    gap: 1rem 2.5rem;
    grid-template-rows: auto;
  }
  .candidature_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    h1 {
      font-family: c.$font-gros-titres;
      color: c.$bleu-paris1;
      font-size: xx-large !important;
    }
    .btn-alerte {
      margin-top: 1rem;
    }
  }
  .btn-bleu {
    background-color: c.$bleu-paris1;
    border-color: c.$bleu-paris1;
    margin-bottom: 4rem;
    margin-top: 2rem;
  }
  .candidature {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
  }
  .candidatures__bouton {
    display: flex;
    justify-content: center;
  }
  .candidatures {
    .offre {
      .offre_envoyee {
        color: red;
      }
    }
  }
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$small) {
  .candidature_envoyee {
    padding-left: 1rem;
    padding-right: 1rem;

    .candidature_title {
      padding-top: 4rem;
      padding-bottom: 2rem;
    }
  }
}
@media screen and (min-width: c.$small) {
  .candidature_envoyee {
    padding-left: 7rem;
    padding-right: 7rem;

    .candidature_title {
      padding-top: 4rem;
      padding-bottom: 2rem;
    }
  }
}

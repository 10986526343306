@use "src/Styles/_vars.scss" as c;

.search_global {
  h1 {
    margin-top: 2rem;
    font-family: "font-gros-titres";
    font-size: 1.75rem;
    color: c.$bleu-paris1;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
  }
  &_empty {
    // centre le texte + marge en haut et en bas
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
  }
  .search_offer_container,
  .search_event_container,
  .search_news_container {
    &_body {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(15rem, 20rem));
      gap: 1rem 3.7rem;
      grid-template-rows: auto;
      justify-content: center;
      margin-top: 3rem;
      .actualite_container {
        box-shadow: 2px 3px 6px lightgrey;
      }
      .offre {
        box-shadow: 2px 3px 6px lightgrey;
        &:hover {
          transition-duration: 0.2s;
          background-color: c.$blanc;
          cursor: pointer;
          transform: scale(1.01);
          box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
          -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
          -moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
        }
      }
    }
  }
}

@use "src/Styles/_vars.scss" as c;

// Override la card des formations
.exp-pro_card {
  max-width: 33svw;
  h4 {
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 0.5rem;
  }
}

// Override l'item des formations
.exp-pro_item {
  margin-top: 0;
  gap: 0;
  flex-direction: row;
}

// Override form des formations
.exp-pro_form {
  width: 45svw;
  margin-bottom: 2rem;
  .label_date {
    width: 100%;
    h2 {
      font-size: large;
      color: c.$bleu-paris1;
      margin: 0 0 0.5rem;
    }
  }
}

.exp-pro_localisation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#form_boutons {
  display: flex;
  width: 25%;
  justify-content: space-between;
}

.errorMessageEndDate,
.errorMessageCaracterLimits {
  font-size: small !important;
  padding: 0.5rem 0;
  margin-left: 0;
  color: c.$erreur !important;
}

.hidden_date {
  visibility: hidden;
}

@media screen and (max-width: c.$medium) {
  .exp-pro_card {
    max-width: 66svw;
  }
  .exp-pro_form {
    width: 66svw;
  }
}

@use "src/Styles/_vars.scss" as c;

.modal.modal_rejoindre_groupe {
    width: 50% !important;
}

.rejoindre_groupe {
    border: 1vh;
    padding-top: 5vh;
    width: 50%;
    &-content {
        color: c.$bleu-paris1;
        margin: 2rem 0 !important;
    }
    &-buttons {
        display: flex;
        justify-content: space-between;
        column-gap: 2rem;
        margin-top: 5vh;
        margin: 0 1vw;
    }
}
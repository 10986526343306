@use "src/Styles/_vars.scss" as c;

.dernieres_offres {
  background-color: c.$fond-page;
}
.linked_offers_container {
  padding: 0 7rem;
  &__title {
    margin-top: 1rem;
    h1 {
      margin-bottom: 1rem;
      font-family: "font-gros-titres";
      font-size: xx-large !important;
      color: c.$bleu-paris1;
      padding-bottom: 2rem;
    }

    h1::before {
      display: block;
      content: "";
      width: 100px;
      height: 2px;
      background-color: c.$bleu-paris1;
      margin-bottom: 1rem;
    }
  }
  &__offers {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 23rem));
    gap: 1rem 2.5rem;
    grid-template-rows: auto;
  }
}

@media screen and (max-width: c.$small) {
  .linked_offers_container {
    padding: 0 1rem;
  }
}

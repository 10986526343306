@use "src/Styles/_vars.scss" as c;

.modalQuestion {
  &__form {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__textarea {
      margin-bottom: 2rem;
    }

    &__error {
      color: c.$erreur;
      font-weight: bold;
    }
  }
}

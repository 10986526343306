@use "src/Styles/_vars.scss" as c;

.formation {
  // Affichage des formations
  &_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &_list {
    // Boutons d'ajout de nouvelles formations en fin de page
    &_boutons {
      display: flex;
      gap: 2rem;
      flex-direction: column;

      .btn-blanc {
        margin-top: 1rem;
        width: fit-content;
      }

      .btn-bleu {
        margin-top: 1rem;
        width: fit-content;
      }

      &-add {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        i {
          cursor: pointer;
        }
        .pi {
          font-size: 1.5rem;
        }
        label {
          cursor: pointer;
        }
        h1 {
          font-family: c.$font-sous-menu;
          padding-top: 2rem;
          padding-bottom: 2rem;
        }
        .btn-blanc {
          width: fit-content;
          svg {
            margin: 0;
          }
        }
        label {
          margin-left: 0.5rem;
          font-family: c.$font-global;
        }
      }
    }
  }

  // Card pour les formations
  &_card {
    max-width: 20svw;
    margin-bottom: 2svh;
    padding: 1rem;
    background-color: white;
    border-radius: 0.33rem;

    // Logo indiquant si la formation / le diplome est certifié(e)
    &_certified {
      width: 1rem;
      height: 1rem;
      margin-bottom: 0.5rem;
    }

    h4 {
      font-size: large;
      font-weight: 700;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: small;
      margin: 0;

      span {
        color: c.$bleu-secondaire;
      }
    }

    // Contenu descriptif de la formation / diplôme
    &_item {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 0.5rem;

      h5,
      h5 {
        margin-right: 0.5rem;
        font-size: medium;
      }
    }
  }

  // Formulaire modification / ajout d'une formation / diplôme
  &_form {
    margin-bottom: 2svh;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 30svw;

    // Header avec n° et bouton de suppression
    &_header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      h1 {
        font-family: c.$font-sous-menu;
        margin-bottom: 1rem;
      }

      .btn-blanc {
        width: fit-content;
        margin-top: -1rem;
        margin-left: 1rem;
        border: none;

        &:hover {
          border: c.$bleu-commun;
        }

        svg {
          margin: 0;
          color: black;
        }
      }
    }

    &_columns {
      display: flex;
      flex-direction: row;
      gap: 1rem;

      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
      }

      .field {
        font-family: c.$font-global;
        width: 45%;

        h2 {
          font-size: medium;
          color: c.$bleu-paris1;

          &.p-error {
            color: #e24c4c;
          }
        }
      }
    }
  }
}

.form-demo {
  .profilBarShow {
    margin: 2rem 0;
    width: 100%;
  }
  .counter {
    margin-bottom: 2rem;
    label {
      padding-left: 1rem;
    }
  }
  .text-field {
    font-family: c.$font-global;
  }
  .field {
    font-family: c.$font-global;
  }
  .field-checkbox {
    margin: 0 0 1rem 0;
    label {
      margin: 0.5rem;
      font-family: c.$font-global;
    }
  }

  .form_bouton_suppr {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    h1 {
      font-family: c.$font-sous-menu;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
    .btn-blanc {
      width: fit-content;
      margin-top: -0.8rem;
      margin-left: 1rem;
      border: none;
      background-color: c.$fond-page;
      svg {
        margin: 0;
        color: black;
      }
    }
    label {
      margin-left: 0.5rem;
    }
  }
}
.p-inputtext.p-component {
  min-width: 100% !important;
  border: none;
}

.counter label {
  font-family: c.$font-global;
}

.field_checkbox label {
  margin-left: 2rem;
}

@media screen and (max-width: c.$medium) {
  .formation {
    &_card {
      max-width: 100%;
    }

    &_form {
      width: 66svw;
      margin-bottom: 2rem;

      &_columns {
        flex-direction: column;
        width: 100%;

        .field {
          width: 100%;

          .MuiFormControl-root.MuiTextField-root {
            width: 100%;
          }
        }
      }
    }
  }
}

.validation {
  font-size: small;
  color: red;
}

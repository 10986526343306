@use "src/Styles/_vars.scss" as c;

.ALaUneCard_container {
  min-width: 100%;
  min-height: 25rem;
  box-shadow: 0.5px 2px 6px grey;
  display: flex;
  background-color: c.$blanc;
  &__left {
    min-width: 50%;
    padding: 2rem 3.5rem 1rem;
    &__titleCard {
      font-family: c.$font-gros-titres;
      font-size: 3rem;
      color: c.$bleu-paris1;
    }
    &__titleActu {
      font-family: c.$font-gros-titres;
      font-size: 1.5rem;
      color: c.$bleu-paris1;
      margin-bottom: 0.5rem;
    }
    &__date {
      font-family: c.$font-global;
      color: c.$gris-picto;
      font-size: 1.125rem;
      margin-bottom: 0.7rem;
    }
    &__text {
      width: 100%;
      font-family: c.$font-global;
      color: c.$bleu-paris1;
      font-size: 1rem;
      margin-bottom: 2rem;
      // Make text overflow with ellipsis
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      // Make text scrollable
      overflow-y: auto;
      max-height: inherit;
      // hide scrollbar
      scrollbar-width: none;
    }
    &__link {
      cursor: pointer;
      display: flex;
      flex-direction: row-reverse;
      color: c.$bleu-secondaire !important;
      font-family: c.$font-global;
      font-weight: 700;
    }
  }
  &__right {
    min-width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .image__bg {
      position: absolute;
      width: 50%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      filter: blur(4px);
      opacity: 0.6;
    }
    img {
      z-index: 900;
      max-width: 100%;
      max-height: 15rem;
      object-fit: cover;
      font-weight: 700;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }
  }
}

@media screen and (max-width: 830px) {
  .ALaUneCard_container {
    display: block;
    &__right {
      display: none;
    }
  }

  .carousel-control-next {
    display: none !important;
  }
  .carousel-control-prev {
    display: none !important;
  }
}

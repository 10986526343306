@use "src/Styles/_vars.scss" as c;

.groupe_container__title {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: c.$fond-page;

  &__filtres {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 2rem;
    .btn-blanc {
      margin: 0;
      margin-left: 0.5rem;
      margin-top: 0.5rem;
    }
    .searchBar_groupe_finder_icon {
      position: relative;
      color: c.$gris-picto;
      font-size: 1.5rem;
      top: 1.4rem;
      margin-left: 0.7rem;
    }
    .membership_input_text {
      font-family: c.$font-global;
      margin-top: -2rem;
      padding-left: 2.5rem;
      border-radius: 1rem;
      border: 1px solid c.$gris-picto;
    }
    .p-dropdown.p-component.p-inputwrapper {
      margin-left: 1rem;
      margin-top: 1rem;
      width: 14rem !important;
    }
    .p-inputtext.p-component {
      box-shadow: none;
    }
  }
}
.groupe_container_finder_right {
  width: 65%;
  margin-left: 5%;
}

@media screen and (max-width: c.$medium) {
  .groupe_container_finder_right {
    width: 100%;
    margin-left: 0;
    margin-bottom: 2rem;
  }
}

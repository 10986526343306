@use "src/Styles/_vars.scss" as c;

.charte_groupes_container {
  margin: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  &_exclamation {
    width: 5rem;
    height: 5rem;
    color: red;
    margin-bottom: 1.5rem;
  }
  &_text {
    max-width: 30rem;
    overflow-wrap: break-word;
  }
  &_display {
    margin: 1.5rem;
    color: c.$bleu-secondaire !important;
    &:hover {
      text-decoration: underline !important;
      cursor: pointer;
    }
  }
  &_charte {
    //? Add vertical and horizontal scroll
    max-width: 50vw;
    max-height: 50vh;
    height: 50vh;
    box-shadow: 0 0 0.25rem 0.05rem rgba(0, 0, 0, 0.2);
    display: block;
    overflow-y: scroll;
    padding: 0.5rem;
    // white-space: nowrap;
    // overflow-x: scroll;
    width: 100%;
    pre {
      display: inline-block;
    }
  }
  &_conditions {
    display: flex;
    flex-direction: row;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    &_label {
      margin-left: 0.5rem;
    }
  }
}

@use "src/Styles/_vars.scss" as c;

.not_desktop {
  .btn-bleu {
    background-color: c.$bleu-secondaire;
    border-color: c.$bleu-secondaire;
  }
}
.annuaire_search_bar {
  &__container {
    &__top {
      &__boxes {
        &__box {
          width: 14rem;
          overflow: hidden;
          .p-multiselect.p-component.p-inputwrapper.box__input.blanc {
            width: 100%;
          }
          .p-inputtext.p-component {
            width: 90%;
            margin-left: -0.55rem;
          }
        }
      }
    }
    &__bottom {
      position: relative;
    }
  }
}

.search_bar_refresh__annuaireEntreprise {
  position: absolute;
  right: 0;
  top: 30%;
}

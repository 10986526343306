@use "src/Styles/_vars.scss" as c;

.searchContainer {
  background-color: c.$blanc;
  font-family: c.$font-gros-titres;
  padding: 1rem 2rem 0 1rem;
  border-bottom: 0.1rem solid c.$gris;
  border-top: 0.1rem solid c.$gris;
  border-left: 0.1rem solid c.$gris;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  &__title,
  &__subtitle {
    color: c.$bleu-paris1;
  }
  &__title {
    font-weight: bold;
    font-size: 1.2rem;
  }
  &__subtitle {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  &__avatars {
    padding: 1rem 0;
    &__avatar {
      width: 2rem;
      height: auto;
      margin-right: 0.5rem;
    }
  }
  &__sentences {
    padding: 0.5rem 0 0;

    &__sentence {
      color: c.$bleu-secondaire;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      font-size: 1rem;

      & svg {
        margin-right: 0.5rem;
      }
    }
  }

  .btn-bleu,
  .btn-blanc {
    margin: 1rem 0;
    text-transform: uppercase;
  }

  &__btns {
    margin: 2rem 0 0;
    &__inputs {
      font-size: 0.9rem;
      margin-top: 4rem;
      &__radios {
        margin: 2rem 0;
        & input,
        & label {
          margin-right: 0.5rem;
        }

        &__mainLabel {
          display: block;
          margin: 1rem 0;
        }
      }

      &__searchAndRedo {
        display: flex;
        margin-top: 3rem;

        & .btn-blanc {
          margin-right: 1rem;
        }
      }
    }

    &__finder {
      border: 0.1rem solid c.$gris;
      border-radius: 2rem;
      padding: 0.1rem 1rem;
      width: 100%;
      font-size: 1.5rem;
      box-shadow: 0px 2px 4px 0px #00000040;

      & svg {
        margin-top: -0.7rem !important;
      }

      & .p-inputtext {
        margin-left: 0.5rem;
        padding-left: 1rem !important;
      }

      &__listOfCities {
        position: absolute;
        background-color: c.$blanc;
        padding: 0.2rem;

        &__searchedCity {
          &:nth-child(even) {
            background-color: c.$gris-bordure;
          }
          &:nth-child(odd) {
            background-color: c.$blanc;
          }

          &__city {
            // border-bottom: 0.1rem solid c.$gris;
            font-size: 0.9rem;
            margin-bottom: 0;
            padding: 0.2rem;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@use "src/Styles/_vars.scss" as c;

.annuaire_search_bar {
  &__container {
    &__bottom {
      ul {
        li {
          .p-multiselect-label-container {
            overflow: hidden;
          }
        }
      }
    }
  }

  @media screen and (max-width: c.$medium) {
    &__container {
      &__top {
        &__boxes {
          display: flex;
          align-items: center;
          &__box {
            width: 100%;
          }
          .p-component {
            width: 100%;
          }
          .box__input {
            border: none;
            border-radius: 2rem !important;
          }
        }
      }
    }
  }
  @media screen and (max-width: c.$tiny) {
    .annuaire_search_bar {
      width: 80% !important;
    }
  }
}

@use "src/Styles/_vars.scss" as c;

.parametres_site {
  &__container {
    padding: 4rem 7rem 2rem;
    background-color: c.$fond-page;
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h1 {
        font-family: c.$font-gros-titres;
        color: c.$bleu-paris1;
        font-size: xx-large !important;
      }
      &__filtre {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
    &__content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .btn-bleu {
        margin: 2rem 0 3rem;
      }

      &__left {
        margin-top: 2rem;
        width: 48%;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;

        &__item {
          padding: 1rem 0.5rem;
          margin-bottom: 1rem;
          background-color: c.$blanc;
          display: flex;
          justify-content: space-between;
          color: c.$bleu-paris1;
          &__icons {
            color: c.$bleu-secondaire;
            svg {
              margin-left: 1rem;
              cursor: pointer;
            }
          }
        }
        &__item.small {
          width: 60%;
          align-self: flex-end;
        }
        &__item.small.error {
          width: 60%;
          align-self: flex-end;
          color: c.$erreur;
          background-color: transparent;
          padding: 0;
          position: relative;
          top: -1rem;
          p {
            margin: 0;
          }
        }
        &__item.small.input {
          width: 60%;
          align-self: flex-end;
          padding: 0;
          background-color: c.$fond-page;
          margin-bottom: 1rem;
        }
        .p-inputtext.p-component {
          height: 2.9rem;
        }
        .p-inputgroup {
          background-color: c.$fond-page;
          border-radius: 0.33rem;
          .p-button.p-component {
            background-color: c.$bleu-paris1;
            color: white;
            height: 3rem;
          }
        }
      }
      &__right {
        margin-top: 2rem;
        width: 48%;
        background-color: c.$blanc;
        height: fit-content;
        padding: 1rem 1rem 0.5rem;
        &__item {
          margin-bottom: 0.5rem;
          svg {
            color: c.$bleu-secondaire;
            margin-right: 1rem;
          }
          svg.small {
            margin-right: 1.8rem;
          }
        }
      }
    }
  }
}

@use "src/Styles/_vars.scss" as c;

.annuaire__container__cards.alumni {
  .etudiant {
    background-color: c.$fond-page-clair !important;
    max-height: 26rem;
    height: fit-content;
    .annuaire_etudiant__container__description__bottom {
      padding-bottom: 0;
    }
    .annuaire_etudiant__container__description__alumni {
      display: flex;
      height: 2.8rem;
      align-items: center;
      padding-top: 0.5rem;
    }
  }
}

@use "src/Styles/_vars.scss" as c;

.management_new_event_offer_filtres {
  display: flex;
  flex-direction: column;
  &__top {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;

    .membership_input_search {
      margin-left: 1rem;
      width: 14rem;
    }

    &__createBtn {
      margin: 0 0 0 1rem;
      min-width: fit-content;
    }
    &__date {
      display: flex;
      align-items: center;
      align-content: center;
      background: #ffffff;
      border-radius: 1rem;
      height: 2.5rem;
      margin-top: 1rem;
      margin-left: 1rem;
      width: 100%;
      svg {
        padding-right: 0.5rem;
        box-sizing: unset;
        cursor: pointer;
        color: #6c757d;
        width: 1.3rem;
        height: 1.3rem;
      }
      .react-datepicker-wrapper {
        background: transparent;
        border: 0 none;
        input {
          width: 100%;
          text-align: center;
          color: #6c757d;
          font-family: "font-global" !important;
          font-size: medium !important;
          transition: background-color 0.2s, color 0.2s, border-color 0.2s,
            box-shadow 0.2s;
          border-radius: 1rem;
          box-shadow: none;
          height: 2.5rem;
          border: none;
        }
        input::placeholder {
          color: #6c757d;
          opacity: 1;
        }
      }
      &.warning {
        input {
          color: red;
        }
      }
    }
    &__refresh {
      color: c.$bleu-paris1;
      margin: 0 1rem;
      cursor: pointer;
      display: flex;
    }
    .membership_input_text {
      font-family: c.$font-global;
      margin-top: -2.5rem;
      padding-left: 2.5rem;
      border-radius: 2rem;
    }
    .searchBar_groupe_finder_icon {
      position: relative;
      color: c.$gris-picto;
      font-size: 1.5rem;
      top: 1.6rem;
      margin-left: 0.7rem;
    }
    .btn-blanc {
      height: 2.5rem;
    }
  }
}

@media screen and (max-width: c.$medium) {
  .management_new_event_offer_filtres {
    &__top {
      flex-direction: column;
      &__date {
        margin-left: 0;
      }
      &.p-dropdown {
        width: 100%;
      }
    }
    &__bottom {
      .mass_actions_buttons {
        flex-direction: column;
        button {
          margin-bottom: 0.2rem;
        }
      }
    }
  }
}

@media screen and (max-width: c.$normal) {
  .management_new_event_offer_filtres {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 1rem;
    div,
    li {
      margin-left: 0 !important;
    }
  }
}

@use "src/Styles/_vars.scss" as c;

.form_formation {
  padding: 0rem;
}

fieldset {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  .btn-blanc {
    border-radius: 3rem;
    height: 3rem;
    width: 3rem !important;
    margin-top: 1.4rem;
    margin-left: 1rem;
  }
  label {
    font-family: c.$font-global;
    width: 90%;
    .p-inputtext.p-component {
      width: 100%;

      height: 2.5rem;
    }
    h1 {
      padding-top: 1rem !important;
      font-family: c.$font-sous-menu;
    }
  }
}
.card {
  display: flex;
  justify-content: flex-start;
  .competences {
    flex-wrap: wrap;

    .competence {
      border-radius: 2rem;
      border: 1px solid c.$bleu-paris1;
      width: fit-content;
      display: flex;
      align-items: center;
      height: 2.5rem;
      margin: 0 2rem 0.5rem 0;

      // texte bouton compétence
      h4 {
        padding: 0 1rem;
        text-transform: uppercase;
        font-family: c.$font-global;
        font-weight: 700;
        margin-bottom: 0;
        color: c.$bleu-paris1;
        font-size: small;
      }
    }
  }
}

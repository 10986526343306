@use "src/Styles/_vars.scss" as c;

.app {
  background-color: c.$blanc;
}
::placeholder {
  color: c.$gris-search !important;
}
h1 {
  font-size: medium;
}

h2 {
  padding: 0.5rem;
}

p {
  font-family: c.$font-global !important;
}
ul {
  margin-bottom: 0 !important;
  padding-left: 0 !important;
}
a {
  text-decoration: none !important;
  color: unset !important;
}

.app__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .loaderContainer {
    height: unset !important;
  }
}

.border {
  border: none !important;

  h1 {
    font-size: medium !important;
  }

  h2 {
    padding: 0.5rem;
  }

  p {
    font-family: c.$font-global !important;
  }
  ul {
    margin-bottom: 0 !important;
    padding-left: 0 !important;
  }
  a {
    text-decoration: none !important;
    color: unset !important;
  }

  .border {
    border: none !important;
  }

  // permet d'avoir le trait au dessus des titres des pages
  .h1_trait_dessus::before {
    display: block;
    content: "";
    width: 100px;
    height: 2px;
    background-color: c.$bleu-paris1;
    margin-bottom: 1rem;
  }

  // permet d'avoir le trait au dessous des titres dans le footer
  .h1_trait_dessous::before {
    display: block;
    content: "";
    width: 20px;
    height: 2px;
    background-color: c.$blanc;
    // margin-top: 2rem;
    position: relative;
    top: 2rem;
  }

  // style de la checkbox
  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: c.$bleu-paris1 !important;
    background: c.$bleu-paris1 !important;
    &:hover {
      border-color: c.$bleu-paris1 !important;
    }
  }
  .p-checkbox.p-checkbox-checked {
    box-shadow: none !important;
  }
  .p-checkbox-box.p-focus {
    box-shadow: none !important;
    border-color: #ced4da !important;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: c.$bleu-paris1 !important;
    box-shadow: none !important;
  }

  // style de l'avatar
  .p-avatar.p-avatar-xl {
    height: 5rem !important;
    width: 5rem !important;
  }

  // style du bouton rafraichir dans les barres de recherche
  .search_bar_refresh {
    i {
      color: c.$bleu-paris1;
      cursor: pointer;
    }
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }

  // style du bouton sauvegarder la recherche dans les barres de recherche
  .search_bar_save {
    display: flex;
    justify-content: flex-end;
    background-color: c.$fond-page;
    .btn-bleu.left {
      margin: 1rem 0 0 0;
    }
  }

  @media screen and (max-width: c.$small) {
    .p-menuitem-text {
      display: unset;
    }
  }

  // ------------------------style de la navbar
  .styleNavBarActive {
    .navBarProfil {
      display: flex;
      justify-content: center;
      &__tabMenu {
        display: flex;
        justify-content: center;
      }

      ul.p-tabmenu-nav.p-reset {
        border: none !important;
        overflow: hidden;
        border-radius: 0.8rem;

        .p-tabmenuitem {
          width: 13rem;
          border-left: 1px solid lightgray;
          text-align: center;
          a {
            flex-direction: column;
            padding: 1.5rem 1rem !important;
            border: none !important;
            background-color: c.$blanc;
            box-shadow: none !important;
            .p-menuitem-icon {
              color: c.$gris-picto;
            }
            .p-menuitem-text {
              margin-top: 0.5rem;
              color: c.$gris-picto;
              font-family: c.$font-sous-menu;
              font-size: c.$font-size-small;
              // width: 10rem;
              &:hover {
                color: c.$bleu-paris1;
              }
            }
          }
          &:first-child {
            border-left: none;
          }
        }
        .p-tabmenuitem.p-highlight {
          .p-menuitem-link {
            background-color: c.$bleu-paris1 !important;
            color: white !important;
            border: none;
            border-radius: 0rem !important;
            box-shadow: none;
            .p-menuitem-text,
            .p-menuitem-icon {
              color: white;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: c.$medium) {
    .styleNavBarActive {
      .navBarProfil {
        padding: 0;
        ul.p-tabmenu-nav.p-reset {
          width: 97%;
          overflow: unset;
          border-radius: unset;
          .p-tabmenuitem a {
            padding: 0.5rem 0.5rem 1rem !important;
          }
        }
      }
    }
    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
      margin-right: 0rem;
    }
  }

  // modifications d'affichage navBar pour mobile
  @media screen and (max-width: c.$medium) {
    .styleNavBarActive {
      .navBarProfil {
        padding: 0;
        .p-scrollpanel-content {
          width: 100%;
          overflow: unset;
        }
        ul.p-tabmenu-nav.p-reset {
          width: 100%;
          overflow-x: scroll;
          overflow-y: hidden;
          .p-tabmenuitem {
            width: fit-content;
            min-width: 11rem;
          }
          .p-tabmenuitem.p-highlight {
            .p-menuitem-link {
              background-color: c.$blanc !important;
              color: white !important;
              border: none;
              border-radius: 0rem !important;
              .p-menuitem-text,
              .p-menuitem-icon {
                color: c.$bleu-paris1;
              }
            }
          }
        }
      }
    }
  }

  //? Effet zoom et shadow au hover sur une card (Ajouter la classe card pour se faire)
  .cardHover {
    transition-duration: 0.2s;
    margin-bottom: 2rem;
    box-shadow: 2px 3px 6px lightgrey;
    &:hover {
      cursor: pointer;
      transform: scale(1.01);
      box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
    }
  }

  // modification des composants primereact

  .p-button.p-component.p-confirm-popup-accept.p-button-sm {
    background: c.$bleu-paris1;
    border-radius: 30px;
    color: white;
    border: 1px solid c.$bleu-paris1;
    width: 6rem;
    height: 3rem;
  }
  .p-button.p-component.p-confirm-popup-reject.p-button-sm.p-button-text {
    border: 1px solid c.$bleu-paris1;
    box-sizing: border-box;
    border-radius: 30px;
    width: 6rem;
    height: 3rem;
    color: c.$bleu-paris1;
  }

  // --------- composant file upload
  .p-fileupload.p-fileupload-advenced.p-component {
    display: flex !important;
  }
  .p-button.p-fileupload-choose.p-component {
    background-color: c.$fond-page !important;
    border-radius: unset !important;
    color: c.$bleu-paris1;
    border: unset !important;
    font-size: small;
    &:hover {
      color: c.$bleu-paris1 !important;
      background-color: c.$fond-page !important;
    }
    &:focus {
      border: unset !important;
    }
  }

  .p-button.p-fileupload-choose.p-component:hover {
    cursor: pointer;
  }
  .p-button.p-component.p-disabled {
    color: c.$gris-picto;
    &:hover {
      cursor: pointer;
    }
  }

  .p-fileupload .p-fileupload-content {
    border: none !important;
    background: transparent !important;
    height: fit-content;
    padding: 0 !important;
    font-size: small;
    .p-button.p-button-icon-only {
      width: 2rem;
      height: 2rem;
      display: none;
    }
    .p-progressbar.p-component.p-progressbar-determinate {
      display: none;
    }
  }
  .p-fileupload.p-fileupload-advanced.p-component {
    margin-top: 0.5rem;
  }
  .p-fileupload-buttonbar {
    .p-button.p-component.p-disabled {
      .p-button-icon.p-c.p-button-icon-left.pi.pi-upload {
        display: none;
      }
      .p-button-icon.p-c.p-button-icon-left.pi.pi-times {
        display: none;
      }
    }
    .p-button-icon.p-button-icon-left.p-clickable.pi.pi-fw.pi-plus {
      display: none;
    }
    .p-button.p-component {
      margin-bottom: 0.5rem;
      padding: 0.5rem;
      font-size: small;
      &:hover {
        background-color: transparent !important;
        color: c.$bleu-paris1 !important;
      }
    }
    .p-button.p-component.bouton_valider {
      background-color: c.$vert-groupe !important;
      color: white;
      width: 2rem;
      height: 2rem;
      &:hover {
        color: white !important;
      }
      .p-button-icon.p-c.p-button-icon-left.pi.pi-check {
        margin-right: 0;
        margin-left: 0.5rem;
      }
    }
    // pour que dans le dépôt d'offres on n'aie pas les composants qui s'affichent s'ils sont pas actifs
    .p-button.p-component.p-disabled {
      display: none;
    }
    .p-button.p-component.bouton_supprimer {
      background-color: c.$rouge-groupe !important;
      color: white;
      width: 2rem;
      height: 2rem;
      &:hover {
        color: white !important;
      }
      .p-button-icon.p-c.p-button-icon-left.pi.pi-times {
        margin-right: 0;
        margin-left: 0.5rem;
      }
    }

    .p-button-label {
      font-weight: unset;
      font-size: small;
      text-align: left;
    }
    button.p-button.p-component.p-disabled {
      .p-button .p-button-icon-left {
        display: none;
      }
    }
  }

  .p-fileupload .p-fileupload-buttonbar .p-button {
    background-color: unset !important;
    padding-top: unset;
    padding-bottom: unset;
    padding-left: unset;
  }
  .p-fileupload .p-fileupload-buttonbar {
    background: transparent !important;
    border: none !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
  }

  // ------------------style boutons
  .p-button.p-component {
    border: 1px solid c.$bleu-paris1;
    box-sizing: border-box;
    border-radius: 30px;
    color: c.$bleu-paris1;
    background-color: c.$blanc;
    &:hover {
      color: white !important;
      background-color: c.$bleu-paris1 !important;
    }
    .p-disabled {
      opacity: 1 !important;
      font-size: small !important;
      &:hover {
        opacity: none !important;
        cursor: pointer;
        background-color: c.$bleu-paris1;
        color: white;
      }
    }
  }

  // -----------------------style accordéon
  .p-accordion {
    .p-accordion-header .p-accordion-header-link {
      background: transparent !important;
      border-color: transparent !important;
      padding: 1.25rem 0 0 0 !important;
      display: flex;
      align-items: center;
      color: c.$bleu-paris1 !important;
    }
    .p-accordion-content {
      background: transparent !important;
      border-color: transparent !important;
      padding: 0 !important;
    }
  }

  .p-accordion-header-text {
    color: c.$bleu-paris1 !important;
  }

  .p-accordion-tab {
    margin-left: 0;
  }

  // -------------- style dropdown, multiselect, inputtext similaires pour les formulaires et barres de recherche
  .p-multiselect.p-component.p-inputwrapper,
  .p-dropdown.p-component.p-inputwrapper,
  .p-inputtext.p-component.box__input,
  .p-inputtext.p-component {
    border-radius: 1rem;
    height: 2.5rem;
    box-shadow: none;
    align-items: center;
    &:enabled:hover {
      border: none;
    }
    &:enabled:focus {
      border-color: transparent;
      box-shadow: none;
    }
  }
  .p-inputtextarea.p-inputtext.p-component {
    border-radius: 0.6rem;
  }
  .p-inputtext.p-component.p-placeholder,
  .p-multiselect-label.p-placeholder,
  .p-dropdown-label.p-inputtext.p-placeholder,
  .p-inputtext {
    color: c.$gris-picto;
    padding-left: 0.5rem;
    margin-left: 0.75rem;
    font-family: c.$font-global;
  }
  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none !important;
  }
  .p-multiselect-item {
    .p-checkbox.p-component {
      margin-top: 0 !important;
    }
  }

  // ------------------------style dropdown
  .p-dropdown.p-component.p-inputwrapper {
    color: c.$blanc;
    width: 12.5rem !important;
    font-family: c.$font-sous-menu;
    border-color: c.$blanc;
    background-color: c.$blanc;
    transition: all 0.5s;
    border: none;

    &:hover {
      // color: c.$bordeau-commun !important;
      background-color: c.$blanc;
      cursor: pointer;
    }
  }
  .p-dropdown-label.p-inputtext.p-placeholder,
  .p-dropdown .p-dropdown-label.p-placeholder,
  .p-inputtext,
  .p-component {
    font-family: c.$font-global !important;
    font-size: medium !important;
  }

  .p-dropdown-label.p-inputtext {
    display: flex;
    align-items: center;
    .p-placeholder {
      display: flex;
      align-items: center;
      border: none;
      &:hover {
        border: none;
      }
    }
    .p-dropdown-clear-icon {
      right: 1rem !important;
    }
  }

  //----------------style multiselect
  .p-multiselect .p-multiselect-label {
    padding: 0.75rem 0.75rem 0.75rem 0rem !important;
  }

  //-------------------style inputtext area
  .p-inputtextarea.p-inputtext.p-component {
    height: unset;
  }

  .p-dialog .p-dialog-content {
    // padding-top: 1rem !important;
    height: fit-content;
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 0rem 0 0 !important;
    margin: 0 0rem 0rem 1.5rem;
  }
  .p-dialog.p-component.modal.p-dialog-enter-done {
    background-color: c.$fond-page !important;
    background: c.$fond-page;
  }

  .depot_offre_formulaire {
    &__container {
      .question {
        .import {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .import_button {
            color: c.$bleu-paris1;
            width: 25%;
            text-align: left;
            border: none;
            padding-left: unset;
            background-color: c.$fond-page;
            font-size: small;
          }
        }
      }
      .p-multiselect-panel.p-component.p-connected-overlay-enter-done {
        .p-checkbox.p-component {
          margin-top: 0 !important;
        }
      }
    }
  }

  hr {
    opacity: 1 !important;
  }

  // style des calendriers
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
    border: none !important;
    // box-shadow: 0px 2px 0.2px lightgray !important;
    height: 2.5rem !important;
    background-color: c.$blanc;
    border-radius: 1rem;
    text-decoration: none;
    margin-top: 0.5rem;
    &:hover {
      // border: 1px solid c.$bleu-paris1 !important;
    }
  }

  .label_date {
    p {
      margin-left: 1rem;
    }
  }

  .MuiFormControl-root.MuiTextField-root {
    width: 100%;
  }

  .MuiInputBase-input {
    border: none;
  }

  .MuiInputBase-input.MuiInput-input {
    font-family: c.$font-global;
    color: c.$gris-search;
    margin-left: 0.6rem;
  }

  .MuiInput-underline::before {
    border-bottom: none !important;
    content: none !important;
  }

  .MuiInput-underline::after {
    border: none !important;
  }

  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
    display: none;
  }

  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled::after {
    visibility: visible;
    position: relative !important;
    pointer-events: none !important;
    top: 50% !important;
    margin-top: -0.5rem !important;
    margin-left: 0.5rem;
    margin-bottom: -0.5rem !important;
    transition-property: all !important;
    transition-timing-function: ease !important;
    line-height: 1 !important;
    font-family: c.$font-global;
  }

  // carrousel
  .p-carousel {
    width: 100%;
  }

  .p-carousel-container {
    height: 8rem;
  }
  .p-carousel-indicators.p-reset {
    display: none;
  }

  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -3rem !important;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none;
  }
  .carousel-indicators [data-bs-target] {
    background-color: c.$gris-picto !important;
  }

  .carousel-inner {
    width: 90% !important;
    height: fit-content;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    color: aqua !important;
    border-radius: 1rem;
  }
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
  }
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e") !important;
  }

  .carousel-item {
    .item-carousel {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      flex-wrap: wrap;
      width: 90%;
      margin: 1% 5%;
    }
    img {
      width: 25%;
      min-width: 8rem;
      margin-top: 1rem;
      padding: 1rem 1rem;
      box-shadow: 1px 1px 8px 1px c.$gris-bordure;
    }
  }
  .carousel {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  // gros titres avec un trait dessus

  .titre_de_page {
    h1 {
      font-family: c.$font-gros-titres;
      color: c.$bleu-paris1;
      font-size: xx-large !important;
      padding: 4rem 0 2rem 0;
      @media screen and (max-width: c.$small) {
        padding: 2rem 0;
      }
    }
  }
}

.p-dropdown-label.p-inputtext.p-placeholder,
.p-dropdown .p-dropdown-label.p-placeholder,
.p-inputtext,
.p-component,
.p-dropdown-label.p-inputtext.p-placeholder {
  font-family: c.$font-global !important;
  font-size: medium !important;
}

@media screen and (max-width: c.$small) {
  .p-menuitem-text {
    display: unset;
  }
}

// permet d'avoir le trait au dessus des titres des pages
.h1_trait_dessus::before {
  display: block;
  content: "";
  width: 100px;
  height: 2px;
  background-color: c.$bleu-paris1;
  margin-bottom: 1rem;
}
.h1_trait_dessus_blanc::before {
  display: block;
  content: "";
  width: 100px;
  height: 2px;
  background-color: c.$blanc;
  margin-bottom: 1rem;
}
// permet d'avoir le trait au dessous des titres dans le footer
.h1_trait_dessous::before {
  display: block;
  content: "";
  width: 20px;
  height: 2px;
  background-color: c.$blanc;
  // margin-top: 2rem;
  position: relative;
  top: 2rem;
}

// style de la checkbox
.p-checkbox-box {
  border: 2px solid c.$bleu-texte-courant !important;
  background: transparent !important;
  &:hover {
    border: 2px solid c.$bleu-paris1 !important;
    span {
      color: c.$bleu-texte-courant !important;
    }
  }
  &.p-highlight {
    border: 2px solid c.$bleu-paris1 !important;
    span {
      color: c.$bleu-paris1 !important;
    }
  }
}

// style de l'avatar
.p-avatar.p-avatar-xl {
  height: 5rem !important;
  width: 5rem !important;
}

// style du bouton rafraichir dans les barres de recherche
.search_bar_refresh {
  i {
    color: c.$bleu-paris1;
    cursor: pointer;
  }
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

// style du bouton sauvegarder la recherche dans les barres de recherche
.search_bar_save {
  display: flex;
  justify-content: flex-end;
  background-color: c.$fond-page;
  .btn-bleu.left {
    margin: 1rem 0 0 0;
  }
}

@media screen and (max-width: c.$small) {
  .p-menuitem-text {
    display: unset;
  }
}

// ------------------------style de la navbar
.styleNavBarActive {
  .navBarProfil {
    display: flex;
    justify-content: center;
    &__tabMenu {
      display: flex;
      justify-content: center;
    }

    ul.p-tabmenu-nav.p-reset {
      border: none !important;
      overflow: hidden;
      border-radius: 0.8rem;

      .p-tabmenuitem {
        width: 13rem;
        border-left: 1px solid lightgray;
        text-align: center;
        a {
          flex-direction: column;
          padding: 1.5rem 1rem !important;
          border: none !important;
          background-color: c.$blanc;
          box-shadow: none !important;
          &:hover {
            background-color: c.$blanc !important;
          }
          .p-menuitem-icon {
            color: c.$gris-picto;
          }
          .p-menuitem-text {
            margin-top: 0.5rem;
            color: c.$gris-picto;
            font-family: c.$font-sous-menu;
            font-size: c.$font-size-small;
            // width: 10rem;
            &:hover {
              color: c.$bleu-paris1;
            }
          }
        }
        &:first-child {
          border-left: none;
        }
      }
      .p-tabmenuitem.p-highlight {
        .p-menuitem-link {
          background-color: c.$bleu-paris1 !important;
          color: white !important;
          border: none;
          border-radius: 0rem !important;
          box-shadow: none;
          .p-menuitem-text,
          .p-menuitem-icon {
            color: white;
          }
        }
      }
    }
  }
}

@media screen and (max-width: c.$medium) {
  .styleNavBarActive {
    .navBarProfil {
      padding: 0;
      ul.p-tabmenu-nav.p-reset {
        width: 97%;
        overflow: unset;
        border-radius: unset;
        .p-tabmenuitem a {
          padding: 0.5rem 0.5rem 1rem !important;
        }
      }
    }
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
    margin-right: 0rem;
  }
}

// modifications d'affichage navBar pour mobile
@media screen and (max-width: c.$medium) {
  .styleNavBarActive {
    .navBarProfil {
      padding: 0;
      .p-scrollpanel-content {
        width: 100%;
        overflow: unset;
      }
      ul.p-tabmenu-nav.p-reset {
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        .p-tabmenuitem {
          width: fit-content;
          min-width: 11rem;
        }
        .p-tabmenuitem.p-highlight {
          .p-menuitem-link {
            background-color: c.$blanc !important;
            color: white !important;
            border: none;
            border-radius: 0rem !important;
            .p-menuitem-text,
            .p-menuitem-icon {
              color: c.$bleu-paris1;
            }
          }
        }
      }
    }
  }
}

//? Effet zoom et shadow au hover sur une card (Ajouter la classe card pour se faire)
.cardHover {
  transition-duration: 0.2s;
  margin-bottom: 2rem;
  box-shadow: 2px 3px 6px rgba(150, 150, 150, 0.75);
  &:hover {
    cursor: pointer;
    transform: scale(1.01);
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
  }
}

// modification des composants primereact

.p-button.p-component.p-confirm-popup-accept.p-button-sm {
  background: c.$bleu-paris1;
  border-radius: 30px;
  color: white;
  border: 1px solid c.$bleu-paris1;
  width: 6rem;
  height: 3rem;
}
.p-button.p-component.p-confirm-popup-reject.p-button-sm.p-button-text {
  border: 1px solid c.$bleu-paris1;
  box-sizing: border-box;
  border-radius: 30px;
  width: 6rem;
  height: 3rem;
  color: c.$bleu-paris1;
}

// --------- composant file upload
.p-fileupload.p-fileupload-advenced.p-component {
  display: flex !important;
}
.p-button.p-fileupload-choose.p-component {
  background-color: c.$fond-page !important;
  border-radius: unset !important;
  color: c.$bleu-paris1;
  border: unset !important;
  font-size: small;
  &:hover {
    color: c.$bleu-paris1 !important;
    background-color: c.$fond-page !important;
  }
  &:focus {
    border: unset !important;
  }
}

.p-button.p-fileupload-choose.p-component:hover {
  cursor: pointer;
}
.p-button.p-component.p-disabled {
  color: c.$gris-picto;
  &:hover {
    cursor: pointer;
  }
}

.p-fileupload .p-fileupload-content {
  border: none !important;
  background: transparent !important;
  height: fit-content;
  padding: 0 !important;
  font-size: small;
  .p-button.p-button-icon-only {
    width: 2rem;
    height: 2rem;
    display: none;
  }
  .p-progressbar.p-component.p-progressbar-determinate {
    display: none;
  }
}
.p-fileupload.p-fileupload-advanced.p-component {
  margin-top: 0.5rem;
}
.p-fileupload-buttonbar {
  .p-button.p-component.p-disabled {
    .p-button-icon.p-c.p-button-icon-left.pi.pi-upload {
      display: none;
    }
    .p-button-icon.p-c.p-button-icon-left.pi.pi-times {
      display: none;
    }
  }
  .p-button-icon.p-button-icon-left.p-clickable.pi.pi-fw.pi-plus {
    display: none;
  }
  .p-button.p-component {
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    font-size: small;
    &:hover {
      background-color: transparent !important;
      color: c.$bleu-paris1 !important;
    }
  }
  .p-button.p-component.bouton_valider {
    background-color: c.$vert-groupe !important;
    color: white;
    width: 2rem;
    height: 2rem;
    &:hover {
      color: white !important;
    }
    .p-button-icon.p-c.p-button-icon-left.pi.pi-check {
      margin-right: 0;
      margin-left: 0.5rem;
    }
  }
  // pour que dans le dépôt d'offres on n'aie pas les composants qui s'affichent s'ils sont pas actifs
  .p-button.p-component.p-disabled {
    display: none;
  }
  .p-button.p-component.bouton_supprimer {
    background-color: c.$rouge-groupe !important;
    color: white;
    width: 2rem;
    height: 2rem;
    &:hover {
      color: white !important;
    }
    .p-button-icon.p-c.p-button-icon-left.pi.pi-times {
      margin-right: 0;
      margin-left: 0.5rem;
    }
  }

  .p-button-label {
    font-weight: unset;
    font-size: small;
    text-align: left;
  }
  button.p-button.p-component.p-disabled {
    .p-button .p-button-icon-left {
      display: none;
    }
  }
}

.p-fileupload .p-fileupload-buttonbar .p-button {
  background-color: unset !important;
  padding-top: unset;
  padding-bottom: unset;
  padding-left: unset;
}
.p-fileupload .p-fileupload-buttonbar {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
}

// ------------------style boutons
.p-button.p-component {
  border: 1px solid c.$bleu-paris1;
  box-sizing: border-box;
  border-radius: 30px;
  color: c.$bleu-paris1;
  background-color: c.$blanc;
  &:hover {
    color: white !important;
    background-color: c.$bleu-paris1 !important;
  }
  .p-disabled {
    opacity: 1 !important;
    font-size: small !important;
    &:hover {
      opacity: none !important;
      cursor: pointer;
      background-color: c.$bleu-paris1;
      color: white;
    }
  }
}

// -----------------------style accordéon
.p-accordion {
  .p-accordion-header .p-accordion-header-link {
    background: transparent !important;
    border-color: transparent !important;
    padding: 1.25rem 0 0 0 !important;
    display: flex;
    align-items: center;
    color: c.$bleu-paris1 !important;
  }
  .p-accordion-content {
    background: transparent !important;
    border-color: transparent !important;
    padding: 0 !important;
  }
}

.p-accordion-header-text {
  color: c.$bleu-paris1 !important;
}

.p-accordion-tab {
  margin-left: 0;
}

// -------------- style dropdown, multiselect, inputtext similaires pour les formulaires et barres de recherche
.p-multiselect.p-component.p-inputwrapper,
.p-dropdown.p-component.p-inputwrapper,
.p-inputtext.p-component.box__input,
.p-inputtext.p-component {
  border-radius: 1rem;
  height: 2.5rem;
  background-color: c.$blanc;

  box-shadow: none;
  align-items: center;
  &:enabled:hover {
    border: none;
  }
  &:enabled:focus {
    border-color: transparent;
    box-shadow: none;
  }
}
.react-datepicker__input-container {
  input {
    background-color: c.$blanc;
  }
}
.react-datepicker-popper {
  z-index: 1002 !important;
}
.p-inputtextarea.p-inputtext.p-component {
  border-radius: 0.6rem;
}
.p-inputtext.p-component.p-placeholder,
.p-multiselect-label.p-placeholder,
.p-dropdown-label.p-inputtext.p-placeholder,
.p-inputtext {
  color: c.$gris-picto;
  padding-left: 0.5rem;
  margin-left: 0.75rem;
  font-family: c.$font-global;
}
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none !important;
}
.p-multiselect-item {
  .p-checkbox.p-component {
    margin-top: 0 !important;
  }
}

// ------------------------style dropdown
.p-dropdown.p-component.p-inputwrapper {
  color: c.$blanc;
  // width: 12.5rem !important;
  font-family: c.$font-sous-menu;
  border-color: c.$blanc;
  background-color: c.$blanc !important;
  transition: all 0.5s;
  border: none;

  &:hover {
    // color: c.$bordeau-commun !important;
    background-color: c.$blanc;
    cursor: pointer;
  }
}
.p-dropdown-label.p-inputtext.p-placeholder,
.p-dropdown .p-dropdown-label.p-placeholder,
.p-inputtext,
.p-component {
  font-family: c.$font-global !important;
  font-size: medium !important;
}

.p-dropdown-label.p-inputtext {
  display: flex;
  align-items: center;
  .p-placeholder {
    display: flex;
    align-items: center;
    border: none;
    &:hover {
      border: none;
    }
  }
  .p-dropdown-clear-icon {
    right: 1rem !important;
  }
}

//----------------style multiselect
.p-multiselect .p-multiselect-label {
  padding: 0.75rem 0.75rem 0.75rem 0rem !important;
}

//-------------------style inputtext area
.p-inputtextarea.p-inputtext.p-component {
  height: unset;
}

.p-dialog .p-dialog-content {
  // padding-top: 1rem !important;
  height: fit-content;
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 0rem 0 0 !important;
  margin: 0 0rem 0rem 1.5rem;
}
.p-dialog.p-component.modal.p-dialog-enter-done {
  background-color: c.$fond-page !important;
  background: c.$fond-page;
}

.depot_offre_formulaire {
  &__container {
    .question {
      .import {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .import_button {
          color: c.$bleu-paris1;
          width: 25%;
          text-align: left;
          border: none;
          padding-left: unset;
          background-color: c.$fond-page;
          font-size: small;
        }
      }
    }
    .p-multiselect-panel.p-component.p-connected-overlay-enter-done {
      .p-checkbox.p-component {
        margin-top: 0 !important;
      }
    }
  }
}

hr {
  opacity: 1 !important;
}

// style des calendriers
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  border: none !important;
  // box-shadow: 0px 2px 0.2px lightgray !important;
  height: 2.5rem !important;
  background-color: c.$blanc;
  border-radius: 1rem;
  text-decoration: none;
  margin-top: 0.5rem;
  &:hover {
    // border: 1px solid c.$bleu-paris1 !important;
  }
}

.label_date {
  p {
    margin-left: 1rem;
  }
}

.MuiFormControl-root.MuiTextField-root {
  width: 100%;
}

.MuiInputBase-input {
  border: none;
}

.MuiInputBase-input.MuiInput-input {
  font-family: c.$font-global;
  color: c.$gris-search;
  margin-left: 0.6rem;
}

.MuiInput-underline::before {
  border-bottom: none !important;
  content: none !important;
}

.MuiInput-underline::after {
  border: none !important;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
  display: none;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled::after {
  visibility: visible;
  position: relative !important;
  pointer-events: none !important;
  top: 50% !important;
  margin-top: -0.5rem !important;
  margin-left: 0.5rem;
  margin-bottom: -0.5rem !important;
  transition-property: all !important;
  transition-timing-function: ease !important;
  line-height: 1 !important;
  font-family: c.$font-global;
}

// carrousel
.p-carousel {
  width: 100%;
}

.p-carousel-container {
  height: 8rem;
}
.p-carousel-indicators.p-reset {
  display: none;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: -5rem !important;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 7rem !important;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  background-color: c.$gris-picto !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
}

.carousel-inner {
  height: 45rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  color: aqua !important;
  border-radius: 1rem;
}
.carousel-control-next-icon {
  margin-left: 5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
}
.carousel-control-prev-icon {
  margin-right: 5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e") !important;
}

.carousel-item {
  .item-carousel {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    margin: 1% 5%;
  }
  img {
    width: 100%;
    min-width: 8rem;
  }
  .item-carouselEvent {
    display: flex;
    flex-direction: column !important;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    margin: 1% 5%;
  }
}
.carousel {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
// gros titres avec un trait dessus

.titre_de_page {
  h1 {
    font-family: c.$font-gros-titres;
    color: c.$bleu-paris1;
    font-size: xx-large !important;
    padding: 4rem 0 2rem 0;
    @media screen and (max-width: c.$small) {
      padding: 2rem 0;
    }
  }
}

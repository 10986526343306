@use "src/Styles/_vars.scss" as c;

.annuaire__container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: c.$fond-page;
  padding-bottom: 2rem;

  h1 {
    font-family: c.$font-gros-titres;
    color: c.$bleu-paris1;
    font-size: xx-large !important;
    padding-top: 4rem;
  }
  h2 {
    font-family: c.$font-sous-menu;
    color: c.$bleu-paris1;
    padding-bottom: 1rem;
  }
  .p-multiselect {
    font-family: c.$font-global;
  }

  .annuaire_filtre {
    width: fit-content;

    display: flex;

    flex-direction: column;

    flex-wrap: wrap;

    padding-top: 5rem;

    p {
      margin-bottom: 1rem;
    }

    &__label {
      display: flex;

      flex-direction: row;

      justify-content: center;

      align-items: center;

      p {
        margin-top: 1rem;

        margin-right: 0.5rem;
      }
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(4, minmax(100px, 1fr));
    gap: 1rem;
    grid-template-rows: auto;
    margin-top: 1rem;
    justify-items: center;
  }
}

@media screen and (max-width: 1450px) {
  .annuaire__container {
    &__cards {
      grid-template-columns: repeat(3, minmax(0, auto));
    }
  }
}

@media screen and (max-width: 1150px) {
  .annuaire__container {
    &__cards {
      grid-template-columns: repeat(2, minmax(0, auto));
    }
  }
}

@media screen and (max-width: 700px) {
  .annuaire__container {
    &__cards {
      grid-template-columns: repeat(1, minmax(0, auto));
    }
  }
}

.annuaire_etudiant__image__info {
  width: 12rem;
  height: 4rem;
  margin-bottom: -0.5rem;
}
@media screen and (max-width: c.$medium) {
  .annuaire__container {
    padding-bottom: 0;

    .annuaire_filtre {
      margin: 0;
    }
  }
}

@media screen and (max-width: c.$small) {
  .annuaire__container {
    h1 {
      padding-top: 1rem;
    }
    &__title {
      h1 {
        padding-top: 1rem;
      }
    }

    .annuaire_filtre {
      padding-top: 2rem;
    }
    .annuaire_search_bar.etudiant_bar {
      background-color: c.$blanc;
    }
  }
}

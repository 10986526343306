@use "src/Styles/_vars.scss" as c;

// Priorité affichage modal par rapport au reste du site
.p-dialog-mask {
  z-index: 1000 !important;
}

.modalCharteConnexion {
  &__description {
    padding: 1rem 2rem;
    text-align: center;
    width: 50rem;
    h1 {
      font-size: 1.5rem;
    }
    &__header {
      margin-bottom: 2rem !important;
      display: flex;
      align-items: center;
      justify-content: center;

      &__icon {
        margin: 0 1rem;
        color: c.$noir-rp;
        font-size: 1.5rem;
      }
    }
  }
  &__boutons {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 2rem;
    .validation_charte {
      display: flex;
      flex-direction: row;
      margin-bottom: 1rem;
      label {
        margin-left: 1rem;
      }
    }
    button {
      display: flex;
      align-self: center;
    }
  }
}

@media screen and (max-width: c.$medium) {
  .modalCharteConnexion {
    &__description {
      &__header {
        &__icon {
          margin: 0 0.5rem;
          color: c.$noir-rp;
          font-size: 5rem;
        }
      }
    }
  }
}

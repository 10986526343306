@use "src/Styles/_vars.scss" as c;

.groupe_card_gestion_container {
  background-color: white;
  box-shadow: 3px 4px 10px lightgrey;
  height: 7rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;

  &__info {
    &__logo {
      display: flex;
      align-items: center;
      img {
        margin-left: 1rem;
        width: 4rem;
        height: 4rem;
        border-radius: 2rem;
        margin-right: 1rem;
        image-rendering: -webkit-optimize-contrast;
      }
    }
    display: flex;
    flex-direction: row;
    width: 72%;
    h6 {
      font-family: c.$font-global;
      color: c.$bleu-paris1;
      font-size: medium;
      margin: 0 0 0.5rem 0;
      padding: 0;
    }
    h6.title {
      font-family: c.$font-sous-menu;
      margin: 0;
    }
    &__bottom {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      color: c.$gris-picto;
    }
    h2 {
      font-size: small;
      margin-right: 2rem;
    }
  }
  &__bouton {
    margin-right: 2rem;
    justify-self: flex-end;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    width: 7rem;
    .groupe_menu {
      display: flex;
      justify-content: flex-end;
      .btn-rond {
        color: c.$bleu-paris1;
        height: 2rem;
        width: 3rem;
        background-color: transparent;
        margin-bottom: 1rem;
      }
      .p-menuitem-text {
        display: flex;
        justify-content: center;
      }
    }
    span {
      padding: 0 1rem;
      border-radius: 0.33rem;
      display: flex;
      justify-content: center;
    }
    .desactive {
      background-color: c.$fond-page;
      color: c.$gris-picto;
    }
    .actif {
      background-color: c.$vert-groupe;
    }
    .attente {
      background-color: c.$orange-groupe;
    }
    .refuse {
      background-color: c.$rouge-groupe;
    }
    .invite {
      background-color: c.$bleu-groupe;
    }
  }
}

.p-menu a.p-menuitem-link {
  padding: 0.75rem 0.5rem 0.75rem 0.75rem;
}

// Tentative fix responsive..
@media screen and (max-width: c.$medium) {
  .groupe_card_gestion_container {
    &__info {
      &__logo {
        img {
          height: 2.8rem;
        }
      }
      display: flex;
      flex-direction: row;
      h6.description {
        display: none;
      }
      &__bottom {
        flex-wrap: nowrap;
      }
      h2 {
        font-size: smaller;
        margin-top: 0.5rem;
        margin-right: 1rem;
      }
    }
    &__bouton {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      width: 2rem;
      span {
        padding: 0 0.5rem;
        font-size: smaller;
      }
    }
  }
}

@use "src/Styles/_vars.scss" as c;

.modal.modal_edition-commentaire {
    width: 30% !important;
}

.edition_commentaire__form {
    border: 1vh;
    padding-top: 5vh;
    &-content {
        border: 3px solid c.$fond-page !important;
        box-shadow: 0px 2px 4px lightgray !important;
        margin: 2rem 0 !important;
    }
    &-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 5vh;
    }
}
@use "src/Styles/_vars.scss" as c;

.modal_groupe-creation {
  width: 50% !important;
}

.creation_groupe_container {
  // Icône dropdown clear PrimeReact
  .groupes-dropdown .p-dropdown-clear-icon {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-out;
  }

  .groupes-dropdown:hover {
    .p-inputtext {
      opacity: 0.25;
    }
    .p-dropdown-clear-icon {
      opacity: 1;
      pointer-events: auto;
    }
  }

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 8%;
    .chargement_image {
      width: 100%;
    }
  }
  small {
    display: block;
  }
  h2.p-error {
    display: block;
    color: c.$erreur;
  }
  .MuiFormControl-root.MuiTextField-root {
    top: -0.5rem;
  }
  h2 {
    font-family: "font-sous-menu";
    color: c.$bleu-paris1;
    // padding-bottom: 1rem;
    font-size: large;
  }
  .question_columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
    // margin-bottom: 1rem;
    .question {
      width: 24%;
      height: fit-content;
      // margin-bottom: 0.8rem;
      .p-dropdown .p-dropdown-label.p-inputtext {
        width: 6.2rem;
        // margin-top: 0.5rem;
      }
      label {
        margin-bottom: 0.25rem;
      }
    }
  }
  .question_columns.large {
    display: flex;
    flex-direction: row;
    justify-content: unset;
    gap: 9rem;
    .question {
      width: 40%;
      // margin-right: 1%;
      .p-dropdown.p-component.p-inputwrapper.input_groupe {
        width: 100% !important;
        // width: 90%;
      }
      .p-inputtext.p-component {
        width: 98% !important;
      }
    }
  }
  .question.column {
    margin-bottom: 0;
    padding-bottom: 0;
    h2 {
      margin-bottom: 0;
    }
  }
  .question {
    width: 100%;
    margin-bottom: 1.5rem;
    .p-inputtextarea {
      width: 100%;
    }
    p {
      margin-top: 0.5rem;
    }
    .searchBar_groupe_icon {
      position: relative;
      z-index: 9;
      color: c.$gris-picto;
      font-size: 1.5rem;
      margin-left: 0.7rem;
    }
  }
  .description {
    width: 100%;
    margin-bottom: 2rem;
  }
  .p-dropdown.p-component.p-inputwrapper,
  .p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled {
    width: 100% !important;
  }
  .question.checkbox {
    width: fit-content;
    margin-right: 2rem;
    display: flex;
    flex-direction: row;
    label {
      font-family: c.$font-global;
      margin-left: 1rem;
    }
  }
  .groupe_prive {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
  }
  .question.bouton {
    .btn-bleu {
      margin: 0;
    }
  }
  .membership_input_text {
    font-family: c.$font-global;
    width: 100%;
    margin-top: -2rem;
    padding-left: 2.5rem;
    border-radius: 2rem;
  }
  .question.search {
    h2 {
      padding-bottom: 0.5rem;
    }
    .p-inputtext.p-component.membership_input_text {
      position: relative;
      width: 48% !important;
      left: -2rem;
    }
  }

  // import d'image
  .question {
    .p-image.p-component {
      img {
        border-radius: 1rem;
      }
    }
    .p-fileupload.p-fileupload-advanced.p-component {
      .p-fileupload-buttonbar {
        border: none;
        background-color: transparent;
        padding: 1.25rem 0;
        .p-button.p-fileupload-choose.p-component {
        }
      }
    }
  }
  .multiselect-demo .p-multiselect {
    width: 48%;
    background-color: white !important;
  }

  .membership_input_text.multiselect-demo {
    padding-left: 0;
  }

  .multiselect-demo
    .multiselect-custom
    .p-multiselect-label:not(.p-placeholder):not(.p-multiselect-items-label) {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .p-multiselect.p-component.p-inputwrapper.p-multiselect-clearable.multiselect-custom.membership_input_group {
    &:enabled:focus {
      border-color: transparent;
      box-shadow: none;
    }
  }
  .multiselect-demo {
    &_list {
      overflow-x: scroll;
      display: flex;
      flex-direction: row;
      width: 25rem;
      height: 4.5rem;
      background-color: white;
      border-radius: 1rem;
      padding: 0.5rem 0;
      .member_fullname {
        width: fit-content;
        font-family: c.$font-global;
        display: flex;
        flex-direction: row;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.6);
        padding: 0.5rem 1rem;
        border-radius: 1rem;
        background-color: #e4e4e4;
        margin-right: 0.25rem;
        margin-left: 0.25rem;
        align-self: center;
        .member_firstname,
        .member_surname {
          margin-right: 0.3rem;
        }
        svg {
          align-self: center;
          color: black;
          margin-left: 0.5rem;
        }
      }
    }
  }

  .p-multiselect:not(.p-diabled).p-focus {
    border-color: transparent;
    box-shadow: none;
  }

  .multiselect-demo .multiselect-custom .member-item-value {
    padding: 0.25rem 0.5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: 0.5rem;
    // background-color: var(--primary-color);
    // color: var(--primary-color-text);
  }
}

@media screen and (max-width: c.$medium) {
  .creation_groupe_container {
    .question,
    .description,
    .groupe_prive {
      width: 100% !important;
    }
    .question_columns,
    .question_columns.large {
      flex-direction: column;
      width: 100%;
      .question {
        width: 100%;
      }
    }
    .question.column {
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }
    .question.search {
      .p-inputtext.p-component.membership_input_text {
        width: 100% !important;
        left: 0;
        top: -1.5rem;
      }
    }
  }
}

@media screen and (max-width: c.$small) {
  .creation_groupe_container {
    .groupe_prive {
      flex-direction: column;
      .question.checkbox {
        width: 100%;
      }
    }
  }
}

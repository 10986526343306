@use "src/Styles/_vars.scss" as c;

.evenements_agenda__container {
  padding: 0 7rem;
  .evenements_container {
    padding: 0;
    .event_aucun {
      margin: 7vh 0;
      padding: 0;
    }
    .evenement_container {
      width: fit-content;
    }
  }
  &__title {
    h1 {
      font-family: c.$font-gros-titres;
      color: c.$bleu-paris1;
      font-size: xx-large !important;
      margin: 4rem 0 2rem 0;
    }
  }
}

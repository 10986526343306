@use "src/Styles/_vars.scss" as c;

.entreprise {
  background-color: c.$fond-page;
  display: flex;
  flex-direction: column;
  .entreprise_bottom {
    h1 {
      margin: 2rem;
      font-family: c.$font-sous-menu;
      color: c.$bleu-paris1;
    }
    .entreprise__offres {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(15rem, 20rem));
      gap: 1rem 3.7rem;
      grid-template-rows: auto;
      justify-content: center;
      margin: 1rem;
      .offre {
        width: fit-content;
      }
      margin-bottom: 2rem;
    }
  }

  .entreprise_top {
    display: flex;
    flex-direction: row;
    .entreprise_droite {
      min-width: 30rem;
      max-width: 70%;

      .offre_main {
        border-radius: 0.33rem;
        background-color: white;
        margin: 2rem;
        &__top {
          background-color: white;
          border-radius: 0.33rem;
          img {
            width: 100%;
            border-top-left-radius: 0.33rem;
            border-top-right-radius: 0.33rem;
            height: 15rem;
          }
          img.logo_entreprise {
            width: 7rem;
            height: 7rem;
            border-radius: 50%;
            border: 2px solid c.$gris-picto;
            position: relative;
            top: -3rem;
            left: 3rem;
          }
          &__header {
            // padding-top: 1rem;
            padding-left: 2rem;
            position: relative;
            top: -2rem;
            h5 {
              font-family: c.$font-sous-menu;
              color: c.$bleu-paris1;
            }
            &__informations {
              display: flex;
              justify-content: flex-start;
              p {
                margin-right: 1rem;
                color: c.$gris;
              }
            }
            .offres_disponibles {
              background-color: c.$bleu-secondaire;
              width: fit-content;
              padding: 0.25rem 0.5rem;
              border-radius: 0.33rem;
              color: white;
              font-size: small;
              align-items: center;
              display: flex;
              margin-bottom: 0;
              svg {
                margin-right: 0.5rem;
              }
            }
          }
        }
        &__description {
          background-color: white;
          border-radius: 1rem;
          padding: 1rem;
          position: relative;
          top: -1rem;
          h5 {
            font-family: c.$font-sous-menu;
            font-size: medium;
            color: c.$bleu-paris1;
            padding-left: 1rem;
            width: 100%;
            margin-bottom: 0.5svh;
          }
          p:first-child {
            margin: 0 1rem 0.5rem 1rem;
            text-justify: inter-word;
          }
        }
      }
      .custom .p-scrollpanel-wrapper {
        border-right: 9px solid #f4f4f4;
        margin-left: 2rem;
        margin-right: 2rem;
      }

      .custom .p-scrollpanel-bar {
        background-color: #1976d2;
        opacity: 1;
        transition: background-color 0.3s;
      }

      .custom .p-scrollpanel-bar:hover {
        background-color: #135ba1;
      }
    }
    .entreprise_gauche {
      width: 30%;
      // margin-top: 3rem;
      padding: 0 0 0 1rem;
      min-width: 15rem;
      .recruteur h1 {
        margin-top: 2rem;
        // margin-left: 1rem;
        font-family: c.$font-sous-menu;
        font-size: x-large;
        color: c.$bleu-paris1;
      }
      .offre {
        width: 100% !important;
        height: fit-content !important;
        margin-bottom: 2rem;
        border-radius: 0.33rem;
        .annuaire_etudiant__image {
          border-top-left-radius: 0.33rem;
          border-top-right-radius: 0.33rem;
          padding: 2rem 0 2rem 6rem;
          h1 {
            display: none;
          }
        }
        .annuaire_etudiant__container {
          padding-right: 0;
        }
        .annuaire_etudiant__container__description__title__item {
          display: flex;
          align-items: center;
          margin-bottom: 0.5rem;
          p {
            margin-bottom: 0;
            margin-left: 0.5rem;
          }
          a:hover {
            color: c.$bleu-secondaire !important;
          }
        }
      }
      .entreprise_detail {
        max-width: 20rem;
        margin-left: 1rem;
        margin-right: 1rem;
        border: 1px solid c.$gris-picto;
        border-radius: 0.33rem;
        &__image {
          position: relative;
          padding: 0;
          &__header {
            width: 100%;
            height: 7rem;
            object-fit: cover;
            border-radius: 0.33rem;
          }
          span {
            font-family: c.$font-sous-menu;
            padding-bottom: 1rem;
          }
          h1 {
            padding: 0;
          }

          &__logo {
            img {
              top: 3rem;
              left: 3rem;
              position: absolute;
              border-radius: 50%;
              width: 5rem;
              height: 5rem;
              border: 1px solid c.$gris;
            }
          }
        }
        &__container {
          height: 70%;
          padding-right: 1rem;
          &__description {
            margin: 1rem 0 2rem;

            &__title,
            &__statut,
            &__localisation,
            &__employes {
              margin: 0 0 1rem 2rem;

              span {
                margin-left: 1rem;
              }
            }
            &__statut {
              font-family: c.$font-sous-menu;
              display: flex;
              // flex-direction: column;
              justify-content: center;
              .offres_disponibles {
                justify-self: center;
                text-transform: uppercase;
                font-size: 0.7rem;
                font-weight: bold;
                margin: 0 0 0.5rem 1rem;
                padding: 0.3rem 0.5rem;
                width: fit-content;
                border-radius: 0.33rem;
                span {
                  margin-left: 1rem;
                }
              }

              .offres_disponibles {
                background-color: c.$bleu-secondaire;
                color: white;
              }
            }
            &__title {
              display: flex;
              flex-direction: column;
              justify-content: center;
              // font-family: c.$font-sous-menu;
              p {
                font-family: c.$font-sous-menu !important;
                margin-bottom: 1rem;
              }
              h3 {
                align-self: center;
                color: c.$gris-picto;
                margin-bottom: 0.5rem;
              }

              margin-top: 2rem;
              a {
                color: c.$noir-rp;
              }
            }
          }
        }
      }

      .email {
        margin-left: 1rem;
        margin-bottom: -0.5rem;
      }
    }
  }
}
@media screen and (max-width: c.$small) {
  .entreprise {
    justify-content: center;
    .entreprise_gauche {
      // On fait passer la partie de gauche en dessous de la partie de droite
      width: 100% !important;
      margin-top: 2rem;
    }
    // La partie droite prend le dessus et prend toute la largeur
    .entreprise_droite {
      width: 100% !important;
      min-width: unset !important;
      max-width: none !important;
      margin-top: 2rem;
    }
  }
}

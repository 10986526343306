@use "src/Styles/_vars.scss" as c;

.btn-blanc.left {
  border: 1px solid c.$bleu-paris1;
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 30px;
  width: fit-content;
  height: 3rem;
  color: c.$bleu-paris1;
  transition: all 0.5s;
  padding: 0rem 1.5rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  svg {
    margin-left: 0;
    margin-right: 0.5rem;
    // margin-top: 0.5rem;
  }
}
.btn-blanc.left:hover {
  cursor: pointer;
  background-color: c.$bleu-paris1;
  color: white;
}

@media screen and (max-width: c.$small) {
  .btn-blanc.left {
    font-size: small;
  }
}

@use "src/Styles/_vars.scss" as c;

.actualites_container {
  a {
    .actualite_container {
      width: fit-content;
    }
  }
  p {
    font-family: c.$font-global;
    text-justify: auto;
  }
  &__body {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(16rem, 23rem));
    gap: 1rem 1rem;
    grid-auto-rows: auto;
    justify-content: space-evenly;
  }
  &__empty {
    margin: 4rem 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__top {
    h1 {
      color: c.$bleu-paris1 !important;
      font-family: c.$font-gros-titres;
      font-size: 3.5rem !important;
      padding-top: 3rem;
      margin-bottom: 1rem;
      padding-bottom: 2rem;
    }
    &__title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      .p-dropdown.p-dropdown-clearable .p-dropdown-label {
        padding-right: 3.25rem;
      }
      .p-dropdown.p-component.p-inputwrapper.date {
        .p-dropdown-label.p-inputtext.p-placeholder {
          margin-right: 1.4rem;
        }
      }

      .p-accordion .p-accordion-content {
        padding: unset !important;
      }
      .p-accordion .p-accordion-header .p-accordion-header-link {
        padding: unset !important;
      }
      .accordion_actus {
        color: c.$bleu-paris1;
        margin: 0 0 1rem;
        a {
          justify-content: unset;
          padding: 1rem 0 !important;
        }
      }
      .p-accordion-content {
        .actualites_container__top__title__filtres {
          justify-content: flex-start;
          align-items: unset;
        }
      }
      &__filtres {
        padding-top: 4rem;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        .p-inputtext.p-component {
          width: 91% !important;
        }
        .btn-blanc {
          margin-top: 1rem;
          margin-left: 2rem;
        }
        &__refresh {
          color: c.$bleu-paris1;
          margin: 0 2rem;
          cursor: pointer;
          display: flex;
          margin-bottom: 0.5rem;
        }
      }
      .searchBar_groupe_finder_icon {
        position: relative;
        color: c.$gris-picto;
        font-size: 1.5rem;
        top: 1.4rem;
        margin-left: 0.7rem;
      }
      .membership_input_text {
        font-family: c.$font-global;
        margin-top: -2rem;
        padding-left: 2.5rem;
      }
      .p-dropdown.p-component.p-inputwrapper {
        margin-left: 2rem;
        margin-top: 1rem;
        width: 14rem;
      }
      .p-dropdown-label.p-inputtext.p-placeholder {
        padding-left: 0.5rem;
      }
      .h1.h1_trait_dessus-blanc {
        color: white !important;
      }
    }
  }
  &__article__pagination {
    padding: 2rem 0;
    .p-paginator {
      background: c.$fond-page;
    }
  }
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$small) {
  .actualites_container {
    padding: 1rem;
    h1 {
      padding-top: 1rem;
    }
    p {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    &__body {
      grid-auto-rows: auto;
    }
    &__top {
      &__title {
        h1 {
          padding-top: 1rem;
        }
        &__filtres {
          margin-right: 0;
          display: flex;
          justify-content: center;
          flex-direction: column;
          flex-wrap: wrap;
        }
      }
    }
  }
}
@media screen and (min-width: c.$small) {
  .actualites_container {
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

@media screen and (max-width: c.$medium) {
  .actualites_container {
    &__top {
      &__title {
        flex-direction: column;
        &__filtres {
          width: fit-content;
          flex-direction: column;
          padding-top: 2rem;
          &__visible {
            display: none;
          }
          .p-inputtext.p-component {
            width: 60% !important;
          }
        }
        .p-dropdown.p-component.p-inputwrapper {
          margin-left: unset;
          width: 100%;
        }
        .searchBar_groupe_finder_icon {
          position: absolute;
          color: c.$gris-picto;
          font-size: 1.5rem;
          top: unset;
          margin-left: 0.7rem;
        }
      }
    }
  }
}

@use "src/Styles/_vars.scss" as c;
// boutons
.buttonsBarDisconnected {
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  // min-height: 8rem;
  width: 100%;
  background-color: none !important;
  align-items: center;
  height: 30rem;
  &__On {
    z-index: 9999;
    &__button {
      width: 10%;
      //center
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
    }
  }
  &__carousel {
    display: block;
    width: 100%;
    position: absolute;
  }
  .accueilTitle {
    font-family: c.$alumni-h1;
    font-size: 4rem;
    font-weight: 900;
    color: c.$blanc;
    margin-bottom: 5rem;
  }
  .accueilButton {
    min-width: 10%;
    min-height: 3rem;
    border-radius: 30px;
    background-color: c.$blanc;
    color: c.$bleu-paris1;
    border: 1px solid transparent;
    font-family: c.$alumni-body-bold;
    font-weight: 600;
    padding: 0.5rem 1rem;
  }
  .connexionInterface {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    z-index: 9999;
    text-align: center;
    width: 11rem;
    background-color: c.$blanc;
    color: c.$bleu-commun;
    padding: 0.5rem;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    font-weight: normal;
    li {
      padding: 0.5rem;
      cursor: pointer;

      &:hover {
        font-weight: bold;
      }
    }
    &.language {
      right: 4rem;
    }
  }
  .accueilButton:hover {
    background-color: transparent;
    color: c.$blanc;
    transition: 0.3s;
    border: 1px solid c.$blanc;
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    position: relative;
    top: 0rem;
    margin-bottom: 2rem;
  }

  .bouton-visible {
    margin-bottom: 1rem;
    // changement de couleur pour la page hors connexion
    .btn-blanc {
      color: c.$blanc;
      width: 20rem !important;
      font-family: c.$font-sous-menu;
      border-color: c.$blanc;
      background-color: transparent;
      transition: all 0.5s;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;

      &:hover {
        color: c.$bordeau-commun !important;
        background-color: c.$blanc;
        cursor: pointer;
      }
    }
    .p-dropdown.p-component.p-inputwrapper {
      color: c.$blanc;
      width: 12.5rem !important;
      font-family: c.$font-sous-menu;
      border-color: c.$blanc;
      background-color: transparent;
      transition: all 0.5s;

      &:hover {
        color: c.$bordeau-commun !important;
        background-color: c.$blanc;
        cursor: pointer;
        .p-dropdown-trigger-icon.p-clickable.pi {
          color: c.$bordeau-commun;
        }
      }
      .p-dropdown-label.p-inputtext.p-placeholder {
        color: white;
        font-family: c.$font-sous-menu;
      }
      .p-dropdown-label.p-inputtext.p-placeholder:hover {
        color: c.$bordeau-commun !important;
      }

      .p-dropdown-trigger-icon.p-clickable.pi {
        color: white;
      }
    }
  }
  .icon-button {
    margin-left: 0.5rem;
  }
}

@media screen and (max-width: c.$small) {
  .buttonsBarDisconnected {
    width: 95%;
    margin: 2.5% 2.5%;
    height: 14rem;
    &__carousel {
      display: none;
    }
    &__On {
      &__button {
        position: relative;
        width: unset;
      }
    }
    .accueilTitle {
      margin-top: 0;
      margin-bottom: 1.5rem;
      line-height: 3rem;
      width: 100%;
      background-color: none !important;
      font-size: 2rem;
    }
    .buttons {
      justify-content: center;
      flex-direction: column;
      margin-bottom: 0;
      .bouton-visible {
        padding: 0;
        .btn-blanc {
          width: fit-content !important;
        }
      }
    }

    .buttons_bar {
      margin-bottom: 2rem;
    }
  }
}

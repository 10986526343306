@use "src/Styles/_vars.scss" as c;

.modal {
  min-width: fit-content;
  background-color: c.$fond-page;
  .p-dialog-content {
    background-color: c.$fond-page !important;
  }

  //?Header
  .p-dialog-title {
    font-family: c.$font-sous-menu !important;
    color: c.$noir-rp;
    text-align: center;
    margin-top: 3rem;
    text-transform: uppercase;
    font-size: 1.1rem !important;
  }
  .btn-bleu {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  .btn-bleu:hover {
    background-color: c.$blanc;
    color: c.$bleu-paris1;
  }
  .btn-blanc {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  p {
    margin: 0.5rem 0 0.5rem 0;
  }
  .p-inputtextarea {
    margin: 0.5rem 0 0 0;
    width: 80%;
    height: 10rem;
  }
  .description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

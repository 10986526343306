@use "src/Styles/_vars.scss" as c;

.membre_card {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  background-color: c.$blanc;
  border-radius: 0.33rem;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      height: 4rem;
      width: 4rem;
      border-radius: 50%;
      border: 2px solid c.$bleu-paris1;
      margin-right: 1rem;
    }
  }
  &__item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    p {
      color: c.$gris-picto;
      margin-bottom: 0;
    }
  }
  &__item.role {
    width: 10rem;
  }
  &__checkbox {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    .p-checkbox.p-component {
      margin-top: 0.25rem !important;
    }
  }
  &__boutons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    .btn-bleu {
      margin-right: 2rem;
    }
  }
  svg {
    margin-right: 0.5rem;
    color: c.$bleu-paris1;
    cursor: pointer;
  }
  svg.poubelle {
    color: c.$bleu-secondaire;
  }
}

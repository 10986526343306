@use "src/Styles/_vars.scss" as c;

.btn-rond {
  background: c.$bleu-secondaire;
  border: none;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  color: c.$blanc;
  transition: all 0.5s;
  
  &:hover {
    color: c.$bleu-secondaire;
    background: c.$blanc;
    cursor: pointer;
  }
}

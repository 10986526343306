@use "src/Styles/_vars.scss" as c;

//bandeau
.header__bandeau {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-family: c.$font-global;
  width: 100%;
  height: 7.5rem;
  padding: 0 2rem;
  position: relative;
  z-index: 3;
  .logo_top {
    align-self: center;
    height: 100%;
    top: -1.5vh;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    cursor: pointer;
    z-index: 4;
  }
  &__title {
    width: 55%;
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__userDisconnected {
    position: absolute;
    display: flex;
    width: 96.5%;
    // width: 18rem;
    justify-content: space-between;
    .rightBandeau {
      display: flex;
    }
    &__buttonAccess {
      display: flex;
      align-items: center;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0.7rem 1rem;
        background: c.$bleu-paris1;
        border-radius: 30px;
        color: white;
        border: 1px solid c.$bleu-paris1;
        text-transform: uppercase;
        font-family: c.$font-global;
        transition: all 0.5s;
        svg {
          margin-right: 0.5rem;
        }
      }
      button:hover {
        cursor: pointer;
        background: transparent;
        color: c.$bleu-paris1;
      }
    }
    &__home {
      display: flex;
      // justify-content: space-around;
      // width: 11rem;
      button {
        color: c.$bleu-paris1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        border: 1px solid c.$alumni_gris_border;
        background-color: transparent;
        font-weight: bold;
        padding: 0.5rem;
        transition: all 0.5s;
        margin-right: 1rem;
        &:hover {
          cursor: pointer;
          color: c.$blanc;
          background-color: c.$bleu-paris1;
        }
        svg {
          font-size: 1.5rem;
        }
      }
      &__contraste {
        svg {
          transform: rotateZ(-0.1turn);
        }
      }
    }
    .connexionInterfaceConnected {
      position: relative;
      z-index: 9999;
      width: 12rem;
      background-color: c.$blanc;
      color: c.$bleu-commun;
      margin-left: 4rem;
      padding: 0.5rem;
      border-radius: 1rem;
      top: 3rem;
      right: 0;

      li,
      a {
        padding: 0.5rem;
        color: c.$bleu-commun;

        &:hover {
          cursor: pointer;
          font-weight: bold;
        }
      }
    }
    .connexionInterface {
      right: -1rem;
      position: absolute;
      z-index: 9999;
      text-align: center;
      width: 11rem;
      background-color: c.$blanc;
      color: c.$bleu-commun;
      padding: 0.5rem;
      border-radius: 1rem;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      font-weight: normal;
      li {
        padding: 0.5rem;

        &:hover {
          cursor: pointer;
          font-weight: bold;
        }
      }
      &.language {
        right: 4rem;
      }
    }
    &__homeRight {
      button {
        color: c.$blanc;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 6.5rem;
        height: 3rem;
        border: 1px solid c.$alumni_gris_border;
        border-radius: 2rem;
        background-color: c.$alumni_bleu_medium;
        font-weight: bold;
        padding: 0.5rem;
        transition: all 0.5s;
        &:hover {
          cursor: pointer;
          color: c.$alumni_bleu_medium;
          background-color: c.$blanc;
        }
        svg {
          font-size: 1.2rem;
          margin-right: 0.5rem;
        }
      }
    }
    &__icon {
      font-size: 2rem;
    }
  }
  &__userConnected {
    position: absolute;
    display: flex;
    // width: 22rem;
    justify-content: space-evenly;
    &__home {
      display: flex;
      // justify-content: space-around;
      // width: 11rem;
      button {
        color: c.$bleu-commun;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        border: 1px solid c.$alumni_gris_border;
        background-color: transparent;
        font-weight: bold;
        padding: 0.5rem;
        transition: all 0.5s;
        margin-right: 1rem;
        &:hover {
          cursor: pointer;
          color: c.$blanc;
          background-color: c.$bleu-commun;
        }
        svg {
          font-size: 1.5rem;
        }
      }
      &__contraste {
        svg {
          transform: rotateZ(-0.1turn);
        }
      }
    }

    .connexionInterfaceConnected {
      position: absolute;
      z-index: 9999;
      width: 12rem;
      background-color: c.$blanc;
      color: c.$bleu-commun;
      margin-left: 4rem;
      padding: 0.5rem;
      border-radius: 1rem;
      top: 3rem;
      right: 0;

      li,
      a {
        padding: 0.5rem;
        color: c.$bleu-commun;

        &:hover {
          cursor: pointer;
          font-weight: bold;
        }
      }
    }
    .connexionInterface {
      top: 3rem;
      left: -3rem;
      position: absolute;
      z-index: 9999;
      text-align: center;
      width: 11rem;
      background-color: c.$blanc;
      color: c.$bleu-commun;
      margin-left: 7rem;
      padding: 0.5rem;
      border-radius: 1rem;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      font-weight: normal;
      li {
        padding: 0.5rem;

        &:hover {
          cursor: pointer;
          font-weight: bold;
        }
      }
    }
    &__homeRight {
      button {
        color: c.$blanc;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 6.5rem;
        height: 3rem;
        border: 1px solid c.$alumni_gris_border;
        border-radius: 2rem;
        background-color: c.$alumni_bleu_medium;
        font-weight: bold;
        padding: 0.5rem;
        transition: all 0.5s;
        &:hover {
          cursor: pointer;
          color: c.$alumni_bleu_medium;
          background-color: c.$blanc;
        }
        svg {
          font-size: 1.2rem;
          margin-right: 0.5rem;
        }
      }
    }
    &__icon {
      font-size: 2rem;
    }
  }
}
// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$small) {
  .header__bandeau {
    display: none;
  }
}

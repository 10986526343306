@use "src/Styles/_vars.scss" as c;

.ql-container {
  background-color: c.$blanc;
}
.actu_formulaire {
  padding: 0 7rem;
  position: relative;
  background-color: c.$fond-page;
  &__title {
    padding-top: 4rem;
    padding-bottom: 2rem;
    color: c.$bleu-paris1;
    font-family: c.$font-gros-titres;
    font-size: xx-large !important;
    &__filtres {
      button {
        position: relative;
        top: -4rem;
        right: -96rem;
        border: 1px solid c.$bleu-paris1;
        background-color: transparent;
        box-sizing: border-box;
        border-radius: 30px;
        width: fit-content;
        height: 2.5rem;
        color: c.$bleu-paris1;
        transition: all 0.5s;
        padding: 0rem 1.5rem;
        font-weight: 700;
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        svg {
          margin-left: 0;
          margin-right: 0.5rem;
        }
      }
      button:hover {
        cursor: pointer;
        background-color: c.$bleu-paris1;
        color: white;
      }
      text-align: right;
    }
  }
  &__container {
    h2 {
      color: c.$bleu-paris1;
      font-family: c.$font-sous-menu;
      font-size: medium;
      margin-bottom: 0.7rem;
    }
    small {
      display: block;
    }
    h2.p-error {
      display: block;
      color: c.$erreur;
    }
    .top_actu {
      display: flex;
      padding-bottom: 2rem;
      .title_actu {
        width: 30%;
        margin-right: 15rem;
      }
      .category_actu {
        width: 30%;
        margin-right: 5rem;
        .p-dropdown.p-component.p-inputwrapper.input_actu {
          width: 100% !important;
        }
      }
    }
    .middle_actu {
      padding-bottom: 2rem;
      .chapo_actu {
        width: 100%;
        padding-bottom: 2rem;
      }
      .description_actu {
        width: 100%;
      }
      .select_actu {
        padding-bottom: 2rem;
      }
    }
    .bot_actu {
      display: flex;
      padding-bottom: 2rem;
      .date_actu {
        width: 30%;
        margin-right: 5rem;
      }
      .auteur_actu {
        width: 30%;
      }
    }
    .url_container {
      padding-bottom: 2rem;
      .url_actu {
        padding-bottom: 1rem;
        width: 38%;
      }
      a {
        color: c.$bleu-paris1 !important;
        display: flex;
        align-items: center;
        cursor: pointer;
        i {
          margin-right: 0.5rem;
        }
      }
    }
    .image_actu {
      padding-bottom: 2rem;
      img {
        padding-bottom: 1.5rem;
      }
      &__buttons {
        padding-bottom: 1rem;
        .first_button {
          margin-right: 1rem;
          width: 35%;
          margin-bottom: 1rem;
        }
        .second_button {
          width: 45%;
        }
      }
      &__checkbox {
        display: flex;
        color: c.$bleu-paris1;
        .private {
          margin-right: 4rem;
        }
        .public,
        .prive,
        .private {
          i {
            margin-right: 0.1rem;
          }
          label {
            margin-left: 0.5rem;
          }
        }
      }
    }
    .buttons_bottom {
      display: flex;
      margin-top: 1rem;
      .publier_button {
        margin-top: 0;
      }
      .waiting_button {
        width: 10rem;
        .loaderContainer {
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          .loaderContainer__loader {
            width: 2rem;
            height: 2rem;
          }
          .loaderContainer__loader::after {
            width: 2rem;
            height: 2rem;
          }
        }
      }
      .enregistrer_button {
        max-width: 33%;
        margin: 0 0 4rem 2rem;
      }
      .annuler_button {
        width: fit-content;
        margin: 0 0 4rem 2rem;
      }
      .modal-buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
      }
    }
  }
}

#pr_id_1_content {
  margin: 0;
}

@media screen and (max-width: c.$small) {
  .actu_formulaire {
    padding: 0 1rem;
    &__container {
      padding: 0;
      .top_actu {
        display: block;
        .title_actu {
          margin-top: 1rem;
          width: 100%;
          margin-bottom: 0.5rem;
        }
        .category_actu {
          width: 100%;
        }
      }
      .url_container {
        .url_actu {
          width: 100% !important;
        }
      }
      .middle_actu {
      }
      .buttons_bottom {
        .enregistrer_button {
          font-size: 0.7rem;
        }
        .annuler_button {
          font-size: 0.7rem;
        }
      }
    }
    &__title {
      &__filtres {
        padding: 1rem 0;
        margin-top: 0;
        button {
          font-size: small;
        }
      }
    }
    h1 {
      padding: 1rem 0;
    }
  }
}

@use "src/Styles/_vars.scss" as c;

.mass_actions_buttons {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  button {
    height: 2rem;
    margin-right: 1rem;
  }
  &_numberOffer {
    height: 2rem;
    display: flex;
    flex-direction: row;
    &_text {
      color: c.$bleu-paris1;
      align-self: center;
      margin-right: 0.5rem;
      width: 8rem;
    }
    .p-dropdown.p-component.p-inputwrapper {
      height: 2rem;
      width: 6rem;
      min-width: fit-content !important;
    }
    .p-dropdown-label {
      width: 2rem;
      padding-left: 1rem;
      padding-right: 1rem !important;
    }
  }
}

.mass_actions_buttons_confirmation {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  span {
    margin-bottom: 1rem;
  }
  button {
    align-self: center;
  }
}

@media screen and (max-width: c.$small) {
  .mass_actions_buttons {
    flex-direction: column;
    align-items: flex-start;
    button {
      margin-bottom: 0.5rem;
    }
  }
}

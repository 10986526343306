@use "src/Styles/_vars.scss" as c;

.groupe_card_demande_container {
  background-color: white;
  height: fit-content;
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  &__logo {
    img {
      margin-left: 1rem;
      height: 4rem;
      width: 4rem;
      border-radius: 2rem;
      margin-right: 1rem;
    }
  }
  &__info {
    width: 60%;
    h2 {
      font-size: small;
      font-family: c.$font-global;
    }

    h6 {
      font-family: c.$font-global;
      color: c.$bleu-paris1;
      font-size: medium;
      margin: 0;
      padding: 0;
    }
    h6.title {
      color: c.$bleu-paris1;
      font-family: c.$font-sous-menu;
      margin-bottom: 0.5rem;
      font-size: medium;
      margin: 0 0 0.5rem 0;
      padding: 0;
    }

    &__bouton {
      display: flex;
      justify-content: space-between;
      margin-right: 1rem;
      margin-top: 0.5rem;
      .btn-blanc {
        padding: 0 0.25rem;
        height: 1.5rem;
      }
      .accepter {
        .btn-blanc {
          border: none;
          color: green;
          background-color: transparent;
        }
      }
      .refuser {
        .btn-blanc {
          border: none;
          color: red;
          background-color: transparent;
        }
      }
    }
  }
}

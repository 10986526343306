@use "src/Styles/_vars.scss" as c;

.sitemapContainer {
  padding: 0rem 7rem 4rem;
  .h1_trait_dessus {
    font-family: c.$font-gros-titres;
    color: c.$bleu-paris1;
    font-size: 3.5rem !important;
    padding-top: 4rem;
    padding-bottom: 2rem;
    margin-bottom: 1rem;
  }
  .h2_sitemap {
    font-family: c.$font-gros-titres;
    color: c.$bleu-paris1;
    margin-bottom: 0.5rem;
    font-weight: 600;
    letter-spacing: 1.5px;
  }
  &__global {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    &__left {
      width: 50%;
    }
    &__container {
      width: 50%;
      margin-bottom: 4rem;
      ol {
        padding-left: 0rem !important;
      }
      ul {
        .liServices:nth-child(8) {
          font-size: 1.2rem;
          position: relative;
          padding: 5px 0 5px 30px;
          font-weight: 700;
          color: c.$bleu-paris1;
          text-decoration: underline;
        }
        .liServices:nth-child(11) {
          font-size: 1.2rem;
          position: relative;
          padding: 5px 0 5px 30px;
          font-weight: 700;
          color: c.$bleu-paris1;
          text-decoration: underline;
        }
        li {
          position: relative;
          padding: 5px 0 5px 30px;
          font-size: 1.2rem;
          cursor: pointer;
        }
        li::after {
          border-left: 3px solid c.$bleu-paris1;
          content: "";
          height: 100%;
          left: 0;
          position: absolute;
          top: 1px;
          width: 0;
        }
        li::before {
          background-color: c.$bleu-paris1;
          background-position: 0 0;
          content: "";
          height: 2px;
          left: 0;
          position: absolute;
          top: 17px;
          width: 18px;
        }
      }
    }
  }
}

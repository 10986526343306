@use "src/Styles/_vars.scss" as c;

.description__postulate {
  display: flex;
  flex-direction: column;
  .message_recruteur {
    margin-top: 1rem;
    font-family: "font-sous-menu" !important;
    font-size: 1rem !important;
    color: c.$bleu-paris1;
  }
  .text-area-pop-in {
    margin-bottom: 1rem;
  }
  .checkbox_RP,
  .checkbox_Own {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    span {
      font-size: medium !important;
      color: c.$bleu-paris1;
    }
  }

  .checkbox_disabled {
    cursor: not-allowed;

    .p-checkbox-box {
      background-color: #e0e0e0;
      border-color: #bdbdbd;
    }

    span {
      color: #bdbdbd;
    }
  }
  .download {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    align-items: center;
    .upload_icone {
      width: 22px;
      height: 22px;
      color: c.$bleu-paris1;
    }
    .download_CV {
      margin-left: 0.5rem;
    }
  }

  .postulate_buttons {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    .btn-bleu {
      margin-right: 1rem;
    }
  }
}

@use "src/Styles/_vars.scss" as c;

.event_card_container {
  width: 100%;
  background-color: white;
  box-shadow: 0px 2px 4px lightgray;
  border: 2px solid c.$bleu-secondaire;
  border-radius: 0.6rem;
  height: 6rem;
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  &__logo {
    width: 12%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-left: 1rem;
      height: 4rem;
      margin-right: 0.5rem;
      border-radius: 2rem;
      width: 4rem;
    }
    .groupe_menu.mobile {
      display: none;
    }
    .groupe_menu {
      display: flex;
      justify-content: flex-end;
      .btn-rond {
        color: c.$bleu-paris1;
        height: 2rem;
        width: 3rem;
        background-color: transparent;
        margin-bottom: 1rem;
      }
    }
  }
  &__info {
    width: 65%;
    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.5rem;
    }
    h5 {
      font-family: c.$font-global;
      color: c.$bleu-paris1;
      margin-bottom: 0;
      font-weight: 600;
      font-size: c.$font-size-x-small;
      text-transform: uppercase;
    }
    h1.title {
      color: c.$bleu-paris1;
      font-family: c.$font-sous-menu;
      margin-bottom: 0.5rem;
    }
    &__bottom {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      color: c.$gris-picto;
      font-family: c.$font-global;
      h2 {
        align-items: center;
        display: flex;
      }
      i {
        margin-right: 0.5rem;
      }
      &__dates {
        margin-right: 0.5rem;
        h2 {
          margin: 0;
        }
      }
    }
    h2 {
      font-size: small;
      margin-right: 1rem;
    }
  }
  &__bouton {
    margin-right: 1rem;
    justify-self: flex-end;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    width: 7rem;
    .groupe_menu {
      display: flex;
      justify-content: flex-end;
      .btn-rond {
        color: c.$bleu-paris1;
        height: 2rem;
        width: 3rem;
        background-color: transparent;
        margin-bottom: 1rem;
      }
      .p-menuitem-text {
        display: flex;
        justify-content: center;
      }
    }

    span {
      padding: 0 1rem;
      border-radius: 0.33rem;
      display: flex;
      justify-content: center;
    }
    .brouillon {
      background-color: c.$fond-page;
      color: c.$gris-picto;
    }
    .publie {
      background-color: c.$vert-groupe;
    }
    .attente {
      background-color: c.$orange-groupe;
    }
    .cloture {
      background-color: c.$rouge-groupe;
    }
  }
}

.p-menu a.p-menuitem-link {
  padding: 0.75rem 0.5rem 0.75rem 0.75rem;
}

@media screen and (max-width: c.$medium) {
  .event_card_container {
    flex-direction: column;
    flex-wrap: nowrap;
    height: fit-content;
    padding: 1rem 0;
    justify-content: center;
    &__logo {
      .groupe_menu.mobile {
        display: unset;
      }
    }
    &__info {
      padding: 0.5rem 1rem;
      width: fit-content;
      h5 {
        display: flex;
        justify-content: center;
      }
      &__bottom {
        justify-content: center;
        &__dates {
          margin-bottom: 0.5rem;
        }
      }
    }
    &__bouton {
      justify-content: center;
      .groupe_menu {
        justify-content: center;
      }
      .groupe_menu.desktop {
        display: none;
      }
    }
  }
}

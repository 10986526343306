@use "src/Styles/_vars.scss" as c;

.error401 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  font-size: x-large;
  font-family: c.$font-global;
  img {
    height: 15rem;
  }
}

@use "src/Styles/_vars.scss" as c;

.modal.modal_désactivation-commentaire {
  width: 50% !important;
}

.desactiver_commentaires {
  border: 1vh;
  padding-top: 5vh;
  &-content {
    margin: 2rem 0 !important;
    color: c.$bleu-paris1;
  }
  &-buttons {
    display: flex;
    justify-content: space-between;
    column-gap: 4vw;
  }
}

@use "src/Styles/_vars.scss" as c;

.saved_offers {
  padding: 0 7rem;
  background-color: c.$fond-page;
  &_container {
    display: grid;
    gap: 1rem 3.7rem;
    grid-template-rows: auto;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 23rem));
  }
  h1 {
    margin-bottom: 1rem;
    font-family: "font-gros-titres";
    font-size: xx-large !important;
    color: c.$bleu-paris1;
    padding-bottom: 2rem;
  }
}

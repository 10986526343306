@use "src/Styles/_vars.scss" as c;

.footer {
  background-color: c.$bleu-paris1;
  padding-top: 2rem;
  &__column {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;

    padding: 0 0.5rem;
  }
  &__top {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 1rem;
    // min-height: 15rem;
    &__text {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: flex-start;
    }
    &__li {
      margin: 1.5rem 3rem;
      li {
        color: c.$blanc;
        font-family: c.$font-global;
        margin-bottom: 0.5rem;
        font-size: small;
      }
      img {
        cursor: pointer;
        height: 4rem;
        margin-bottom: 1rem;
      }
      &__socialMedia {
        display: flex;
        justify-content: space-evenly;
        margin-top: 0.5rem;
        a {
          svg {
            font-size: 1.5rem;
            color: white;
          }
        }
      }
    }

    .trait_dessus {
      border-top: 2px solid white;
      width: 30px;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
    .h1_trait_dessous.footerTrait::before {
      background-color: c.$blanc !important;
    }
    h6 {
      color: c.$blanc;
      font-family: c.$font-sous-menu;
    }
  }
  &__bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    background-color: c.$blanc;
    color: c.$bleu-paris1;
    min-height: 4rem;
    padding: 1rem 7rem;
    h6 {
      margin-left: 0;
      font-weight: 700;
      font-family: c.$font-global;
      font-size: small;
    }
    h6:nth-child(n + 1) {
      margin-left: 1rem;
    }
    &__left {
      color: c.$bleu-paris1;
      font-family: c.$font-global;

      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      margin: 1rem;
    }
    &__right {
      color: c.$bleu-paris1;
      font-family: c.$font-global;
      margin: 1rem 0;
      display: flex;
      flex-direction: column;
      row-gap: 1svh;
      h6.build {
        text-align: center;
        font-style: italic;
        font-size: x-small;
      }
    }
    img {
      width: 5rem;
    }
  }
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$small) {
  .footer {
    &__top {
      &__text {
      }
    }
    &__column {
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
    }
    &__bottom {
      flex-direction: column;
      padding: 0;
      align-items: flex-start;
      h6 {
        margin: 0.3rem 0 !important;
      }
      &__left {
        flex-direction: column;
        margin-left: 3.5rem;
        margin-bottom: 0rem;
      }
      img {
        margin-left: 3.5rem;
        margin-top: 1rem;
      }
      &__right {
        margin-left: 3.5rem;
      }
    }
  }
}
@media screen and (max-width: c.$tiny) {
  .footer {
    &__top {
      &__text {
      }
    }
    &__column {
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
    }
  }
}
@media screen and (min-width: c.$small) {
}

@use "src/Styles/_vars.scss" as c;

.search_people_component {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;

  &_search {
    display: flex;
    flex-direction: row;

    &_inputs {
      display: flex;
      flex-direction: row;

      &_firstname,
      &_surname {
        display: flex;
        flex-direction: column;
        margin-right: 2rem;
      }

      label {
        font-weight: 600;
        color: c.$bleu-paris1;
      }

      .p-inputtext.p-component {
        width: 15rem !important;
        margin-bottom: 1rem;
      }
    }

    button {
      align-self: center;
    }
  }

  &_amount {
    margin: 3svh 0;
  }

  &_results {
    display: flex;
    flex-direction: column;
    background-color: c.$blanc;
    margin: 2svh 0;
    width: 30svw;
    height: 15rem;
    overflow-y: scroll;
    border-radius: 1rem;

    span {
      &.selected {
        color: c.$bleu-paris1;
        font-weight: bold;
      }

      cursor: pointer;
      padding: 0.5rem;

      &:hover {
        color: c.$gris-search;
        background: c.$blanc;
      }
    }
  }
}

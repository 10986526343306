@use "src/Styles/_vars.scss" as c;

.btn-bleu.left {
  background: c.$bleu-paris1;
  border-radius: 30px;
  color: white;
  border: 1px solid c.$bleu-paris1;
  width: fit-content;
  height: 3rem;
  transition: all 0.5s;
  padding: 0rem 1.5rem;
  font-weight: 700;
  font-size: medium;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 4rem;
  margin-top: 3rem;
  svg {
    margin-left: 0;
    margin-right: 0.5rem;
    // margin-top: 0.25rem;
  }
}
.btn-bleu.left:hover {
  cursor: pointer;
  background-color: transparent !important;
  color: c.$bleu-paris1;
}

@media screen and (max-width: c.$small) {
  .btn-bleu.left {
    font-size: small;
  }
}

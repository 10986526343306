@use "src/Styles/_vars.scss" as c;

.chargement_image {
  &-image-standard {
    img {
      display: block;
      max-width: 300px;
      max-height: 200px;
      width: auto;
      height: auto;
    }
  }
  &-image-long {
    img {
      display: block;
      max-width: 500px;
      max-height: 200px;
      width: auto;
      height: auto;
    }
  }
  &__infos {
    font-size: 0.6rem;
  }
}

.p-galleria {
  max-width: 1000px;
}

#pr_id_5_content {
  margin: 0;
}

.cover-standard {
  display: block;
  max-width: 600px;
  max-height: 500px;
  width: auto;
  height: auto;
}

.thumbnail-standard {
  display: block;
  max-width: 120px;
  max-height: 120px;
  width: auto;
  height: auto;
}

.thumbnail-long {
  display: block;
  max-width: 150px;
  max-height: 150px;
  width: auto;
  height: auto;
}

.is_uploaded {
  display: none !important;
}

#chargement_image__container__item-button:hover {
  background-color: c.$fond-page !important;
  color: c.$bleu-paris1 !important;
  text-decoration: none !important;
}

#chargement_image__container__item-button:focus {
  outline: none !important;
  box-shadow: none !important;
  box-sizing: 0 !important;
}

#chargement_image__container__item-button {
  outline: none;
  background-color: c.$fond-page !important;
  padding: 0;
  overflow: visible;
  width: 30%;
  text-align: left;
  border: none;
  font-size: small !important;
  color: c.$bleu-paris1 !important;
  span {
    background-color: c.$fond-page !important;
    display: block;
    color: c.$bleu-paris1 !important;
    text-decoration: none !important;
  }
}

#chargement_image__container__item-button_last {
  outline: none;
  background-color: c.$fond-page !important;
  padding: 0;
  overflow: visible;
  width: 30%;
  text-align: left;
  border: none;
  font-size: small !important;
  color: c.$bleu-paris1 !important;
  span {
    background-color: c.$fond-page !important;
    display: block;
    color: c.$bleu-paris1 !important;
    text-decoration: none !important;
  }
  padding-bottom: 5vh;
}

@media screen and (max-width: c.$small) {
  .chargement_image {
    &__container {
      flex-direction: column;
      &__item {
        width: 100%;
      }
    }
  }

  .p-galleria {
    max-width: 400px;
  }

  .cover-standard {
    max-width: 300px;
    max-height: 300px;
  }

  .cover-long {
    max-width: 400px;
    max-height: 300px;
  }

  .thumbnail-standard {
    max-width: 60px;
    max-height: 60px;
  }

  .thumbnail-long {
    max-width: 110px;
    max-height: 200px;
  }
}

.fileupload .p-fileupload-choose .p-button-label {
  font-size: small !important;
}

@use "src/Styles/_vars.scss" as c;

@media screen and (max-width: c.$small) {
  .annuaire {
    #header {
      .menu {
        min-height: fit-content;
        background-color: c.$blanc;
        .styleNavBarActive {
          .navBarProfil {
            background-color: c.$fond-page !important;
          }
        }
      }
    }
    .annuaire_search_bar.etudiant_bar {
      background-color: c.$fond-page;
    }
    .annuaire_search_bar {
      &__title {
        color: black;
      }
    }
  }
}

@use "src/Styles/_vars.scss" as c;

.evenements_container {
  background-color: c.$fond-page;
  padding-left: 7rem;
  padding-right: 7rem;
  .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 2.25rem;
  }

  .btn-blanc {
    height: 2.5rem;
  }
  a {
    .evenement_container {
      width: 100%;
    }
  }
  &__top {
    h1.h1_trait_dessus {
      font-family: c.$font-gros-titres;
      color: c.$bleu-paris1 !important;
      font-size: 3.5rem !important;
      padding-top: 4rem;
      padding-bottom: 2rem;
      margin-bottom: 1rem;
      font-weight: 700;
    }
    h1.h1_trait_dessus::before {
      background-color: c.$bleu-paris1 !important;
    }
    &__title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      flex-wrap: wrap;
      margin-bottom: 3rem;
      .p-dropdown.p-dropdown-clearable .p-dropdown-label {
        padding-right: 3.25rem;
        padding-left: 1rem;
      }
      .p-dropdown.p-component.p-inputwrapper.date {
        .p-dropdown-label.p-inputtext.p-placeholder {
          margin-right: 1rem;
        }
      }
      .p-dropdown-label.p-inputtext.p-placeholder {
        padding-right: 2.25rem;
      }
      .p-accordion .p-accordion-content {
        padding: unset !important;
      }
      .p-accordion .p-accordion-header .p-accordion-header-link {
        padding: unset !important;
      }
      .accordion_events {
        color: c.$bleu-paris1;
        margin-left: 0rem !important;
        a {
          justify-content: unset;
          padding: 1rem 0 !important;
        }
      }
      &__filtres {
        display: flex;
        justify-content: flex-end;
        position: relative;
        .btn-blanc {
          margin-left: 2rem;
          width: 13rem;
          padding-right: 0.25rem;
          padding-left: 0.25rem;
        }
        &__searchInput {
          position: relative;
        }
        &__refresh {
          color: c.$bleu-paris1;
          display: flex;
          align-items: center;
          margin: 0 1rem;
          cursor: pointer;
        }
        .management_new_event_offer_filtres__top__date {
          margin-top: 0 !important;
        }
      }
      .searchBar_groupe_finder_icon {
        position: absolute;
        color: c.$gris-picto;
        font-size: 1.5rem;
        top: 0.55rem;
        margin-left: 0.7rem;
      }
      .membership_input_text {
        padding-left: 2.5rem;
        border-radius: 2rem;
        border: 1px solid c.$gris-picto;
      }
      .p-dropdown.p-component.p-inputwrapper {
        margin-left: 2rem;
        min-width: 14rem !important;
      }
      .p-dropdown-label.p-inputtext.p-placeholder {
        padding-left: 1rem;
      }
    }
  }
  // style différent de son style général
  .btn-bleu {
    width: fit-content;
  }
  p {
    font-family: c.$font-global;
    text-justify: auto;
  }
  &__body {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(16rem, 23rem));
    gap: 1rem 1rem;
    grid-auto-rows: auto;
    justify-content: space-evenly;
    &-empty {
      margin-top: 4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  &__button {
    display: flex;
    justify-content: center;
    padding-bottom: 4rem;
    margin-top: 2rem;

    &__article__pagination {
      padding: 2rem 0;
    }
    .p-paginator.p-component {
      background-color: c.$fond-page;
    }
  }
}
@media screen and (max-width: 1590px) {
  .evenements_container {
    &__body {
      margin-top: 2rem;
    }
  }
}
@media screen and (max-width: c.$normal) {
  .evenements_container {
    a {
      width: unset;
    }
  }
}
// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$small) {
  .evenements_container {
    padding-left: 1rem;
    padding-right: 1rem;
    .evenements_all {
      padding: 1rem 0;
    }
    &__body {
      grid-auto-rows: auto;
    }
    &__top {
      h1 {
        padding: 1rem 0 2rem 0;
      }
    }
    p {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
@media screen and (max-width: 1100px) {
  .evenements_container {
    &__body {
      justify-content: center;
    }
  }
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: 992px) {
  .evenements_container__body {
    justify-content: center !important;
  }
}
@media screen and (min-width: 1200px) {
  .evenements_container {
    &__top {
      &__title {
        .btn-blanc_visible {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1497px) {
  .evenements_container {
    &__top {
      &__title {
        &__filtres {
          margin-top: 2rem;
        }
      }
    }
  }
}

@media screen and (max-width: c.$medium) {
  .evenements_container {
    &__top {
      &__title {
        flex-direction: column;

        &__visible {
          display: none;
        }
        &__filtres {
          flex-direction: column;
          margin: 2rem 9rem 0 0;

          &__searchInput {
            margin: 0.5rem 0;
            max-width: 20rem;
          }
        }
        .searchBar_groupe_finder_icon {
          position: absolute;
          color: c.$gris-picto;
          font-size: 1.5rem;
          margin-left: 0.7rem;
        }
        .p-dropdown.p-component.p-inputwrapper {
          margin-left: unset;
          margin: 0.5rem 0;
        }
        .btn-blanc_visible {
          margin-top: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: c.$small) {
  .evenements_container {
    &__top {
      &__title {
        flex-direction: column;
        h1.h1_trait_dessus {
          font-size: 2.5rem !important;
        }
        &__filtres {
          flex-direction: column;
          margin-right: 0;
          justify-content: center;
          flex-wrap: wrap;
          padding-top: 2rem;
          .btn-blanc {
            margin-left: 0 !important;
          }
        }
      }
    }
  }
}

@use "src/Styles/_vars.scss" as c;

.listOfLocation {
  display: none;
  border-radius: 1rem;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  background-color: c.$blanc;
  position: absolute;
  // margin-left: 0.5rem;
  z-index: 10;
  margin-top: 2.5rem;
  .group {
    span {
      color: black;
      font-weight: bold;
    }
    .location {
      text-justify: distribute-all-lines;
      display: flex;
      flex-direction: column;
      padding: 0.5rem;
      padding-left: 1rem;

      cursor: pointer;
      &:hover {
        cursor: pointer;
        color: c.$bleu-paris1 !important;
      }
    }
  }
}

@use "src/Styles/_vars.scss" as c;
@use "src/index.scss" as font;

.p-slidemenu.p-component.p-slidemenu-overlay {
  left: 0px !important;
  top: 60px !important;
  height: fit-content;
}
.p-slidemenu .p-menuitem {
  height: fit-content !important;
}
.navigation {
  min-height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  background-color: c.$alumni_bleu_medium;
  font-family: c.$font-nav;
  justify-content: space-between;
  a {
    height: 100%;
  }
  .activeLink {
    position: relative;
    &::after {
      content: "";
      display: block;
      background-color: c.$blanc;
      height: 0.05rem;
      // position: absolute;
      width: 100%;
      // top: 2.2rem;
    }
  }

  &__hamburger {
    justify-self: flex-start;
    .p-button-icon.p-c.pi.pi-bars::before {
      font-size: 2rem;
    }
    .p-button.p-component {
      background-color: c.$bleu-paris1;
      color: c.$blanc;
    }

    .p-button:enabled:hover {
      border-color: c.$bleu-paris1 !important;
      background-color: c.$bleu-paris1 !important;
      color: white !important;
    }
    .p-button:focus {
      box-shadow: unset;
    }
    .slide {
      background-color: red !important;
    }
  }
}

.p-connected-overlay-enter-done {
  display: flex;
  justify-content: center;
  background-color: c.$fond-page;
}

.p-button.p-component {
  color: c.$bleu-paris1;
  border: white;
}
.p-menu .p-menuitem-link:hover {
  background-color: c.$bleu-paris1;
}
.p-menu-list.p-reset {
  width: 100%;
}
.p-menu .p-menuitem-link {
  &:hover {
    background-color: c.$bleu-paris1 !important;
    // span {
    //   color: white !important;
    // }
  }
}

.p-menuitem.logoRP_burger {
  background-image: url("../../assets/Reseauprologo.webp");
  background-repeat: no-repeat;
  height: 55px !important;
  width: 190px;
  margin-left: 1.5rem;
  margin-top: -0.4rem;
}
.p-menuitem.logoUnif_burger {
  background-image: url("../../assets/logo_unif_petit.webp");
  background-repeat: no-repeat;
  height: 50px !important;
  width: 190px;
  margin-left: 1.5rem;
  margin-top: 0.6rem;
}
.p-slidemenu .p-menuitem-link:hover {
  background-color: c.$bleu-paris1;
}

.p-slidemenu .p-menuitem-link {
  &:hover {
    background-color: c.$bleu-paris1 !important;
    // span {
    //   color: white !important;
    // }
  }
  .p-menuitem-text {
    color: c.$bleu-paris1 !important;
    font-weight: 700;
  }
  .p-submenu-icon {
    color: c.$bleu-paris1;
  }
}

.p-slidemenu .p-slidemenu-content {
  height: fit-content;
  width: 100%;
}
.p-slidemenu-rootlist.p-active-submenu {
  width: 100% !important;
}
.p-slidemenu-rootlist {
  position: relative !important;
}

.p-slidemenu-wrapper {
  height: fit-content !important;
  width: 100%;
}
.p-submenu-list.p-active-submenu {
  width: 190px !important;
  left: 180px !important;
}
.brand-name {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  width: 8rem;
  height: 4rem;
}
.img-profil {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  margin-right: 1rem;
  width: 8rem;
  height: 3.5rem;
  &__disconnected {
    height: 2rem;
    margin-right: 1rem;
    width: fit-content;
    color: white;
  }
}

.navigation-menu {
  margin: auto;
  display: flex;
  justify-content: center;
}

.navigation-menu ul {
  display: table;
  width: 100%;
  text-align: center;
  margin-bottom: unset;
}
.navigation-menu li {
  // removes default disc bullet for li tags and applies margin to left & right side
  list-style-type: none;
  margin: 0 1.2rem !important;
  display: inline-block;
}
.navigation-menu li a {
  // increases the surface area of the anchor tag to span more than just the anchor text
  text-decoration: none;
  display: block;
  width: 100%;
  color: white;
}
button {
  cursor: pointer;
}
.p-menu-separator {
  background-color: rgb(241, 241, 241);
  height: 1px;
  margin-top: -4px;
  margin-bottom: 4px;
  width: 100%;
}

barre-boutons p {
  font-family: c.$font-sous-menu;
  font-weight: 700;
}

.annuaire__button {
  position: relative;
  color: c.$blanc;
  align-items: center;
  justify-content: space-between;
  border: none;
  background-color: transparent;
  font-family: c.$alumni-nav-regular;
  padding: 0.5rem 0;
  border-radius: 2rem;
  font-size: 1.125rem;
  transition: all 0.5s;
}

.annuaireInterface {
  position: absolute;
  z-index: 99999;
  width: 18rem;
  background-color: c.$blanc;
  color: c.$bleu-commun;
  padding: 0.5rem;
  border-radius: 0.2rem;
  top: 2rem;
  left: -5.2rem;
  right: 0;

  .p-divider.p-component.p-divider-horizontal.p-divider-solid.p-divider-left {
    margin-top: 0;
  }
  .p-divider-horizontal.p-divider-left {
    margin: 0 10%;
    width: 80%;
  }
  li {
    margin: 0;
    width: 95%;
    padding: 0 0.5rem;
    a {
      width: 82%;
      margin-bottom: 0;
      font-size: 0.9rem;
    }
  }
  li,
  a {
    padding: 0.5rem;
    color: c.$bleu-commun !important;
    text-align: left;

    &:hover {
      cursor: pointer;
      font-weight: bold;
    }
  }
  p {
    color: c.$gris-picto;
    display: flex;
    text-align: start;
    justify-content: left;
    padding-top: 0.5rem;
  }
  .annuaireBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .border {
    border: none !important;
    border-bottom: 1px solid black !important;
  }
  .pi-user {
    margin: 0rem 0rem 0 0.2rem;
    color: c.$gris-picto;
  }
  .laurier {
    margin: 0rem 0rem 0 0rem;
    color: c.$gris-picto;
    width: 1.5rem;
    height: 1.5rem;
    color: c.$gris-picto;
  }
  .pi-building,
  .pi-bookmark,
  .pi-calendar,
  .pi-book,
  .pi-check,
  .pi-download,
  .pi-list {
    margin: 0rem 0rem 0 0.7rem;
    color: c.$gris-picto;
  }
  .pi-download {
    margin-bottom: 1rem;
    margin-left: 0;
  }
  .pi-star {
    display: none;
  }
  .pi-building {
    margin-left: 0rem;
  }
}
//? Style pour ajouter le laurier en icône dans la navBar
//TODO Ajouter le laurier en blanc quand le lien est actif
//TODO Voir pour modifier la taille de l'image
.navBarProfil {
  .p-menuitem-icon.pi-star {
    visibility: hidden;
  }
  .p-menuitem-icon.pi-star + span {
    position: relative;
  }
  .p-tabmenuitem .p-menuitem-icon.pi-star + span::before {
    display: flex;
    content: url("../../assets/laurier_gris_petit.webp");
    position: relative;
    width: 0.2rem;
    height: 0.2rem;
    z-index: 1000;
    top: -1.9rem;
    left: 2.5rem;
  }
  .p-tabmenuitem.p-highlight .p-menuitem-icon.pi-star + span::before {
    display: block;
    content: url("../../assets/laurier_blanc_petit.webp");
    position: absolute;
    width: 0.2rem;
    height: 0.2rem;
    z-index: 1000;
    top: -1.9rem;
    left: 2.5rem;
  }
}

.p-menu.p-component.p-menu-overlay.p-connected-overlay-enter-done {
  z-index: 100000 !important;
}
.p-submenu-header {
  display: flex;
  justify-content: flex-start;
}
@media screen and (max-width: c.$medium) {
  .navBarProfil {
    .p-menuitem-icon.pi-star {
      visibility: hidden;
    }
    .p-menuitem-icon.pi-star + span {
      position: relative;
    }
    .p-tabmenuitem .p-menuitem-icon.pi-star + span::before {
      display: flex;
      content: url("../../assets/laurier_gris_petit.webp");
      position: relative;
      width: 0.2rem;
      height: 0.2rem;
      z-index: 1000;
      top: -1.5rem;
      left: 3.3rem;
    }
    .p-tabmenuitem.p-highlight .p-menuitem-icon.pi-star + span::before {
      display: block;
      content: url("../../assets/laurier_blanc_petit.webp");
      position: absolute;
      width: 0.2rem;
      height: 0.2rem;
      z-index: 1000;
      top: -1.5rem;
      left: 3.3rem;
    }
  }
  .img-profil {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    margin-right: 1rem;
    width: 8rem;
    height: 3.5rem;
    border: 2px solid white;
    border-radius: 2rem;
    &__disconnected {
      height: 2rem;
      margin-right: 1rem;
      width: fit-content;
      color: white;
      font-size: 2.5rem;
    }
  }
}

// règles d'affichage qui limite l'apparition de la navigation classique
@media screen and (max-width: c.$small) {
  .navigation-menu {
    display: none;
  }
  .navigation {
    height: 40px;
    img {
      height: 100%;
      width: auto;
    }
  }
}
@media screen and (max-width: c.$small) {
}
@media screen and (min-width: c.$small) {
  .brand-name {
    display: none;
  }
  .img-profil {
    display: none;
    &__disconnected {
      display: none;
    }
  }
  .navigation__hamburger {
    display: none;
  }
}

@use "src/Styles/_vars.scss" as c;

// utilisé dans l'édition de profil
.profil_edit {
  .edit_profil__title {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: baseline;
    h1 {
      font-family: c.$font-gros-titres;
      color: c.$bleu-paris1;
      font-size: xx-large !important;
      margin-bottom: 0;
      padding: 4rem 0 2rem 0;
    }

    &__informationPersoForm {
      margin-top: 0;
    }
  }
}
.article_actu {
  .btn-bleu {
    width: fit-content;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
  p {
    font-family: c.$font-global;
    text-justify: auto;
  }
  padding-bottom: 5rem;
  background-color: c.$fond-page;

  .form-demo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 2rem;
    .card {
      background-color: c.$fond-page;
      border: none;
    }
    .btn-blanc {
      width: fit-content;
    }
    .form_columns {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .field {
        width: 100%;
        margin: 0 0 1.5rem 1rem;
        h2 {
          font-size: medium;
          font-size: large;
          color: c.$bleu-paris1;
          &.p-error {
            color: #e24c4c;
          }
        }
        width: 45%;
        .btn-blanc {
          width: fit-content;
        }
        .btn-bleu {
          width: fit-content;
          display: flex;
          justify-content: center;
        }
      }
      .field.ville:hover {
        .listOfAdresses {
          display: block !important;
          border-radius: 1rem;
          padding: 0.25rem;
        }
      }
      .field.ville {
        margin-bottom: 0;
        .listOfAdresses {
          background-color: c.$blanc;
          display: none;
          position: absolute;
          z-index: 10;
          // margin-top: 4.5rem;
          .searchedAdress {
            display: flex;
            flex-direction: column;
            padding: 0.5rem;
            cursor: pointer;
            &:hover {
              cursor: pointer;
              .adress,
              .department {
                color: c.$bleu-paris1;
              }
            }
            .adress {
              font-weight: bold;
            }
            .department {
              color: c.$gris-search;
              font-family: "font-global";
              font-size: medium;
            }
          }
        }
      }
    }
    .field {
      margin: 0 0 1.5rem 1rem;
      .field__calendrier {
        background-color: white;
        border-radius: 1rem;
        display: flex;
        flex-direction: row;
        svg {
          align-self: center;
          margin-right: 1rem;
          color: #6c757d;
          width: 1.3rem;
          height: 1.3rem;
        }
      }
    }
    .field.petit {
      width: 45%;
    }
    .privateCity {
      padding-left: 1rem;
    }
    .field.parrain {
      // display: flex;
      h3 {
        width: 100%;
      }
      .label_parrain {
        margin-left: 0;
      }
      .permis_container {
        display: flex;
        margin: 0.5rem 0;
      }
      .permis {
        width: fit-content;
        margin-right: 2rem;
      }
    }
    .field.checkbox {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
    }
    .field-checkbox {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      .p-checkbox {
        margin-top: 0 !important;
      }
    }
    .field {
      h3 {
        margin-top: 3rem;
        margin-bottom: 0;
        font-size: large;
        color: c.$bleu-paris1;
      }
      label {
        margin-left: 1rem;
      }

      h2 {
        margin: 0 0 0.5rem 1rem;
        font-size: large;
        color: c.$bleu-paris1;
        font-family: c.$font-sous-menu;
      }
      h1 {
        padding-top: 0rem;
      }
      &__photoProfil {
        margin: 2rem 0 1.5rem 1rem;
        .p-float-label {
          margin-bottom: 1rem;
          label {
            margin-left: 5rem;
          }
        }
      }
    }

    .form_boutons {
      display: flex;
      justify-content: space-between;
    }
  }

  .p-dropdown.p-component.p-inputwrapper {
    background-color: c.$blanc;
    width: 100%;
  }
}
.btnInfoPersoValider {
  margin-top: 0 !important;
}
// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$small) {
  // utilisé dans l'édition de profil
  .article_actu {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media screen and (min-width: c.$small) {
  // utilisé dans l'édition de profil
  .article_actu {
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

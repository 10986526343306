@use "src/Styles/_vars.scss" as c;

.groupe_menu_offers {
  .groupe_menu {
    display: flex;
    justify-content: flex-end;
    .btn-rond {
      color: c.$bleu-paris1;
      height: 2rem;
      width: 3rem;
      background-color: transparent;
      margin-bottom: 1rem;
    }
    &.mobile {
      display: none;
    }
  }
}

@media screen and (max-width: c.$medium) {
  .groupe_menu_offers {
    .groupe_menu.mobile {
      display: unset;
    }
    .groupe_menu.desktop {
      display: none;
    }
  }
}

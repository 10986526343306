@use "src/Styles/_vars.scss" as c;

.cardHoverActualite {
  transition-duration: 0.2s;
  background-color: c.$blanc;

  &:hover {
    cursor: pointer;
    transform: scale(1.01);
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
  }
}
.actualite_container {
  border-radius: 0.33rem;
  margin-bottom: 2rem;
  width: 100% !important;

  &__top {
    &__categorie {
      font-family: c.$font-global;
      font-size: c.$font-size-normal;
      font-weight: 700;
      color: c.$gris-picto;
      position: relative;
      display: flex;
      justify-content: flex-end;
      .etiquette {
        position: absolute;
        margin-top: 1rem;
        margin-right: 2rem;
        padding: 0.2rem 2rem;
        background-color: c.$fond-page-clair;
        color: grey;
        border-radius: 5px;
        z-index: 1000;
      }
    }
  }
  &__image {
    height: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 0.33rem 0.33rem 0 0;
    .image__bg {
      position: absolute;
      width: 110%;
      height: 110%;
      background-repeat: no-repeat;
      background-size: cover;
      filter: blur(4px);
      opacity: 0.6;
    }
    img {
      z-index: 900;
      max-width: 100%;
      max-height: 15rem;
      object-fit: cover;
      font-weight: 700;
      border-top-left-radius: 0.33rem;
      border-top-right-radius: 0.33rem;
    }
  }
  &__corps {
    height: 15rem;
    padding: 1rem 1rem 0 1rem;
    &__debutdate {
      font-family: c.$font-global;
      font-size: 0.9rem;
      color: c.$gris-picto;
      padding: 0 1rem 0 1rem;
    }
    &__title {
      font-family: c.$font-sous-menu;
      color: c.$noir-rp;
      margin: 2rem 2rem 1.5rem 1rem;
      font-size: c.$font-size-x-normal;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    &__chapeau {
      font-family: c.$font-global;
      font-size: 1rem;
      margin: 0 3rem 0rem 1rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem 2rem 1rem;
    &__lien {
      display: flex;
      font-family: c.$font-global;
      font-weight: 700;
      font-size: 1rem;
      color: c.$bleu-secondaire;
      padding: 1rem 1rem 0 1rem;
    }
    &__logo {
      img {
        width: 8rem;
        height: auto;
      }
    }
  }
}

@media screen and (max-width: c.$small) {
  .actualite_container {
    height: fit-content;
    &__corps__chapeau {
      height: fit-content;
    }
  }
}

@media screen and (max-width: c.$medium) {
  .actualite_container {
    height: fit-content;
    &__corps {
      height: fit-content;
    }
  }
}

@media screen and (max-width: c.$medium) and (min-width: 1000px) {
  .actualite_container {
    width: 100% !important;
  }
}

@use "src/Styles/_vars.scss" as c;
#mobile.styleNavBarActive {
  display: none;
}
.navBarProfil__scrollMenu {
  padding: 2rem 0;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  .p-scrollpanel.p-component {
    width: 100%;
  }
  .p-scrollpanel-bar {
    background-color: #1976d2;
    opacity: 1;
    transition: background-color 0.3s;
    .p-scrollpanel-bar.p-scrollpanel-bar-x {
      display: none;
      bottom: -90px !important;
    }
  }
  .p-scrollpanel.p-scrollpanel-wrapper {
    border-right: 10px solid var(--surface-b);
    border-bottom: 10px solid var(--surface-b);
  }
  .p-scrollpanel-content {
    padding: 0 !important;
    width: fit-content;
    overflow-y: hidden;
  }
  .p-scrollpanel-wrapper {
    width: fit-content !important;
  }
  .pi {
    font-size: 1.3rem !important;
  }
}

@media screen and (min-width: c.$medium) {
  .navBarProfil__scrollMenu {
    .p-scrollpanel-content {
      overflow: hidden;
    }
  }
}

@media screen and (max-width: c.$medium) {
  #desktop.styleNavBarActive {
    display: none;
  }
  #mobile.styleNavBarActive {
    display: unset;
  }

  .navBarProfil__scrollMenu {
    padding: 0;
    .p-tabmenu-nav.p-reset {
      width: fit-content !important;
    }
    .p-scrollpanel-bar-y {
      width: 0;
    }
    .navBarProfil__tabMenu {
      .p-menuitem-icon.pi.pi-fw.pi-building::before,
      .p-menuitem-icon.pi.pi-fw.pi-user::before,
      .p-menuitem-icon.pi.pi-fw.pi-star::before,
      .p-menuitem-icon.pi.pi-fw.pi-comments::before,
      .p-menuitem-icon.pi.pi-fw.pi-search::before,
      .p-menuitem-icon.pi.pi-fw.pi-plus-circle::before,
      .p-menuitem-icon.pi.pi-fw.pi-user-edit::before,
      .p-menuitem-icon.pi.pi-fw.pi-briefcase::before,
      .p-menuitem-icon.pi.pi-fw.pi-bookmark::before,
      .p-menuitem-icon.pi.pi-fw.pi-file::before,
      .p-menuitem-icon.pi.pi-fw.pi-calendar::before,
      .p-menuitem-icon.pi.pi-fw.pi-book::before,
      .p-menuitem-icon.pi.pi-fw.pi-lock::before,
      .p-menuitem-icon.pi.pi-fw.pi-users::before,
      .p-menuitem-icon.pi.pi-fw.pi-user-plus::before,
      .p-menuitem-icon.pi.pi-fw.pi-pencil::before,
      .p-menuitem-icon.pi.pi-fw.pi-cog::before {
        display: none !important;
      }
      align-items: center;
      .p-tabmenuitem .p-menuitem-icon.pi-star + span::before {
        display: flex;
        content: none;
        position: relative;
        width: 0.2rem;
        height: 0.2rem;
        z-index: 1000;
        top: -1.5rem;
        left: 3.5rem;
      }
      .p-tabmenuitem.p-highlight .p-menuitem-icon.pi-star + span::before {
        display: block;
        content: none;
        position: absolute;
        width: 0.2rem;
        height: 0.2rem;
        z-index: 1000;
        top: -1.5rem;
        left: 3.5rem;
      }
    }
  }
}

@use "src/Styles/_vars.scss" as c;

.profilBarShow {
  display: flex;
  align-items: center;
  width: 70%;
  margin: 0 15%;
  border-radius: 0.33rem;
  height: 14rem;
  position: relative;
  &__top {
    display: flex;
    background-color: c.$blanc;
    padding: 1rem 2rem;
    border-radius: 0.33rem;
    width: 100%;
    height: 100%;
    align-items: center;
    &__image {
      width: 8rem;
      margin: 0 2rem;
      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
        border-radius: 50%;
        border: 2px solid c.$bleu-paris1;
      }
      .profilBarShow__middle {
        margin: 0 1rem;
        width: 70%;
        &__parrain {
          background-color: c.$fond-parrain;
          color: c.$texte-parrain;
          border-radius: 0.33rem;
          height: 1.5rem;
          display: flex;
          align-items: center;
          width: 8.5rem;
          p {
            text-transform: uppercase;
            margin: 0.5rem 0.5rem 0.5rem 0.8rem;
            font-size: x-small !important;
          }
        }
      }
    }
    &__middle {
      display: flex;
      flex-direction: column;
      justify-content: center;
      h2 {
        font-family: c.$font-gros-titres;
        margin-bottom: 0;
      }
      h3 {
        font-family: c.$font-sous-menu;
        font-size: 1.2rem;
      }
      &__parrain {
        background-color: c.$fond-parrain;
        color: c.$texte-parrain;
        border-radius: 0.33rem;
        height: 1.5rem;
        display: flex;
        align-items: center;
        width: 8.5rem;
        p {
          display: flex;
          align-items: center;
          text-transform: uppercase;
          margin: 0.5rem 0.5rem 0.5rem 0.8rem !important;
          font-size: x-small !important;
          svg {
            margin-right: 0.25rem;
          }
        }
      }
    }
  }
  &__right {
    background-color: c.$blanc;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: c.$blanc;
    padding: 1rem 2rem;
    border-radius: 0.33rem;
    position: absolute;
    top: 16rem;
    &__item {
      margin-left: 1rem;
      font-size: medium;
      svg {
        margin-right: 0.5rem;
      }

      label {
        display: flex;
        align-items: center;
        font-weight: 700;
        margin-left: 0;
        img {
          height: 1rem;
          width: 1rem;
          margin-right: 0.5rem;
        }
      }
      p {
        display: flex;
      }
    }
    &__logos {
      margin-left: 1rem;
      img {
        margin-right: 0.5rem;
      }
    }
    // style du texte
    h2 {
      font-family: c.$font-gros-titres;
      text-transform: uppercase;
      font-size: x-large;
    }
    h3 {
      font-family: c.$font-global;
      font-weight: 700;
      font-size: medium;
    }
    p {
      font-family: c.$font-global;
      font-size: small;
      margin-bottom: 0.5rem;
      a:hover {
        color: c.$bleu-secondaire !important;
      }
    }
  }
}

.btn_ProfilBarShow {
  display: flex;
  justify-content: flex-end;
  margin: 8rem 7rem 0 0;
}

@media screen and (max-width: c.$medium) {
  .profilBarShow {
    height: unset;
    width: 80%;
    margin: 0 10%;
    flex-direction: column;
    &__right {
      position: unset;
      margin-top: 2rem;
    }
  }
}
@media screen and (max-width: c.$small) {
  .profilBarShow {
    flex-direction: column;
    margin: 0 auto;
    height: fit-content;
    width: fit-content;
    box-shadow: 0px 2px 0.2px lightgray;
    .p-divider.p-divider-vertical {
      display: none;
    }
    .p-divider.p-component.p-divider-horizontal.p-divider-solid.p-divider-left {
      display: unset;
      margin-top: 0;
      justify-content: center;
      width: 80%;
    }
    &__top {
      flex-direction: column;
      &__middle {
        width: fit-content;
        margin: 1rem 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        p {
          text-align: center;
        }
      }
    }
    &__right {
      padding-bottom: 1rem;
      margin: 2rem 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      &__item {
        margin-left: 0;
        align-items: center;
        display: flex;
        flex-direction: column;
        p {
          display: flex;
          justify-content: center;
          font-size: x-small;
        }
      }
    }
  }
}

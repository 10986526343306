@use "src/Styles/_vars.scss" as c;

.ReseauDynamique_container {
  min-width: 100%;
  min-height: 30rem;
  display: flex;

  &__left {
    min-width: 55%;
    &__img {
      width: 100%;
      height: 100%;
    }
  }
  &__right {
    min-width: 45%;
    background-color: c.$blanc;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 4rem 10rem 0rem 10rem;
    &__title {
      font-size: 1.813rem;
      color: c.$bleu-paris1;
      font-family: c.$font-gros-titres;
      margin-bottom: 0.5rem;
    }
    &__text {
      color: c.$bleu-paris1;
      font-size: 1.1rem;
    }
    &__img {
      min-height: fit-content;
      margin-top: 5vh;
      margin-bottom: 5vh;
    }
  }
}

@media screen and (max-width: 830px) {
  .ReseauDynamique_container {
    display: block;
    &__left {
      display: none;
    }
    &__right {
      padding: 2rem 4rem 1rem 4rem;
      &__img {
        top: 17rem;
      }
      &__button {
        height: 2.5rem !important;
      }
    }
  }
}

[data-theme="light"] {
  --main-color-univ: #00326e;
  --secondary-color: #4190ee;
  --fond-page-color: #f1f3f6;
  --fond-page-clair-color: #fafafa;
  --default-color: #fff;
  --gris-search: #6c757d;
  --gris-picto: #8a8a8a;
}
[data-theme="dark"] {
  --main-color-univ: #2c2c2c;
  --secondary-color: #3f3f3f;
  --fond-page-color: #b1b1b1;
  --fond-page-clair-color: #f5f5f5;
  // --fond-page-clair-color: #c8c8c8;
  // --default-color: #e2e2e2;
  --default-color: #fff;
  --gris-search: #2c2c2c;
  --gris-picto: #2c2c2c;
}

$bleu-paris1: var(--main-color-univ);
$bleu-secondaire: var(--secondary-color);
$fond-page: var(--fond-page-color);
$fond-page-clair: var(--fond-page-clair-color);
$blanc: var(--default-color);
$gris-search: var(--gris-search);
$alumni_bleu_medium: var(--main-color-univ);
$gris-picto: var(--gris-picto);

// couleurs principales
$gris: #bebebe;
$noir-rp: #000638;
$orange: #f88c3d;
$bleu-texte-courant: #4a5574;
$bleu-clair: #d7d7eb;

// couleurs des universités
$bordeau-sorbonne-nouvelle: #8d0e57;
$rouge-upec: #e42535;
$bleu-sorbonne-pn: #13aae3;
$rouge-assas: #d63440;
$rouge-pn: #e4001f;

// couleurs secondaires
// $bleu-secondaire: #4190ee;

// couleurs des mises en avant sur les offres des les alumnis
$fond-alumni: #84e1cb;
$texte-alumni: #0b4d3d;

// couleurs des mises en avant sur les nouvelles offres
$fond-nouvelle-offre: #cce2fd;
$texte-nouvelle-offre: #2f4a8f;

// couleurs des mises en avant sur les offres des entreprises partenaires
$fond-entreprise-partenaire: #fff1d6;
$texte-entreprise-partenaire: #573a01;

// couleur de mise en avant du parrainnage sur les utilisateurs
$fond-parrain: #e5c9e3;
$texte-parrain: #543552;

// couleurs des fonds
$fond-boutons-accueil: #00326e99;

// couleurs des pages communes
$bordeau-commun: #941c56;
$bleu-commun: #132554;

//couleur bordure
$gris-bordure: #eeeeee;

//couleur icone pop-in
$vert-icon: #5eb6a1;

// couleurs des statuts de groupes
$vert-groupe: #99d08b;
$orange-groupe: #ffcb65;
$rouge-groupe: #ef6b64;
$bleu-groupe: #3b96d3;

// couleur d'erreur
$erreur: #e24c4c;

// couleur admin groupes
$jaune: #fbff25;

// tailles limites
$large: 1480px;
$medium: 1200px;
$normal: 1000px;
$small: 830px;
$tiny: 400px;

// polices
//taille
$font-size-small: 0.8rem;
$font-size-x-small: 0.9rem;
$font-size-normal: 1rem;
$font-size-x-normal: 1.2rem;
$font-size-medium: 1.5rem;
$font-size-large: 2rem;
$font-size-x-large: 2.5rem;

// Affichage des styles overrides par le style, dans l'editor quill
.ql-editor {
  p {
    // les liens
    a {
      color: $bleu-paris1 !important;
      text-decoration: underline !important;
    }
    // texte en italique
    em {
      font-style: italic;
    }
  }
}


@font-face {
  font-family: "font-gros-titres";
  src: url(../assets/fonts/Bitter-Regular.ttf);
}
$font-gros-titres: "font-gros-titres";

@font-face {
  font-family: "font-global";
  src: url(../assets/fonts/Quicksand-Regular.ttf);
}
$font-global: "font-global";

@font-face {
  font-family: "font-sous-menu";
  src: url(../assets/fonts/Bitter-Regular.ttf);
}
$font-sous-menu: "font-sous-menu";

@font-face {
  font-family: "font-date";
  src: url(../assets/fonts/Inter-Thin.ttf);
}
$font-date: "font-date";

@font-face {
  font-family: "font-nav";
  src: url(../assets/fonts/Lato-Bold.ttf);
}
$font-nav: "font-global";

// *********** PORTAIL ALUMNI ***********
// Variables à modifier dès qu'un attribut vient à changer, allègement du code

// MAIN UI COLORS
$alumni_main_prune: #00326e;
$alumni_main_violet: #572c5f;
$alumni_main_orange: #f0283c;

// GREYSCALE PALETTE

$alumni_bleu_noir: #002c64;
$alumni_gris_label: #939fae;
$alumni_gris_border: #cfd6df;
$alumni_gris_background: #f1f3f5;
$alumni_white: #fff;

// TYPOGRAPHY

@font-face {
  font-family: "alumni-h1";
  src: url(../assets/fonts/Bitter-Regular.ttf);
  font-size: 3.5rem;
}
$alumni-h1: "alumni-h1";

@font-face {
  font-family: "alumni-h2";
  src: url(../assets/fonts/Bitter-Regular.ttf);
  font-size: 3rem;
  font-weight: 600;
}
$alumni-h2: "alumni-h2";

@font-face {
  font-family: "alumni-h3";
  src: url(../assets/fonts/Bitter-Regular.ttf);
  font-size: 2.25rem;
  font-weight: 600;
}
$alumni-h3: "alumni-h3";

@font-face {
  font-family: "alumni-h4";
  src: url(../assets/fonts/Bitter-Regular.ttf);
  font-size: 1.5rem;
  font-weight: 600;
}
$alumni-h4: "alumni-h4";

@font-face {
  font-family: "alumni-h5";
  src: url(../assets/fonts/Bitter-Regular.ttf);
  font-size: 1.125rem;
  font-weight: 600;
}
$alumni-h5: "alumni-h5";

@font-face {
  font-family: "alumni-nav-regular";
  src: url(../assets/fonts/Quicksand-Regular.ttf);
  font-size: 1.125rem !important;
  font-weight: 400;
}
$alumni-nav-regular: "alumni-nav-regular";

@font-face {
  font-family: "alumni-body-regular";
  src: url(../assets/fonts/Quicksand-Regular.ttf);
  font-size: 1rem;
  font-weight: 500;
}
$alumni-body-regular: "alumni-body-regular";

@font-face {
  font-family: "alumni-body-bold";
  src: url(../assets/fonts/Quicksand-Regular.ttf);
  font-size: 1rem;
}
$alumni-body-bold: "alumni-body-bold";

@font-face {
  font-family: "alumni-link-regular";
  src: url(../assets/fonts/Brandon-Grotesque-Regular.otf);
  font-size: 1rem;
  font-weight: 390;
}
$alumni-link-regular: "alumni-link-regular";

@font-face {
  font-family: "alumni-link-bold";
  src: url(../assets/fonts/Quicksand-Regular.ttf);
  font-size: 1rem;
  font-weight: 700;
}
$alumni-link-bold: "alumni-link-bold";

@font-face {
  font-family: "alumni-label-regular";
  src: url(../assets/fonts/Quicksand-Regular.ttf);
  font-size: 0.8125rem;
  font-weight: 500;
}
$alumni-label-regular: "alumni-label-regular";

@font-face {
  font-family: "alumni-label-bold";
  src: url(../assets/fonts/Quicksand-Regular.ttf);
  font-size: 0.8125rem;
  font-weight: 700;
}
$alumni-label-bold: "alumni-label-bold";

@font-face {
  font-family: "alumni-placeholder-regular";
  src: url(../assets/fonts/Quicksand-Regular.ttf);
  font-size: 0.875rem;
  font-weight: 400;
}
$alumni-placeholder-regular: "alumni-placeholder-regular";

@font-face {
  font-family: "alumni-placeholder-bold";
  src: url(../assets/fonts/Quicksand-Regular.ttf);
  font-size: 0.875rem;
  font-weight: 400;
}
$alumni-placeholder-bold: "alumni-placeholder-bold";

// ROUNDED CORNERS

$alumni-border-large-element: 10px;
$alumni-border-medium-element: 8px;
$alumni-border-small-element: 4px;

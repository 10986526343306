@use "src/Styles/_vars.scss" as c;

.modal.modal_suppression {
    width: 50% !important;
}

.suppression {
    border: 1vh;
    padding-top: 5vh;
    width: 50%;
    &-content {
        margin: 1.5rem 0 !important;
        color: c.$bleu-paris1;
    }
    &-buttons {
        display: flex;
        justify-content: space-between;
        column-gap: 2rem;
        margin-top: 5vh;
        margin: 0 1vw;
    }
}
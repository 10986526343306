@use "src/Styles/_vars.scss" as c;

.inviter__form {
  padding: 1rem 2rem;
  width: fit-content;
  background-color: #f1f3f6;
  &-content {
    border: 3px solid c.$fond-page !important;
    box-shadow: 0 2px 4px lightgray !important;
    margin: 2rem 0 !important;
  }
  &-error {
    color: red;
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }
  &-buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 5vh;
  }
}

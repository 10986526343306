@use "src/Styles/_vars.scss" as c;
.offre_search_bar {
  padding-bottom: 3rem;
  .p-input-icon-left > .p-inputtext {
    padding-left: 0.5rem;
  }
  &__title {
    padding: 0.5rem;
    color: c.$blanc;
    font-family: c.$font-gros-titres;
    font-size: x-large !important;
  }
  &__container {
    border-radius: 1rem;
    .myOffers_button {
      padding: 0;
      button {
        margin-bottom: 0;
      }
    }

    .p-divider.p-divider-vertical {
      margin: 0 0.5rem;
    }

    &__top {
      display: flex;
      flex-direction: row;
      background-color: c.$blanc;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      &__boxes {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 95%;
        .p-divider-horizontal {
          display: none;
        }
        &__box {
          padding: 0.5rem 0 0.5rem 1.5rem;
          width: 100%;
          label {
            font-family: c.$font-sous-menu;
            color: c.$bleu-paris1;
            text-transform: uppercase;
            display: block;
          }
          .p-input-icon-left {
            width: 100%;
            display: flex;
            justify-content: flex-start;
          }
          .p-divider.p-component.p-divider-vertical.p-divider-solid.p-divider-center {
            padding: 0;
            margin: 0;
          }
        }
        .box__input {
          max-width: 10rem;
          .p-placeholder {
            color: c.$gris-picto;
            padding: 0.5rem 3rem 0.5rem 0 !important;
            display: flex;
            justify-content: flex-start;
          }
        }
        .box__input.blanc {
          // background-color: c.$blanc !important;
          width: 100%;

          .p-placeholder {
            color: c.$gris-picto;
            padding: 0.5rem 3rem 0.5rem 0 !important;
          }
        }
        .p-multiselect.p-component.p-inputwrapper.box__input.blanc {
          .p-multiselect-label.p-placeholder {
            margin-left: 0.5rem;
          }
        }

        .p-inputtext.p-component {
          width: 100%;
        }
      }
      &__inputSearch {
        background-color: c.$bleu-secondaire;
        display: flex;
        font-size: 2rem;
        color: c.$blanc;
        width: 5%;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 0;
        border: none;
        cursor: pointer;
      }
    }
    .box__input {
      border-radius: 2rem;
    }
    &__bottom {
      background-color: c.$fond-page-clair;
      padding: 0.5rem 0.5rem 0.5rem 2.1rem;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &__list {
        position: relative;
      }
      li:hover {
        .listOfLocation {
          display: block !important;
        }
      }
      li {
        // width: fit-content;
        max-width: 100%;
        flex-direction: column;
        position: relative;

        .input_lieu {
          display: flex;
          flex-direction: row;
          .lieu_title {
            align-self: center;
            font-family: c.$font-global !important;
            font-size: c.$font-size-normal;
            color: c.$gris-search;
            margin-right: 0.5rem;
            margin-bottom: 0rem;
          }
          .p-inputtext.p-component.box__input.blanc {
            background-color: transparent;
          }
        }
      }
      &__salaire {
        display: flex;
        flex-direction: row !important;
        width: 30rem;
        span {
          margin-left: 0.5rem;
        }
        .salaire--slider {
          margin-left: 0.5rem;
        }
      }
      .box__input.gris {
        background-color: c.$fond-page-clair !important;
        width: 6rem !important;
        margin-left: 0.5rem;
        .p-inputtext.p-component {
          background-color: c.$fond-page !important;
        }
        .p-placeholder {
          color: c.$gris-picto;
          padding: 0.5rem 3rem 0.5rem 0 !important;
        }
      }
    }
    .p-slider-horizontal,
    .slider-demo .p-inputtext {
      min-width: 3rem;
    }
    .p-slider.p-slider-horizontal {
      margin-left: 1rem;
    }
    .mobile {
      display: none;
    }
    ul {
      display: flex;
      flex-direction: row;
      color: c.$gris-picto;
      width: 100%;

      li {
        font-family: c.$font-global;
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        margin-right: 1.5rem;
        .pSalaire {
          font-size: 1rem;
          color: c.$gris-search;
        }
        label {
          display: none;
        }
        .box_input {
          border: none;
        }

        span {
          margin-right: 0.5rem;
        }
        .slider_salaire {
          display: block !important;
          padding-top: 0.6rem;
        }
        .p-multiselect-label {
          max-width: 10rem;
        }
      }
    }
  }
  .p-accordion-tab {
    padding-left: 0;
  }
}

.search_bar_refresh__offres {
  position: absolute;
  right: 0;
  top: 20%;
}

.no_offers_saved {
  margin: 1rem;
}

@media screen and (max-width: c.$large) and (min-width: c.$medium) {
  .offre_search_bar {
    &__container {
      .box__input {
        border: none;
        max-width: 10rem;
      }
    }
  }
}
@media screen and (max-width: c.$small) {
  .p-inputtext.p-component.box__input {
    width: 100% !important;
  }
  .search_bar_refresh__offres {
    margin-top: 2svh;
    i {
      display: none;
    }
  }
  .p-multiselect.p-component.p-inputwrapper.box__input.blanc {
    width: 100% !important;
    margin-bottom: 1rem;
  }
  .p-accordion-header-text {
    width: 100%;
    .offre_search_bar__container__top__boxes__box.top_mobile {
      width: 100%;
      .p-inputtext.p-component.box__input {
        width: 100% !important;
      }
    }
  }
  .offre_search_bar__container__bottom {
    ul {
      width: 100% !important;
    }
  }
  .offre_search_bar__container__top__inputSearch.desktop {
    width: 100%;
  }

  .p-multiselect.p-component.p-inputwrapper.box__input.blanc {
    width: 100%;
  }
  .p-inputtext.p-component.box__input {
    width: 100% !important;
  }
  .p-inputtext.p-component.box__input {
    width: 100% !important;
  }
}
@media screen and (min-width: c.$medium) {
  .p-accordion.p-component {
    display: none;
  }
  .p-multiselect.p-component.p-inputwrapper.box__input {
    box-shadow: none;
  }
  .p-inputtext.p-component.box__input {
    box-shadow: none;
  }
  .offre_search_bar__container {
    width: 95%;
    margin: auto;
    &__top {
      &__inputSearch.desktop {
        display: none;
      }
      &__inputSearch.mobile {
        display: flex;
      }
    }
  }
}

@media screen and (max-width: c.$medium) {
  .question {
    width: 90% !important;
  }
  .offre_search_bar {
    &__filtres {
      display: none;
    }
    &__container {
      background-color: transparent;

      &__top {
        flex-direction: column;
        background-color: transparent;
        &__boxes {
          flex-direction: column;
          padding: 0;
          width: 100%;
          margin-top: 1rem;
          &__box {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            text-align: center;
            padding: 0;

            label {
              display: flex;
              justify-content: flex-start;
            }
            .p-inputtext.p-component.box__input {
              height: 2.5rem;
            }
            .p-input-icon-left.p-inputtext {
              margin-left: -2rem;
            }
          }

          .p-divider-vertical {
            display: none;
          }
        }
        &__inputSearch {
          width: 45%;
          align-items: center;
          justify-content: center;
          border-radius: 2rem;
          padding: 0.5rem;
        }
        &__inputSearch.mobile {
          display: none;
        }
      }
      &__bottom {
        background-color: transparent;
        padding: 0;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .box__input.gris {
          background-color: c.$fond-page-clair !important;
          width: 6rem !important;
          margin-left: 0.5rem;
          .p-inputtext.p-component {
            background-color: c.$fond-page !important;
          }
          .p-placeholder {
            color: c.$gris-picto;
            padding: 0.5rem 3rem 0.5rem 0 !important;
          }
        }
        ul {
          display: flex;
          flex-direction: column;
          color: c.$gris-picto;
          justify-content: space-evenly;
          width: 100%;

          li {
            font-family: c.$font-global;
            font-size: 0.9rem;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            margin-right: 0;
            width: 100%;
            p {
              display: none;
            }
            label {
              display: flex;
              justify-content: flex-start;
              color: c.$bleu-paris1;
              font-family: c.$font-sous-menu;
              margin-bottom: 0.5rem;
              text-transform: uppercase;
            }
            .box__input {
              width: 100%;
              margin-bottom: 1rem;
              border: none;
              background-color: c.$blanc !important;
              height: 2.5rem;
              display: flex;
              align-items: center;
            }
            .box__input.gris {
              width: 100% !important;
              border: none;
              background-color: c.$blanc !important;
              margin-left: 0;
            }

            span {
              margin-right: 0.5rem;
            }
          }
        }
      }
      .box__input {
        max-width: unset;
        box-shadow: 2px 3px 6px lightgray;
        background-color: c.$blanc !important;
      }
      &__top__boxes__box.top {
        display: none;
      }
    }
  }
}
@media screen and (max-width: c.$medium) and (min-width: c.$small) {
  .p-accordion-header-text {
    width: 42%;
    .offre_search_bar__container__top__boxes__box.top_mobile {
      width: 100%;
      .p-inputtext.p-component.box__input {
        width: 100% !important;
      }
    }
  }
  .p-inputtext.p-component.box__input {
    height: 2.5rem;
  }

  .p-inputtextarea.p-inputtext.p-component.box__input {
    height: unset;
  }
}

@use "src/Styles/_vars.scss" as c;

.offre_search_card_container {
  margin: 1rem 0;
  background-color: c.$blanc;
  min-width: 35rem;
  margin-left: 3rem;
  margin-right: 3rem;
  .offre_search_card {
    display: flex;
    justify-content: space-between;
    &__title {
      width: 80%;
      height: 5rem;
      align-items: center;
      display: flex;
      margin-left: 1rem;
      label {
        cursor: pointer;
        color: c.$bleu-paris1;
        font-size: large;
        font-weight: bold;
      }
    }
    &__buttons {
      display: flex;
      flex-direction: row;
      &__left {
        display: flex;
        align-items: center;
        &__trash,
        &__pen {
          color: c.$bleu-secondaire;
          margin: 1rem;
        }
      }
      &__right {
        align-self: center;
        margin: 0 2rem 0 2rem;
        svg {
          height: 25px;
          width: 25px;
        }
      }
    }
  }
  .offre_search_card__details {
    display: flex;
    flex-direction: column;
    label {
      margin: 0 0 0.5rem 1rem;
    }
    &__champs {
      .name {
        color: c.$noir-rp;
      }
      .content {
      }
    }
  }
}

@use "src/Styles/_vars.scss" as c;

.accueil {
  &:nth-child(odd) {
    background-color: c.$fond-page;
  }
  h1 {
    font-family: c.$font-gros-titres;
    color: c.$bleu-paris1;
    // font-size: xx-large !important;
    // padding-top: 4rem;
    margin-bottom: 0.5rem;
  }
  p {
    font-family: c.$font-global;
    text-justify: auto;
  }
  .article_ALaUne {
    padding: 0rem 13.5rem;
  }
  @media screen and (max-width: 830px) {
    .article_ALaUne {
      padding: 0;
    }
  }
  .article_MecenatGroupeAccueil {
    min-height: 30rem;
    display: flex;
    justify-content: center;
  }
  .article_ReseauDynamique {
    min-height: 30rem;
  }
  .article_FaireDon {
    min-height: 30rem;
  }
  .article_evenements {
    background-color: c.$fond-page;
    padding: 0 7rem;
    .evenement_container {
      width: 24rem;
    }
    .noCarouselEvent {
      display: none;
    }
  }
  .article_actualites {
    background-color: c.$bleu-paris1 !important;
    padding: 0 7rem;
    .noCarouselActus {
      display: none;
    }
    .actualites_container {
      width: fit-content;
      &__top {
        &__title {
          h1.h1_trait_dessus {
            color: c.$blanc !important;
            &::before {
              background-color: c.$blanc !important;
            }
          }
        }
      }
    }
  }
  .article_conseils {
    background-color: c.$bleu-paris1;
    .ressources {
      h1 {
        color: white;
      }
    }
  }
  .article_partenaires {
    padding-top: 3rem;
  }
}
@media screen and (min-width: c.$small) {
  #header .menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    background-image: url(../../assets/fondAlumniConnected.webp);
    &__connected {
      width: 100%;
    }
    &__notConnected {
      min-width: 150%;
    }
  }
}
@media screen and (max-width: c.$small) {
  .accueil {
    .article_partenaires {
      padding-top: 0;
    }
    .article_offre__offres {
      grid-template-columns: 1fr !important;
    }
    .article_evenements {
      padding: 0 2rem;
      .evenement_container {
        width: 20rem;
        &__corps {
          &__chapeau {
            padding: 0 1rem 1rem 1rem;
          }
        }
        &__bottom {
          &__logo {
            padding: 1rem 0rem 0 1rem;
          }
        }
      }
      .noCarouselEvent {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .carouselDesktopEvent {
        display: none;
      }
    }
    .article_actualites {
      padding: 0 2rem;
      .actualite_container {
        width: 20rem;
      }
      .noCarouselActus {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .carouselDesktopActus {
        display: none;
      }
    }
  }
}

@media screen and (max-width: c.$medium) {
  .accueil {
    .article_partenaires {
      padding-top: 0;
    }
    .article_offre__offres {
      grid-template-columns: 1fr 1fr;
    }
  }
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$small) {
  #header .menu {
    min-height: fit-content;
  }
  .accueil {
    h1 {
      margin-bottom: 0;
      font-size: 2.5rem !important;
    }
    .article_offre_container {
      padding-bottom: 0;
    }
    .btn-bleu {
      height: 3rem;
    }
    .btn-transparent {
      height: fit-content;
    }
    .offre {
      height: fit-content;
    }
    .article_partenaires {
      padding-top: 0;
    }
  }
}

@use "src/Styles/_vars.scss" as c;

.gestion_editoriale {
  padding: 5rem 7rem 2rem;
  background-color: c.$fond-page;
  &__container {
    &__title {
      display: flex;
      justify-content: space-between;
      h1 {
        font-family: c.$font-gros-titres;
        color: c.$bleu-paris1;
        font-size: xx-large !important;
      }
      &__filtre {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
        h6 {
          font-size: medium;
          font-family: c.$font-global;
          color: c.$bleu-paris1;
        }
        img {
          height: 3rem;
          margin-left: 0.5rem;
        }
      }
    }
    .menu_vertical {
      font-family: c.$font-global;
      color: c.$bleu-paris1;
      h3 {
        cursor: pointer;
        font-size: medium;
        margin-left: 1rem;
      }
      h3.Ouvert {
        width: fit-content;
        position: relative;
        &::after {
          content: "";
          display: block;
          background-color: c.$bleu-paris1;
          height: 0.1rem;
          position: absolute;
          width: 100%;
        }
        font-weight: 600;
      }
      h2 {
        font-size: large;
        margin-top: 1rem;
        font-weight: 600;
      }
      h2.top {
        margin-top: 0;
      }
    }
    &__content {
      padding-top: 1rem;
      display: flex;
      flex-direction: row;
      &__right {
        width: 60%;
        margin-left: 5rem;
        color: c.$bleu-paris1;
        h2 {
          font-family: c.$font-global;
          font-size: large;
          font-weight: 600;
          margin-bottom: 2rem;
        }
        .p-inputtextarea.p-component {
          margin-bottom: 1rem;
        }
        .btn-bleu {
          margin: 0;
        }
      }
    }
  }
}

@media screen and (max-width: c.$medium) {
  .gestion_editoriale {
    padding: 1rem 1rem 2rem;
    &__container {
      &__content {
        flex-direction: column;
        &__right {
          margin-left: 0;
        }
      }
    }
  }
}

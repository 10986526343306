@use "src/Styles/_vars.scss" as c;

.filters__bar__container {
  display: flex;
  justify-content: flex-end;
  &__searchInput {
    position: relative;
    .searchBar__icon {
      position: absolute;
      color: #8a8a8a;
      font-size: 1.5rem;
      top: 0.55rem;
      margin-left: 0.7rem;
    }
    .p-inputtext.p-component {
      margin: 0 !important;
      width: 100% !important;
      border: none;
      padding-left: 2.5rem;
      border-radius: 2rem;
    }
  }
  &__dropdown {
    .p-dropdown {
      width: 13.5rem !important;
      margin: 0 0 0 1rem !important;
    }
  }
  &__date {
    display: flex;
    align-items: center;
    align-content: center;
    background: #ffffff;
    border-radius: 1rem;
    height: 2.5rem;
    margin-left: 1rem;
    svg {
      padding-right: 0.5rem;
      box-sizing: unset;
      cursor: pointer;
      color: #6c757d;
      width: 1.3rem;
      height: 1.3rem;
    }
    .react-datepicker-wrapper {
      background: transparent;
      border: 0 none;
      margin: 0;
      input {
        width: 100%;
        text-align: center;
        color: #6c757d;
        font-family: "font-global" !important;
        font-size: medium !important;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s,
          box-shadow 0.2s;
        border-radius: 1rem;
        box-shadow: none;
        height: 2.5rem;
        border: none;
      }
      input::placeholder {
        color: #6c757d;
        opacity: 1;
      }
    }
    &.warning {
      input {
        color: red;
      }
    }
  }
  &__refresh {
    color: c.$bleu-paris1;
    display: flex;
    align-items: center;
    margin: 0 1rem;
    cursor: pointer;
  }
}

@media screen and (max-width: c.$medium) {
  .filters__bar__container {
    flex-direction: column;
    padding-top: 0 !important;

    &__searchInput,
    &__date,
    &__dropdown {
      width: 90% !important;
    }

    &__searchInput {
      margin: 0.5rem 0;
      max-width: 20rem;
      .searchBar__icon {
        position: absolute;
        color: c.$gris-picto;
        font-size: 1.5rem;
        margin-left: 0.7rem;
      }
    }
    &__date {
      margin: 0.5rem 0;
    }
    &__dropdown {
      .p-dropdown {
        width: 100% !important;
        margin-left: 0 !important;
        margin: 0.5rem 0;
      }
    }
  }
}

@media screen and (max-width: c.$small) {
  .filters__bar__container {
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 2rem;
  }
}

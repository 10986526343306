@use "src/Styles/_vars.scss" as c;

.recruteur_card.annuaire_etudiant__container__description__title {
  &__item {
    display: block !important;
    margin-bottom: 2rem !important;
    label {
      width: 100%;
      font-size: medium;
    }
    p {
      width: 100%;
      font-size: small;
    }
  }
}

.offre.recruteur_card {
  .annuaire_etudiant__image {
    // Affiche deux élements, l'image et le titre (nom + prénom) sur une même ligne
    // Ratio 1:2
    display: flex;
    height: 10svh;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 0 0 0.5rem !important;
    justify-content: space-between;
    img {
      height: 4rem;
      width: 4rem;
      border-radius: 3rem;
      border: 1px solid whitesmoke;
    }
    span {
      // Titre (nom + prénom)
      width: 65%;
      font-family: c.$font-sous-menu;
      font-size: small !important;
      padding: 0.75rem 0 1rem 0.5rem;
      font-size: medium;
    }
  }
  min-height: 25svh !important;
}

// affichage sur ordinateur
@media screen and (min-width: 1024px) {
  .offre.recruteur_card {
    .annuaire_etudiant__image {
      img {
        height: 4rem;
        width: 4rem;
      }
      span {
        font-size: large;
      }
    }
  }
  .recruteur_card.annuaire_etudiant__container__description__title {
    &__item {
      display: flex !important;
      margin-bottom: 0.5rem !important;
      label {
        width: 40%;
        font-size: medium;
      }
      p {
        width: 60%;
        font-size: small;
      }
    }
  }
}

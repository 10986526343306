@use "src/Styles/_vars.scss" as c;

.userConnected {
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__unifLogo {
    width: 6rem;
  }
  &__icons {
    display: flex;
    margin-right: 1rem !important;
    color: c.$bleu-paris1;
    &__icon {
      font-size: 1.5rem !important;
      margin: 0 1.5rem;
      padding-top: 0.2rem;
    }
  }
  &__picture {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    object-fit: cover;
  }
  &__right {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;

    &__image_button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      cursor: pointer;

      .userConnectedButton {
        border: 2px solid #cfd6df;
        &.alumni {
          border: 2px solid c.$bleu-paris1;
        }
        &.none {
          border: none;
        }
        color: c.$bleu-paris1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 8.5rem;
        height: 3rem;
        border-radius: 2rem;
        background-color: c.$blanc;
        font-weight: bold;
        padding: 0.5rem;
      }
      .userConnectedButton:hover {
        transition: all 0.5s;
        background-color: c.$bleu-paris1;
        color: c.$blanc;
      }
    }
  }
  button {
    position: relative;
    color: c.$bleu-commun;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    background-color: transparent;
    font-weight: bold;
    padding: 0.5rem 0.7rem;
    border-radius: 5rem;
    transition: all 0.5s;

    &:hover {
      cursor: pointer;
      color: c.$blanc;
      background-color: c.$bleu-commun;
    }
  }
}

.image_button {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

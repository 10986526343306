@use "src/Styles/_vars.scss" as c;

.input_localisation_annuaire:hover {
  .listOfLocation {
    display: block !important;
    margin: 0;
    .location {
      color: c.$gris-picto;
    }
  }
}

.p-multiselect.p-component {
  background-color: c.$blanc !important;
  border: none;
}
.annuaire_search_bar.etudiant_bar {
  padding-bottom: 3rem;
}
.annuaire_search_bar {
  .p-divider.p-divider-vertical {
    margin: 0 0.5rem;
  }
  .p-placeholder {
    color: c.$gris-picto;
    padding: 0.5rem 0 0.5rem 0.4rem !important;
  }
  &__title {
    display: none;
  }
  &__container {
    border-radius: 1rem;
    background-color: c.$blanc;
    width: 95%;
    margin-bottom: 3rem;
    margin: 0 2.5%;

    &__top {
      display: flex;
      flex-direction: row;
      &__boxes {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;
        padding-bottom: 0.5rem;
        .p-divider-horizontal {
          display: none;
        }
        &__box {
          label {
            font-family: c.$font-sous-menu;
            color: c.$bleu-paris1;
            text-transform: uppercase;
            display: block;
            margin-bottom: 0.5rem;
            margin-top: 0.5rem;
          }
        }
        .p-inputtext {
          width: 110%;
        }
        .p-multiselect.p-component {
          &__p-multiselect-label {
            padding: 0.75rem 0rem;
          }
        }
        .p-component {
          border: none;
        }
        .box__input.blanc {
          background-color: c.$blanc !important;
          .p-placeholder {
            color: c.$gris-picto;
            padding: 0.5rem 0 0.5rem 0 !important;
          }
        }
      }
      &__inputSearch {
        background-color: c.$bleu-secondaire;
        display: flex;
        font-size: 2rem;
        color: c.$blanc;
        width: 5%;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 1rem;
        cursor: pointer;
      }
      &__inputSearch.not_desktop {
        display: none;
      }
      &__inputSearch.not_mobile {
        display: flex;
      }
    }
    &__bottom {
      background-color: c.$fond-page-clair;
      padding: 0.5rem 0;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      .ul_entreprises {
        margin-left: 2.2rem;
      }
      ul {
        display: flex;
        flex-direction: row;
        color: c.$gris-picto;
        margin-left: 1.4rem;

        li {
          font-family: c.$font-global;
          font-size: 0.9rem;
          display: flex;
          align-items: center;
          margin-right: 0.5rem;

          label {
            display: none;
          }
          .p-inputtext.p-component {
            background-color: c.$fond-page-clair;
          }
          .label-min-prenom {
            display: unset;
            color: c.$bleu-paris1;
            font-size: c.$font-sous-menu;
            font-weight: 700;
            min-width: 5.5rem;
          }
          .label-min-nom {
            display: unset;
            color: c.$bleu-paris1;
            font-size: c.$font-sous-menu;
            font-weight: 700;
            min-width: 4rem;
          }
        }
        .li_checkbox {
          display: flex;
          flex-direction: row;
          .p-checkbox.p-component {
            margin-top: 0 !important;
          }
          margin-right: 1rem;
          label {
            display: flex;
            align-items: center;
            .buildingLogoAnnuaire {
              color: c.$texte-entreprise-partenaire;
            }
          }
          .p-checkbox-label {
            svg {
              margin-right: 0.5rem;
            }
            .parrain {
              color: c.$texte-parrain;
            }
            .entreprise {
              color: c.$texte-entreprise-partenaire;
              background-color: unset;
            }
            img {
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: c.$medium) {
  .annuaire_search_bar {
    .p-multiselect.p-component.p-inputwrapper.box__input {
      box-shadow: none;
    }
  }
}
@media screen and (max-width: c.$medium) {
  .annuaire_search_bar {
    &__container {
      background-color: transparent;
      box-shadow: none !important;
      &__top {
        flex-direction: column;
        width: 100%;
        &__boxes {
          flex-direction: column;
          padding: 0;
          width: 100%;
          &__box {
            margin-left: 0;

            border-bottom: 0.1rem solid c.$gris-bordure;
            label {
              margin-left: 0.5rem;
              font-family: c.$font-sous-menu;
            }
          }
          .p-divider-vertical {
            display: none;
          }
        }
        &__inputSearch {
          width: 45%;
          align-items: center;
          justify-content: center;
          border-radius: 2rem;
          padding: 0.25rem;
        }
        &__inputSearch.not_desktop {
          display: flex;
        }
        &__inputSearch.not_mobile {
          display: none;
        }
      }
      &__bottom {
        background-color: transparent;
        padding: 0 0 1rem 0;
        ul {
          justify-content: flex-start;
          flex-direction: column;
          width: 100%;
          li {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            align-items: flex-start;
            .p-inputtext.p-component {
              background-color: c.$blanc;
            }
            label {
              display: unset;
              color: c.$bleu-paris1;
              font-family: c.$font-sous-menu;
              text-transform: uppercase;
              justify-content: flex-start;
            }
            .label-annuaire {
              margin: 0.5rem;
              font-size: medium;
            }
            .p-multiselect.p-component {
              width: 100%;
              background-color: c.$blanc !important;
            }
          }
          .li_checkbox {
            margin: 1rem 0 0 0;
          }
        }
      }
    }
  }
}
@media screen and (max-width: c.$tiny) {
  .annuaire_search_bar {
    width: 90% !important;
  }
}

@media screen and (min-width: c.$small) and (max-width: c.$medium) {
  .annuaire_search_bar {
    &__container {
      margin-bottom: 2rem;
      &__top {
        flex-direction: column;
        &__boxes {
          flex-direction: column;
          padding: 0 0.5rem;
          &__box {
            border-bottom: none;
          }
        }

        .p-divider-vertical {
          display: none;
        }
      }
      &__inputSearch {
        width: 100%;
        align-items: center;
        justify-content: center;
        border-bottom-left-radius: 1rem;
        border-top-right-radius: 0;
        padding: 0.5rem;
      }
    }
    &__bottom {
      display: none;
    }
  }
}

@media screen and (max-width: c.$small) {
  .annuaire_search_bar {
    &__container {
      .p-multiselect.p-component.p-inputwrapper.box__input {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: c.$medium) {
  .annuaire_search_bar {
    display: none;
    &__container {
      &__bottom {
        ul {
          margin-left: 0 !important;
          li {
            .label-min-nom,
            .label-min-prenom {
              display: none;
            }
          }
          .search_bar_refresh__annuaireEntreprise {
            align-self: center;
            position: unset;
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
  .p-accordion.p-component {
    .annuaire_search_bar {
      display: unset;
    }
  }
}

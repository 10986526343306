@use "src/Styles/_vars.scss" as c;

.evenement_formulaire {
  padding: 0 7rem;
  background-color: c.$fond-page;
  &__title {
    display: flex;
    justify-content: space-between;
    padding-top: 4rem;
    align-items: center;
    h1 {
      padding-bottom: 2rem;
      color: c.$bleu-paris1;
      font-family: c.$font-gros-titres;
      font-size: xx-large !important;
    }
    button {
      float: right;
      border: 1px solid c.$bleu-paris1;
      background-color: c.$bleu-paris1;
      box-sizing: border-box;
      border-radius: 30px;
      width: fit-content;
      height: 2.5rem;
      color: white;
      transition: all 0.5s;
      padding: 0rem 1.5rem;
      font-weight: 700;
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      svg {
        margin-left: 0;
        margin-right: 0.5rem;
        // margin-top: 0.5rem;
      }
    }
    button:hover {
      cursor: pointer;
      background-color: transparent;
      color: c.$bleu-paris1;
    }
    &__filtres {
      text-align: right;
    }
  }
  &__container {
    padding: 0 8%;
    h2 {
      color: c.$bleu-paris1;
      font-family: c.$font-sous-menu;
      font-size: medium;
      margin-bottom: 0.7rem;
    }
    small {
      display: block;
    }
    h2.p-error {
      color: c.$erreur;
    }
    .label_checkbox {
      font-size: unset;
    }
    h3 {
      color: c.$bleu-paris1;
      font-family: c.$font-sous-menu;
      font-size: small;
      margin-bottom: 0rem;
      margin-left: 1.3rem;
    }
    .column_line {
      display: flex;
      justify-content: space-between;
      padding-bottom: 2rem;
      &__item {
        width: 45%;

        .p-dropdown.p-component.p-inputwrapper.input_evenement {
          width: 46% !important;
        }

        &-date {
          small {
            display: block;
          }
          label {
            width: 90%;
          }
          span {
            width: 90%;
            input {
              text-align: center;
            }
          }
        }
        .p-inputtext.p-component.small_item {
          width: 60% !important;
          text-align: center;
        }
        .eventNumberInput {
          min-width: 30% !important;
          text-align: center;
        }
      }
      .column_line {
        padding-bottom: 0;
      }
    }
    .fourth_line {
      padding-bottom: 1rem;
    }
    .url_container {
      padding-bottom: 2rem;
      .url_event {
        padding-bottom: 1rem;
      }
      a {
        color: c.$bleu-paris1 !important;
        display: flex;
        align-items: center;
        cursor: pointer;
        i {
          margin-right: 0.5rem;
          font-size: 1.5rem;
        }
      }
    }
    .fith_line_evenement {
      padding-bottom: 2rem;
      .dates_evenement__container {
        display: flex;
        width: 45%;
        justify-content: space-between;
        &__item {
          width: 45%;
        }
      }
      .url {
        padding-top: 2rem;
        width: 45%;
      }
    }
    .image_evenement {
      padding-bottom: 2rem;
      &__checkbox {
        display: flex;
        color: c.$bleu-paris1;
        padding-bottom: 2rem;
        .label_publique {
          margin-top: 1rem;
          margin-right: 2rem;
        }
      }
    }
    .checkbox_line {
      display: flex;
      flex-direction: row;
      padding-bottom: 2rem;
      &__item {
        width: fit-content;
        margin-right: 2rem;
        display: flex;
        align-items: center;
        flex-direction: row;
        label {
          svg {
            margin-top: -0.25rem;
            margin-right: 0.25rem;
          }
        }
      }
    }
    .buttons_bottom {
      display: flex;
      .btn-bleu,
      .btn-blanc {
        max-width: 33%;
        margin: 0 1rem 4rem 0;
      }
      .waiting_button {
        width: 10rem;
        .loaderContainer {
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          .loaderContainer__loader {
            width: 2rem;
            height: 2rem;
          }
          .loaderContainer__loader::after {
            width: 2rem;
            height: 2rem;
          }
        }
      }
    }
  }

  &__add_question_create_event {
    margin-bottom: 2rem;
  }
}
.spaceCheckbox {
  margin-left: 1rem;
}
#pr_id_1_content {
  margin: 0;
}

@media screen and (max-width: c.$medium) {
  .evenement_formulaire {
    padding: 0 2rem;
    &__title {
      &__filtres {
        padding: 1rem 0;
        margin-top: 0;
        button {
          font-size: small;
        }
      }
    }
    &__container {
      padding: 0 4%;
    }
    .column_line {
      display: block;
      &__item {
        margin-top: 1rem;
        width: 100%;
        margin-bottom: 0.5rem;
        .p-dropdown.p-component.p-inputwrapper.input_evenement {
          width: 90% !important;
        }
        .p-inputtext.p-component.small_item,
        .p-inputtext.p-component.input_evenement,
        .p-inputtext.p-component.input_actu {
          width: 90% !important;
        }
        .column_line {
          flex-direction: column;
          &__item {
            width: 100%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: c.$small) {
  .evenement_formulaire {
    padding: 0 1rem;
    &__title {
      padding-top: 1rem;
    }
    &__container {
      padding: 0;
      .column_line {
        justify-content: flex-start;
        flex-direction: column;
        padding-bottom: 0;
        &__item {
          .p-dropdown.p-component.p-inputwrapper.input_evenement {
            width: 100% !important;
          }
          .p-inputtext.p-component.small_item,
          .p-inputtext.p-component.input_evenement,
          .p-inputtext.p-component.input_actu {
            width: 100% !important;
          }
          small {
            display: block;
          }
          width: 100%;
          padding-bottom: 1rem;
          &-date {
            label {
              width: 60%;
            }
            span {
              width: 100%;
              input {
                text-align: center;
              }
            }
          }
        }
      }
      .buttons_bottom {
        flex-direction: column;
        padding-top: 1rem;
        .btn-bleu,
        .btn-blanc {
          max-width: 100%;
          margin: 0 1rem 1rem 0;
        }
      }
      .fith_line_evenement {
        display: flex;
        flex-direction: row;
      }
      .checkbox_line {
        flex-direction: column;
        padding-bottom: 1rem;
        &__item {
          width: 100%;
        }
      }
    }
  }
}

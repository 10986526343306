@use "src/Styles/_vars.scss" as c;
.p-menuitem-icon.pi.pi-star {
  visibility: hidden;
}
.p-menuitem-icon.pi.pi-shield {
  color: c.$bleu-paris1;
}
.groupe_details_container {
  .groupe_details_img {
    img {
      background-size: cover;
      width: 100%;
      height: 14rem;
      border-radius: 1rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .bouton_groupe {
      position: relative;
      display: flex;
      justify-content: flex-end;
      .btn-blanc {
        position: absolute;
        color: white;
        border: none;
        background-color: white;
        color: c.$bleu-paris1;
        margin: 1rem 2.5rem 0 0;
        height: 2rem;
        svg {
          margin: 0;
        }
      }
    }
  }
  .groupe_details_header {
    padding: 0rem 2.5rem 1rem;
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .leave {
        padding: 0.4rem 1rem;
        border-radius: 2rem;
        width: fit-content;
        border: 1px solid c.$bleu-paris1;
        display: flex;
        align-items: center;
        align-self: flex-start;
        margin-top: 1.5rem;
        color: c.$bleu-paris1;
        background-color: c.$fond-page-clair;
        &:hover {
          background-color: c.$bleu-paris1;
          color: white;
          transition: 0.5s;
          border: 1px solid c.$bleu-paris1;
        }
      }
    }
    h2 {
      font-family: c.$font-sous-menu;
      font-size: 1.5rem;
      padding: 1.5rem 0 0.5rem;
      color: c.$bleu-paris1;
    }
    .infos {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      p {
        margin-right: 2rem;
        color: c.$gris-search;
        .icon_infos {
          margin-right: 0.5rem;
        }
        margin-bottom: 0;
      }
      p.click {
        cursor: pointer;
      }

      .btn-blanc {
        width: fit-content;
      }
    }
  }
  .groupe_details_description {
    padding: 0rem 2.5rem 1rem;
    h5 {
      font-size: c.$font-size-normal;
    }
    a {
      color: c.$bleu-secondaire !important;
    }
    h6 {
      color: c.$bleu-secondaire;
    }
  }
}
@media screen and (max-width: c.$medium) {
  .groupe_details_container {
    .groupe_details_header {
      .infos {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
      }
    }
  }
}

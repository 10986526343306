@use "src/Styles/_vars.scss" as c;

.modal.modal_ajout-droits {
  width: 40% !important;
}

.ajout_droits__form {
  border: 1vh;
  padding-top: 5vh;
  width: 50%;
  &-content {
    border: 3px solid c.$fond-page !important;
    box-shadow: 0px 2px 4px lightgray !important;
    margin: 2rem 0 !important;
  }
  &-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 5vh;
    margin: 0 1vw;
  }
}

@use "src/Styles/_vars.scss" as c;

.gestion_membres {
  &__container {
    padding: 4rem 7rem 2rem;
    background-color: c.$fond-page;
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h1 {
        font-family: c.$font-gros-titres;
        color: c.$bleu-paris1;
        font-size: xx-large !important;
      }
    }
    &__article {
      padding-top: 2rem;
      &__form {
        display: flex;
        flex-direction: row;
        &__champ {
          display: flex;
          flex-direction: column;
          margin-right: 2rem;
        }
      }
      label {
        font-weight: 600;
        color: c.$bleu-paris1;
      }
      h3 {
        font-weight: 600;
        font-family: c.$font-global;
        font-size: large;
        color: c.$bleu-paris1;
        margin-bottom: 1rem;
      }
      .p-inputtext.p-component {
        width: 15rem !important;
        margin-bottom: 1rem;
      }
      .btn-bleu {
        margin: 1rem 0 2rem 0;
      }
    }
  }
}

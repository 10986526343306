@use "src/Styles/_vars.scss" as c;

.carouselALaUne {
  .carousel-control-prev {
    left: -10rem;
  }
  .carousel-control-next {
    right: -10rem;
  }
}

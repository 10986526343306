@use "src/Styles/_vars.scss" as c;

.stats_container {
  background-color: c.$fond-page;
  &__top {
    h1 {
      font-family: c.$font-gros-titres;
      color: c.$bleu-secondaire;
      font-size: xx-large !important;
      .trait_dessus {
        border-top: 2px solid c.$bleu-paris1;
        width: 100px;
        margin-bottom: 1rem;
      }
    }
  }
  &__iframe {
    iframe {
      display: block;
      background: #000;
      border: none;
      height: 100vh;
    }
  }
  &__scrollmenu {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    .p-scrollpanel.p-component {
      width: 100%;
    }
    .p-scrollpanel-bar {
      background-color: #1976d2;
      opacity: 1;
      transition: background-color 0.3s;
    }
    .p-scrollpanel.p-scrollpanel-wrapper {
      border-right: 10px solid var(--surface-b);
      border-bottom: 10px solid var(--surface-b);
    }

    .p-scrollpanel-bar.p-scrollpanel-bar-x {
      bottom: -90px !important;
    }
  }
  &__component {
    &-dual {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: space;
    }
    &-center {
      position: relative;
      width: 40%;
      left: 50%;
      transform: translateX(-50%);
    }

    &-boxshadow {
      box-shadow: 0px 2px 0.2px lightgray;
    }

    &-export {
      Button.xl {
        background: #22c55e !important;
        display: block;
        margin-right: 0px;
        margin-left: auto;
      }

      Button.xl:hover {
        background: #16a34a !important;
      }
    }
  }
  &__table {
    box-shadow: 0px 2px 0.2px lightgray;

    &__forms {
      min-width: 15rem;
      padding: 0.25rem 0.5rem;
      border-radius: 3px;
      margin-right: 0.5rem;

      .p-multiselect {
        width: 25%;
      }

      &-dual {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &-title {
      text-align: center;
      background-color: c.$fond-page;
      margin-bottom: 0;
    }
    &-tooltip {
      Button.xl {
        background: #22c55e !important;
        margin-right: 1%;
      }
      Button.xl:hover {
        background: #16a34a !important;
      }
      Button.pdf {
        background: #f59e0b !important;
        margin-right: 1%;
      }
      Button.pdf:hover {
        background: #d97706 !important;
      }
      Button.csv {
        background: #6366f1 !important;
        margin-right: 1%;

        span {
          color: whitesmoke;
        }
      }
      Button.csv:hover {
        background: #3538ec !important;
      }
    }
  }
}

.p-connected-overlay-enter-done {
  display: flex;
  flex-direction: column;
}

// titres dans la page
.stats_container h1 {
  font-family: c.$font-gros-titres;
  color: c.$bleu-paris1;
  font-size: xx-large !important;
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$small) {
  .stats_container {
    .p-menuitem-text {
      display: none;
    }
  }

  .stats_mb {
    padding-bottom: 20%;
  }

  .stats_mb-xs {
    padding-bottom: 10%;
  }

  .stats_container {
    padding-left: 1rem;
    padding-right: 1rem;
    h1 {
      padding-top: 5rem;
      padding-bottom: 2rem;
    }
    p {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    &__table {
      &__forms {
        .p-multiselect {
          width: 100%;
        }
      }
    }
    &__component {
      &-float {
        float: left;
        position: relative;
        left: 0%;
      }
      &-dual {
        display: inline;
        justify-content: center;
        align-items: center;
        margin-bottom: 10%;
      }

      &-center {
        position: relative;
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: c.$small) {
  .stats_container {
    padding-left: 7rem;
    padding-right: 7rem;
    h1 {
      padding-top: 5rem;
      padding-bottom: 2rem;
    }
    p {
      padding-left: 7rem;
      padding-right: 7rem;
    }
  }
}

@media screen and (max-width: 1600px) {
}

.trait_dessus {
  border-top: 2px solid c.$bleu-paris1;
  width: 100px;
  margin-bottom: 1rem;
}

.stats_mb-xxs {
  padding-bottom: 1%;
}

.stats_mt {
  margin-top: 10%;
}

.stats_mb-xs {
  padding-bottom: 3%;
}

.stats_mb-s {
  padding-bottom: 5%;
}

.stats_mb {
  margin-bottom: 10%;
}

@use "src/Styles/_vars.scss" as c;

.article_presentation {
  background-color: c.$fond-page;
  padding: 4rem 10rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .logo_container {
    margin: 4% 20%;
    width: 60%;
  }
  p {
    font-family: c.$font-global;
    font-size: 1.125rem;
    text-justify: auto;
    display: block;
    margin: 0.5rem 2.85rem;
    line-height: 1.5rem;
    color: c.$bleu-paris1;
  }
  .presentation_title {
    width: 100%;
    .p-accordion.p-component {
      display: unset !important;
    }
    .h1_accueilPresentation {
      font-family: c.$font-gros-titres;
      color: c.$bleu-paris1;
      font-size: 3rem !important;
      margin-bottom: 1rem;
      font-weight: bold;
    }
  }
  .logos_univ {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .logo_univ_1 {
      width: 10rem;
      margin-top: 1rem;
      margin-right: 2rem;
      padding: 1rem 1rem;
      box-shadow: 1px 1px 8px 1px c.$gris-bordure;
    }
  }
  .p-accordion.p-component {
    width: 100%;
    .p-accordion-tab {
      width: 100%;
      border: none;
    }
    .p-accordion.p-component {
      border: none;
    }
    .p-accordion-toggle-icon.pi.pi-chevron-down,
    .p-accordion-toggle-icon.pi.pi-chevron-right {
      font-size: 1.5rem;
    }
    .p-accordion .p-accordion-header .p-accordion-header-link {
      padding: 0;
    }
    .p-accordion-content {
      font-family: c.$font-sous-menu;
    }
    .p-accordion-header-text {
      color: c.$bleu-paris1;
      font-weight: 500;
      font-size: xx-large !important;
      font-family: c.$font-gros-titres;
      margin-left: 0.7rem;
      display: flex;

      flex-wrap: wrap;
    }
    .p-accordion-header-link {
      border: none !important;
      box-shadow: none;
    }
    .p-accordion-tab.p-accordion-tab-active {
      box-shadow: none;
    }
    .p-accordion-content {
      border: none !important;
      width: 100%;
      padding: 0;
    }
  }
}
.cardHover_univ {
  margin-top: 1rem;
  margin-right: 2rem;
  padding: 1rem 1rem;
  box-shadow: 1px 1px 8px 1px c.$gris-bordure;
  &:hover {
    cursor: pointer;
    transform: scale(1.01);
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
  }
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$small) {
  .article_presentation {
    padding: 0 1rem;
    .presentation_title {
      .h1_accueilPresentation {
        font-size: 1.5rem !important;
        margin-left: 0.5rem;
      }
    }
    p {
      margin: 0.5rem 2rem;
    }
    .article_presentation h1 {
      padding-bottom: 2rem;
    }
    .article_presentation p {
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 5rem;
      margin: 0;
    }
    .logos_univ {
      padding-left: 1rem;
      padding-bottom: 1rem;
      justify-content: center;
      padding-right: 1rem;
    }
    .p-accordion-header-text {
      margin-left: 0 !important;

      flex-wrap: wrap !important;
    }
    .p-accordion.p-component {
      width: 100%;
      .p-accordion-tab {
        width: 100%;
        border: none;
        // margin-left: 1rem !important;
      }
      .p-accordion-toggle-icon.pi.pi-chevron-down,
      .p-accordion-toggle-icon.pi.pi-chevron-right {
        margin-left: 0 !important;
      }
    }
    #pr_id_3_header_0.p-accordion-header-link {
      padding: 0;
    }
    .article_presentation {
      .logo_container {
        width: 90%;
        margin: 0 5%;
      }
      .presentation_title {
        padding: 0 1rem;
      }
    }
  }
}

@use "src/Styles/_vars.scss" as c;

.etudiant {
  background-color: c.$fond-page-clair !important;
  font-family: c.$font-global;
  display: flex;
  flex-direction: column;
  color: black;
  margin: 1rem;
  box-shadow: 2px 3px 6px lightgrey;
  border-radius: 0.33rem;
  height: fit-content;
  margin: 1rem;
}
.etudiant:hover {
  box-shadow: 0px 1px 10px 0px darkgrey;
  transition: 0.4s;
}

.annuaire_etudiant {
  padding: 1rem 1rem;
  &__image {
    display: flex;
    background-color: c.$bleu-paris1;
    position: relative;
    padding: 1rem 0 1rem 6.5rem;
    border-top-right-radius: 0.33rem;
    border-top-left-radius: 0.33rem;
    span {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      font-family: c.$font-sous-menu;
      color: c.$blanc;
    }
    h2 {
      margin-top: 0.2rem;
      color: c.$blanc;
    }
    &__info {
      .domaine {
        font-family: c.$font-global;
      }
    }

    &__logo {
      // pour les entreprises

      // pour les étudiants
      img {
        top: 1rem;
        left: 1.5rem;
        position: absolute;
        border-radius: 50%;
        border: 2px solid c.$fond-page-clair;
        width: 4rem;
        height: 4rem;
      }
    }
  }
  &__container {
    margin-top: 0.5rem;
    &__description {
      margin: 1rem 0 0 0;

      &__title,
      &__statut,
      &__localisation,
      &__employes {
        margin: 0 0 0 1.5rem;

        span {
          margin-left: 1rem;
        }
      }
      &__statut {
        font-family: c.$font-sous-menu;
      }
      &__title {
        a {
          color: c.$noir-rp;
        }
        .title_etudiant {
          margin-bottom: 0.5rem;
          font-family: c.$font-sous-menu !important;
          color: c.$bleu-paris1;
        }
        .promo_etudiant {
          margin-bottom: 0.5rem;
          color: c.$bleu-paris1;
        }
        .tel_etudiant {
          margin-bottom: 0.5rem;
          color: c.$bleu-paris1;
        }
        .reseau {
          margin-right: 0.4rem;
        }
        img {
          width: 1rem;
          margin-top: 0.5rem;
          margin-bottom: 0.3rem;
        }
        &__item {
          display: flex;
          align-items: baseline;
          font-size: 14px;
          height: 2rem;
          svg {
            margin-right: 0.5rem;
          }

          label {
            display: flex;
            align-items: center;
            font-weight: 700;
            margin-left: 0;
            margin-right: 0.5rem;
          }
          p {
            display: flex;
            justify-content: flex-start;
          }
          .highlight_mail {
            overflow: hidden;
            a {
              width: 15rem;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            padding-right: 0.5rem;
          }

          .highlight_mail:hover {
            color: c.$bleu-secondaire;
            transition: 0.2s;
          }
          &__formation {
            font-weight: 700;
            margin-bottom: -1.5rem;
            label {
              margin-left: 0;
            }
            h3 {
              font-family: c.$font-sous-menu;
              font-size: 1.2rem;
            }
          }
        }
      }
      .all_bottom {
        display: flex;
        justify-content: space-between;
        padding-right: 2rem;
      }
      &__bottom {
        height: 2.8rem;
        display: flex;
        align-items: center;
        padding-left: 1.5rem;
        .img_card {
          margin-right: 0.5rem;
        }
      }
      &__alumni {
        .parrain,
        .createur {
          font-size: 0.7rem;
          font-weight: bold;
          margin: 0 0 0.5rem 0;
          padding: 0.3rem 0.5rem;
          width: fit-content;
          border-radius: 0.33rem;
          span {
            margin-left: 0.3rem;
          }
        }
        .parrain {
          display: flex;
          align-items: center;
          background-color: c.$fond-parrain;
          color: c.$texte-parrain;
          margin-right: 0.5rem;
        }
        .createur {
          display: flex;
          align-items: center;
          background-color: c.$fond-entreprise-partenaire;
          color: c.$texte-entreprise-partenaire;
        }
      }
    }
  }
}
.annuaire_etudiant.offre__date {
  display: flex;
  flex-direction: column;
  margin-top: -0.5rem;
}

.email_annuaire {
  margin-left: 1rem;
}
.email_annuaire:hover {
  background-color: c.$bleu-paris1;
  color: c.$blanc;
  transition: 1s;
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$tiny) {
  .etudiant {
    min-width: none;
    margin: 1rem 0;
  }
  .annuaire_etudiant__image {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
}

@use "src/Styles/_vars.scss" as c;

.offresHorsConnexionContainer {
  background-image: url("/assets/accueilOffresHorsConnexion.webp");
  min-height: 22rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__h2 {
    color: white;
    margin-bottom: 2rem;
    font-family: c.$font-gros-titres;
    font-size: 48px;
    font-weight: 600;
  }
  &__button {
    border: 1px solid c.$blanc;
    background-color: c.$blanc;
    box-sizing: border-box;
    border-radius: 30px;
    width: fit-content;
    height: 3rem;
    color: c.$bleu-paris1;
    transition: all 0.5s;
    padding: 0rem 1.5rem;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    font-weight: 500;
    svg {
      margin-left: 0.5rem;
      margin-top: 0.25rem;
      width: 0.4rem;
    }
  }
  &__button:hover {
    cursor: pointer;
    background-color: transparent;
    color: white;
  }
}

@media screen and (max-width: c.$small) {
  .offresHorsConnexionContainer {
    &__h2 {
      font-size: 26px;
      text-align: center;
    }
  }
}

@use "src/Styles/_vars.scss" as c;

.profilPage {
  #header {
    .menu {
      min-height: 30rem;
    }
  }
}
@media screen and (max-width: c.$medium) {
  .profilPage {
    #header {
      .menu {
        min-height: fit-content;
      }
    }
  }
}

@use "src/Styles/_vars.scss" as c;

.salaire--slider {
  display: flex;
  width: 100%;
  &--label {
    margin: 0 0.5rem 0.5rem 0;
    display: inline-block !important;
    padding-top: 0.35rem;
    width: 30%;
  }
  &--container {
    width: 70%;
    &--slider {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    }
  }
}

@media screen and (max-width: c.$medium) {
  .salaire--slider {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
    // &--label {
    //   margin: 0 0.5rem 0.5rem 0;
    //   display: inline-block !important;
    //   padding-top: 0.6rem;
    //   width: 30%;
    // }
    // &--container {
    //   width: 70%;
    //   &--slider {
    //     display: flex;
    //     justify-content: space-between;
    //     margin-bottom: 0.5rem;
    //   }
    // }
  }
}

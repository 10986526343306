@use "src/Styles/_vars.scss" as c;
.groupe_container__title__filtres.gestion {
  .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 2.25rem;
  }
  .btn-blanc.left {
    height: 2.5rem;
    margin: 2rem 0 1rem 1rem;
  }
}
.groupe_main_section {
  display: flex;
  margin-top: 5rem;
  margin-bottom: 5rem;

  .groupe_container_gestion_left {
    width: 65%;
    //----------- plutôt mettre une min-width pour le cas où il n'y a pas de groupes
    border-radius: 1rem;
  }
  .groupe_container_gestion_right {
    width: 30%;
    margin-left: 5%;
    border-radius: 1rem;
    height: fit-content;
  }
}

@media screen and (max-width: c.$medium) {
  .groupe_main_section {
    .groupe_container_gestion_right {
      width: 100%;
      margin: 0;
      .membership_container {
        .p-scrollpanel.p-component {
          height: 14rem !important;
        }
      }
      .groupe_card_demande_container {
        width: 30rem;
        flex-wrap: nowrap;
      }
    }
    .groupe_container_gestion_left {
      width: 100%;
    }
  }
}

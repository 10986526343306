@use "src/Styles/_vars.scss" as c;

.p-dialog.p-component.modal.p-dialog-enter-done {
  /* override le style inline */
  height: fit-content !important;

  .p-dialog-title {
    color: white;
    margin-top: 0;
  }
  .p-dialog-header {
    background-color: c.$bleu-paris1;
  }
  .groupe_container {
    padding: 2rem 0;
    .creation_groupe_container {
      width: 100%;
      .question.checkbox {
        margin-top: 2rem;
        margin-left: 2rem;
        margin-bottom: 2rem;
        width: 100%;
      }
      .question {
        width: 95%;
        .question_label {
          font-family: c.$font-sous-menu;
          color: c.$bleu-paris1;
          font-size: large;
        }
        label {
          color: c.$bleu-paris1;
        }
      }

      .question.bouton {
        display: flex;
        flex-direction: row;
        align-items: center;
        .btn-bleu {
          margin-right: 1rem;
        }
      }
      .desc {
        width: 95%;
        h2 {
          align-self: flex-start;
        }
        .quill {
          margin-bottom: 0;
          .ql-editor {
            min-height: 4rem;
            max-height: 10rem;
            overflow-y: auto;
          }
        }
      }
      .question_double_column {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        h2 {
          padding-bottom: 0;
        }
        .question {
          width: 45%;
        }
        .question_double {
          display: flex;
        }
      }
    }
    .question.checkbox {
      margin-top: 1vh;
      label.label_checkbox.label_modalEvent {
        margin-top: 0rem !important;
      }
    }
  }
}

.modal.modal-event {
  // !important obligatoire pour override le style inline
  width: 50% !important;
}

.modals-buttons {
  padding-top: 3vh;
  display: flex;
  justify-content: space-between;
  width: 70%;
}
.text-center {
  input {
    text-align: center;
  }
}

.modal_creation_react_date_picker {
  margin-left: unset !important;
}

.p-inputtext.p-component.small_item {
  min-width: 30% !important;
  text-align: center;
}

@use "src/Styles/_vars.scss" as c;

.entreprises_cards {
  width: 21svw;
  height: 50svh;
  background-color: c.$blanc;
  box-shadow: 2px 3px 6px lightgrey;
  transition: 0.4s;
  border-radius: 0.33rem;
  &__top {
    position: relative;
    img {
      width: 100%;
      height: 7rem;
      border-top-left-radius: 0.33rem;
      border-top-right-radius: 0.33rem;
    }
    &__logo {
      img {
        width: 5rem;
        height: 5rem;
        position: absolute;
        background-color: c.$blanc;
        top: 4rem;
        left: 4%;
        border-radius: 50%;
        border: 2px solid lightgrey;
      }
    }
  }
  &__corps {
    padding: 2.8rem 1.5rem 0 1.5rem;
    // On spécifie une taille identique peu importe le contenu
    & h3 {
      height: 3.6rem;
      font-family: c.$font-sous-menu;
      font-size: c.$font-size-x-normal;
      line-height: 1.8rem;
      margin-bottom: 0.5rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    & * {
      margin-top: 0.5svh;
    }
    height: 12rem;
    p {
      margin-bottom: unset;
      height: 2rem;
      i {
        margin-right: 0.5rem;
      }
      svg {
        margin-right: 0.5rem;
      }
    }
  }
  &__bottom {
    display: flex;
    justify-content: space-around;
    // padding-left: 1.5rem;
    margin-top: 1rem;
    .btn_card {
      width: 13rem;
      height: 1.6rem;
      font-family: c.$font-sous-menu;
      font-size: small;
      color: c.$blanc;
      border: unset;
      background-color: c.$bleu-secondaire;
      border-radius: 0.2rem;
      transition: 0.4s;
      svg {
        margin-right: 0.2rem;
        margin-bottom: 0.15rem;
        color: c.$blanc;
      }
    }
    .partner_company_heart {
      color: rgb(243, 102, 125);
      &.isHovered:hover {
        color: grey;
      }
    }
    .not_partner_company_heart {
      color: grey;
      &.isHovered:hover {
        color: rgb(243, 102, 125);
      }
    }
    .not_partner_company_heart,
    .partner_company_heart {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  &:hover {
    cursor: pointer;
    box-shadow: 0px 1px 10px 0px darkgrey;
  }
}

@media screen and (max-width: c.$medium) {
  .entreprises_cards {
    width: 40svw;
    height: 30svh;
    min-width: unset;
    max-width: none;
    margin: 5% 2rem;
    &__top {
      img {
        width: 100%;
      }
      &__logo {
        img {
          width: 5rem;
        }
      }
    }

    &__corps {
      & * {
        margin-top: unset;
      }

      h3 {
        font-size: medium;
      }

      height: 12rem;
    }
    &__bottom {
      padding-bottom: 0.5rem;
    }
  }
}

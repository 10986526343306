@use "src/Styles/_vars.scss" as c;

.mapContainer {
  width: 80%;
  margin: 2rem auto 5rem;
  font-family: c.$font-gros-titres;

  &__title,
  &__subtitle {
    color: c.$bleu-paris1;
    text-align: center;
  }

  &__title {
    font-weight: bold;
  }

  &__subtitle {
    margin-bottom: 2rem;
  }
  &__container {
    display: flex;
    &__map {
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
      height: 600px;
      width: 70%;
    }
  }
}

@use "src/Styles/_vars.scss" as c;

.universites {
  #header .menu {
    min-height: 0;
  }
  &__container {
    padding: 0 7rem 1rem;
    color: c.$bleu-paris1;
    background-color: c.$fond-page;
    &__top {
      &__title {
        h1 {
          color: c.$bleu-paris1 !important;
          font-family: c.$font-gros-titres;
          font-size: xx-large !important;
          padding-top: 4rem;
        }
      }
      ul {
        list-style-type: disc;
        margin-left: 2rem;
      }
      &__boutons {
        display: flex;
        justify-content: flex-start;
        .btn-bleu,
        .btn-blanc {
          margin: 2rem 0 0 0;
        }
        .btn-bleu {
          margin-right: 1rem;
        }
      }
    }
    &__bottom {
      &__title {
        .container_input_entreprises {
          position: relative;
          svg {
            position: absolute;
            color: c.$gris-picto;
            font-size: 1.5rem;
            top: 0.5rem;
            left: 0.2rem;
          }
          input {
            padding-left: 2.2rem;
          }
        }
        display: flex;
        justify-content: space-between;
        h1 {
          color: c.$bleu-paris1 !important;
          font-family: c.$font-gros-titres;
          font-size: xx-large !important;
          padding-top: 4rem;
        }
        .p-dropdown.p-component.p-inputwrapper {
          margin-top: 4rem;
          width: fit-content !important;
        }
      }
      &__entreprises {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(10rem, 20rem));
        gap: 1rem 2rem;
        grid-template-rows: auto;
        justify-content: space-evenly;
        a {
          width: fit-content;
          .entrprises_cards {
            margin: 0 2rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: c.$small) {
  .universites {
    &__container {
      padding: 0 1rem;
      &__top {
        h1 {
          padding-top: 1rem;
        }
      }
      &__bottom {
        padding-bottom: 2rem;
        &__title {
          h1 {
            padding-top: 2rem;
          }
          flex-direction: column;
          .p-dropdown.p-component.p-inputwrapper {
            margin-top: 1rem;
          }
        }
        &__entreprises {
          a {
            width: 100%;
            .entreprises_cards {
              width: 100%;
              margin: 1rem 0;
            }
          }
        }
      }
    }
  }
}

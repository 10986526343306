@use "src/Styles/_vars.scss" as c;

.groupe_comment {
  border: 3px solid c.$fond-page;
  box-shadow: 0px 2px 4px lightgray;
  margin: 2rem 0;
  &__form {
    margin: 0 !important;
    padding-bottom: 2vh;
  }
  &__top {
    padding: 1rem;
    &__container {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 1rem;
      .btn-blanc {
        padding: 0;
        width: 3rem;
        height: 3rem;
        border: none;
        color: c.$bleu-paris1;
        &:hover {
          color: c.$bleu-paris1;
          background-color: transparent;
        }
      }
      &__user {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        &-date {
          padding-left: 1vw;
          color: grey;
          font-size: smaller;
        }
        img {
          height: 3rem;
          margin-right: 0.5rem;
          border-radius: 50%;
        }
        h5 {
          color: c.$bleu-paris1;
        }
      }
    }
    &__file {
      display: flex;
      justify-content: flex-end;
      margin: 0.5rem 0;
      color: c.$bleu-paris1;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      &-link {

        // make text not overflow, and replace the overflow by ellipsis
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
  &__bottom {
    padding: 0.5rem 0.5rem 0.5rem 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    background-color: c.$fond-page;
    .p-inputtext.p-component {
      height: 5rem !important;
      width: 90%;
    }
    img {
      width: 3rem;
      border-radius: 50%;
      margin-right: 1rem;
      margin-left: 1rem;
    }
    .p-inputtext.p-component {
      // !important à cause du fichier FormationDiplome.scss qui override tout
      min-width: 70% !important;
      width: 70%;
    }
    .btn-bleu {
      margin: 0;
      flex-wrap: nowrap;
    }
    .btn-bleu,
    .btn-blanc {
      border: none;
      margin: 0.5rem 0 0.5rem 0.5rem;
      height: 2rem;
    }
  }
  &__messages {
    display: flex;
    align-items: center;
    flex-direction: column;

    // !important obligatoire car des fichiers override tout
    button.commentaires-bouton:hover {
      background-color: transparent !important;
      text-decoration: underline;
      color: c.$bleu-paris1 !important;
    }
    button.commentaires-bouton {
      color: c.$bleu-paris1 !important;
    }

    &__message {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      img {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        margin-right: 1rem;
        margin-left: 1rem;
      }
      &__block {
        margin: 0.5rem 0 0.5rem 0;
        background-color: c.$fond-page;
        padding: 0.5rem;
        border-radius: 1rem;
        display: flex;
        justify-content: space-between;
        width: 80%;
        &-user {
          color: c.$bleu-paris1;
        }
        &-date {
          padding-left: 1vw;
          color: grey;
          font-size: smaller;
        }
        h4 {
          font-size: medium;
          color: c.$bleu-paris1;
        }
        h5 {
          font-size: medium;
          font-family: c.$font-global;
        }
        .publication_btn {
          background-color: transparent !important;
          color: c.$bleu-paris1 !important;
          background-size: 15px !important;
        }
      }
    }
  }
}

@media screen and (max-width: c.$small) {
  .groupe_comment {
    &__top {
      &__file {
        margin-top: 1.5rem;
      }
    }
    &__messages {
      :last-child {
        margin-bottom: 1vh;
      }
      &__message {
        img {
          margin-bottom: 0.5vh;
        }
        margin-bottom: 1vh;
        &__block {
          margin: 0 auto;
        }
      }
    }
    &__bottom {
      flex-direction: column;
      align-items: center;
      &-avatar {
        padding-bottom: 0.5rem;
      }
      .p-inputtext.p-component {
        height: 5rem !important;
        width: 90%;
      }

      .btn-bleu,
      .btn-blanc {
        margin: 0.5rem 0 0.5rem 0;
      }
    }
  }
}

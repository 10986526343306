@use "src/Styles/_vars.scss" as c;

.MecenatGroupeAccueil_container {
  padding: 4rem 7rem;
  width: 119rem;
  &__boxes {
    min-width: 100%;
    height: 28rem;
    display: flex;
    &__box {
      min-width: 25%;
      height: 28rem;
      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3rem;
        font-family: c.$font-gros-titres;
        text-align: center;
        font-weight: 700;
      }
      &.first {
        padding-top: 4rem;
        background-color: c.$bleu-paris1;
        color: white;
        .title {
          height: 10rem;
          padding: 0 4rem;
        }
        .mecenatProvisoire {
          color: c.$blanc;
          text-align: center;
          padding: 1rem;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 8;
          max-height: 13rem;
        }
      }
      &.second {
        background-image: url(../../assets/FaireDonConnected.webp);
      }
      &.third {
        background-color: c.$fond-page;
        display: flex;
        flex-direction: column;
        .title {
          height: 20rem;
          padding: 0 7rem;
          color: c.$bleu-paris1;
        }
        .MecenatGroupeAccueil {
          height: 3rem;
          display: flex;
          margin-top: 0;
          align-self: center;
          text-transform: uppercase;
        }
      }
      &.fourth {
        background-image: url(../../assets/ReseauDynamique.webp);
      }
    }
  }
  &__boxesResponsive {
    display: none;
  }
}

@media screen and (max-width: c.$small) {
  .MecenatGroupeAccueil_container {
    padding: 0;
    &__boxes {
      display: none;
    }
    &__boxesResponsive {
      display: block;
      &__box {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          height: 6rem;
          display: flex;
          align-items: center;
          padding: 0 1rem;
          color: c.$bleu-paris1;
          font-size: 2.5rem;
          font-family: c.$font-gros-titres;
          text-align: center;
          font-weight: 700;
        }
        &.first {
          background-color: c.$fond-page;
          padding: 1rem 0 3rem;
          .mecenatProvisoire {
            padding: 0 2rem;
          }
        }
        &.second {
          padding: 3rem 0 0;
        }
      }
    }
  }
}

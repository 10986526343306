@use "src/Styles/_vars.scss" as c;

.modal_motif_refus {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .error_refus_length {
    color: #e24c4c;
    margin-bottom: 2rem;
  }
  button {
    align-self: center;
  }
}
.motif_refus_interface {
  max-width: 20rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  &_message {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    &_title {
      color: black;
      font-weight: bold;
    }
    &_text {
      margin: 0.5rem 0 0 0.5rem;
    }
  }
  &_bouton {
    display: flex;
    justify-content: center;
  }
}

.logo__card__gestion {
  width: 4rem;
  height: 4rem;
  margin: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: 2rem;
  .image__bg {
    top: 0;
    left: 0;
    position: absolute;
    width: 110%;
    height: 110%;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(4px);
    opacity: 0.6;
  }
  img {
    max-width: 100%;
    max-height: 4rem;
    object-fit: cover;
    z-index: 900;
  }
}

.gestion_evenements_container {
  width: 100%;
  background-color: c.$blanc;
  box-shadow: 3px 4px 10px lightgrey;
  height: 6rem;
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &__left {
    max-width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    .groupe_menu.mobile {
      display: none;
    }
    .groupe_menu {
      display: flex;
      justify-content: flex-end;
      .btn-rond {
        color: c.$bleu-paris1;
        height: 2rem;
        width: 3rem;
        background-color: transparent;
        margin-bottom: 1rem;
      }
    }
    &__info {
      span {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0.5rem;
      }
      h5 {
        font-family: c.$font-global;
        color: c.$bleu-paris1;
        margin-bottom: 0;
        font-weight: 600;
      }
      h1.title {
        color: c.$bleu-paris1;
        font-family: c.$font-sous-menu;
        margin-bottom: 0.5rem;
      }
      &__bottom {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        // flex-wrap: wrap;
        align-items: center;
        color: c.$gris-picto;
        font-family: c.$font-global;
        h2 {
          align-items: center;
          display: flex;
        }
        i {
          margin-right: 0.5rem;
        }
        &__dates {
          margin-right: 0.5rem;
          h2 {
            margin: 0;
          }
        }
      }
      h2 {
        font-size: small;
        margin-right: 1rem;
      }
    }
  }

  &__bouton {
    margin-right: 1rem;
    // margin-left: 7rem;
    justify-self: flex-end;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    width: 7rem;
    .groupe_menu {
      display: flex;
      justify-content: flex-end;
      .btn-rond {
        color: c.$bleu-paris1;
        height: 2rem;
        width: 3rem;
        background-color: transparent;
        margin-bottom: 1rem;
      }
      .p-menuitem-text {
        display: flex;
        justify-content: center;
      }
    }

    span {
      padding: 0 1rem;
      border-radius: 0.33rem;
      display: flex;
      justify-content: center;
    }
    .brouillon {
      background-color: c.$fond-page;
      color: c.$gris-picto;
    }
    .publie {
      background-color: c.$vert-groupe;
    }
    .attente {
      background-color: c.$orange-groupe;
    }

    .refuse {
      background-color: c.$rouge-groupe;
    }
    .cloture {
      color: #5b5b5b;
      background-color: c.$fond-page;
      font-weight: 500;
    }
    .desactive {
      background-color: #59cdf0;
    }
  }
}

.p-menu a.p-menuitem-link {
  padding: 0.75rem 0.5rem 0.75rem 0.75rem;
}

@media screen and (max-width: c.$medium) {
  .gestion_evenements_container {
    flex-direction: column;
    flex-wrap: nowrap;
    height: fit-content;
    padding: 1rem 0;
    justify-content: center;
    &__logo {
      .groupe_menu.mobile {
        display: unset;
      }
    }
    &__info {
      padding: 0.5rem 1rem;
      width: fit-content;
      h5 {
        display: flex;
        justify-content: center;
      }
      &__bottom {
        justify-content: center;
        &__dates {
          margin-bottom: 0.5rem;
        }
      }
    }
    &__bouton {
      justify-content: center;
      .groupe_menu {
        justify-content: center;
      }
      .groupe_menu.desktop {
        display: none;
      }
    }
  }
}

@use "src/Styles/_vars.scss" as c;

#mobile.monProfil {
  display: none;
}

.monProfil {
  top: -3rem;
  width: 100%;
  padding: 0 15%;
  &__container {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    &__header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      &__title {
        margin-left: 10rem;
      }
      h1 {
        font-family: c.$font-gros-titres !important;
        font-size: c.$font-size-x-large !important;
        color: white;
        margin-bottom: -0.5rem;
        text-transform: uppercase;
      }
      h2 {
        font-family: c.$font-global;
        font-size: c.$font-size-x-small;
        color: white;
        font-style: italic;
      }
    }
    .bouton {
      display: flex;
      justify-content: flex-end;
      width: fit-content;
      align-items: center;
      .btn-bleu {
        background-color: c.$bleu-secondaire;
        border-color: c.$bleu-secondaire;
        margin: 0;
        &:hover {
          background: c.$bleu-secondaire !important;
        }
      }
    }

    .profilDescription {
      min-height: 5rem;
      width: 100%;
      position: relative;
      background-color: c.$blanc;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      border-radius: 1rem;
      padding: 3.5rem 1rem 1.5rem 1rem;
      justify-content: center;
      .div_horiz {
        display: none;
      }
      .div_vert {
        display: unset;
      }
      .user__alumnis {
        height: 0;
        img {
          position: relative;
          bottom: 7rem;
          left: 0rem;
          z-index: 9;
        }
      }
      .user__picture img {
        position: absolute;
        top: -4rem;
        left: 2rem;
        width: 7rem;
        height: 7rem;
        border-radius: 50%;
        border: 3px solid white;
        object-fit: cover;
      }
      .column {
        min-height: 5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 80%;
        margin-left: 2rem;
        h1 {
          font-family: c.$font-sous-menu;
          font-size: c.$font-size-x-normal !important;
          margin-bottom: 0rem;
        }
        .apercu {
          font-size: small;
          font-style: italic;
          color: c.$bleu-secondaire;
          font-weight: 500;
          a::before {
            background-color: unset;
          }
        }

        .underline {
          font-family: c.$font-sous-menu;
          color: c.$bleu-secondaire !important;
          text-transform: uppercase;
          position: relative;
          width: fit-content;
          &::before {
            content: "";
            display: block;
            background-color: c.$bleu-secondaire;
            height: 0.1rem;
            position: absolute;
            width: 100%;
            top: 1.5rem;
          }
          &::after {
            content: "";
            display: block;
            background-color: c.$bleu-secondaire;
            height: 0.1rem;
            position: absolute;
            width: 100%;
            top: 1.5rem;
          }
        }
        p {
          font-family: c.$font-global !important;
          color: c.$bleu-paris1;
          margin: 0.5rem 0;
        }
        &__infoApplyJob {
          color: c.$bleu-secondaire !important;
          font-style: italic;
          font-size: small !important;
        }
        .span_gris {
          color: c.$gris-picto;
          font-size: small;
          margin-right: 2rem;
        }
        &__groups {
          display: flex;
          flex-direction: row;

          .loaderContainer {
            padding: 2rem 0;
          }

          &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 2rem 0 0;
            cursor: pointer;
            img {
              width: 3rem;
              height: 3rem;
              border-radius: 50%;
            }
            .btn-bleu {
              margin: 0;
              padding: 0 1rem;
              &:hover {
                background: c.$bleu-paris1;
                background-color: c.$bleu-paris1 !important;
                color: white;
              }
              svg {
                margin: 0;
              }
            }
            span {
              color: c.$bleu-paris1;
            }
          }
        }
      }
      &__progressBar {
        height: 1rem;
        border-radius: 1rem;
        .p-progressbar-value {
          background-color: c.$bleu-secondaire;
        }
      }
    }
  }
}

// modifications d'affichage en passant sur tablette
@media screen and (max-width: c.$medium) {
  #desktop.monProfil {
    display: none;
  }
  #mobile.monProfil {
    display: unset;
  }

  .monProfil {
    position: relative;
    padding-bottom: 1rem;
    top: 0;
    padding: 1rem 5%;
    width: 100%;
    text-align: center;
    &__container {
      box-shadow: 2px 3px 6px lightgray;
      background-color: c.$blanc;
      flex-direction: column;
      flex-wrap: nowrap;
      width: 90%;
      height: 100%;
      padding: 0 5%;
      margin: 0 5%;
      &__header {
        background-color: c.$blanc;
        left: 0rem;
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
        margin-top: 4rem;
        margin-left: 0;
        &__title {
          margin-left: 0;
        }
        h1 {
          color: c.$bleu-paris1;
          padding-top: 3rem;
          font-size: x-large !important;
        }
        h2 {
          color: c.$bleu-paris1;
        }
      }
      .profilDescription {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: nowrap;
        padding: 0;
        position: unset;
        border-radius: unset;
        box-shadow: none !important;
        .div_horiz {
          display: unset;
        }
        .div_vert {
          display: none;
        }
        &__progressBar {
          margin-top: -2rem;
          margin-bottom: -1.5rem;
          height: 0.7rem;
          width: 50%;
          display: flex;
          align-self: center;
          top: 2rem;
        }
        .column {
          margin: 1rem 0;
          width: 100%;
          margin-left: 0;
          flex-wrap: wrap;
          justify-content: center;
          h1 {
            font-size: 1.2rem !important;
          }

          p {
            width: 100%;
          }
          &__infoApplyJob {
            margin-top: 4rem !important;
          }
          &__groups {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            flex-direction: row;
            margin-bottom: 1rem;
            img {
              height: fit-content;
            }
          }
        }
        .column:nth-child(4) {
          a {
            padding-bottom: 2rem;
          }
        }

        .user__picture {
          display: flex;
          justify-content: center;
        }

        .user__picture img {
          width: 5rem;
          height: 5rem;
          top: 2rem;
          left: unset;
          border: 2px solid c.$bleu-paris1;
        }

        .user__alumnis img {
          width: 2rem;
          bottom: 10rem;
          left: -2rem;
        }
        .p-divider.p-component.p-divider-horizontal.p-divider-solid.p-divider-left {
          margin: 0;
        }
        .p-divider-horizontal::before {
          width: 80%;
          margin: 0 10%;
        }
        .p-divider-horizontal.p-divider-left::before {
          justify-content: center;
        }
      }
      .bouton {
        display: none;
      }
    }
  }
}

.p-fileupload-choose {
  margin: 0 !important;
  span {
    font-size: medium !important;
  }
}
.myresume {
  .p-fileupload-files {
    display: none;
  }
}
.myresume span,
svg {
  cursor: pointer;
}

@use "src/Styles/_vars.scss" as c;

.groupe_publication {
  border: 3px solid c.$fond-page;
  box-shadow: 0px 2px 4px lightgray;
  margin: 2rem 0;

  &__form {
    margin: 0 !important;
  }

  .boutons_mobile {
    display: none;
  }

  &__top {
    padding: 1rem;

    &__input {
      padding: 1rem 0;
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      img {
        height: 4rem;
        margin-right: 0.5rem;
      }

      .p-inputtext.p-component {
        width: 90% !important;
        height: 4rem;
      }
    }

    h4 {
      color: c.$bleu-paris1;
      font-size: large;
    }
  }

  &__bottom {
    display: flex;
    // force flex on same line (no wrap and in row)
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: c.$fond-page;

    .btn-bleu {
      margin: 0;
      flex-wrap: nowrap;
    }

    .btn-bleu,
    .btn-blanc.left {
      border: none;
      margin: 0.5rem;
      height: 2rem;
    }

    .btn-blanc.left {
      color: c.$bleu-paris1;
    }
  }

  .publication_btn {
    background-color: transparent !important;
    color: c.$bleu-paris1 !important;
    background-size: 15px !important;
    &.uploaded {
        font-weight: bold !important;
    }

    :hover {
      background-size: 15px !important;
      background-color: c.$bleu-paris1 !important;
      color: white !important;
    }

    &_icon {
      margin-left: 0.5rem !important;
    }
  }
}


@media screen and (max-width: c.$medium) {
  .groupe_publication {
    .boutons_mobile {
      display: inline;

      svg {
        margin-right: 0;
      }

      .publication_btn {
        &.uploaded {
          .publication_btn_icon {

          }
        }
      }
    }

    .boutons_desktop {
      display: none;
    }
  }
}

.is_uploaded {
  display: none !important;
}

@use "src/Styles/_vars.scss" as c;

.ecoute_marche {
  padding: 3rem 0 0 0;
  &_button {
    display: flex;
    align-items: center;
    gap: 0.5vw;
    label {
      margin: 0.5rem;
      font-family: c.$font-sous-menu;
    }
    h1 {
      padding: 1rem 0 0rem 0 !important;
      font-family: c.$font-global;
    }
    margin-bottom: 1vh;
  }
  a {
    color: c.$bleu-secondaire !important;
    cursor: pointer;
  }
}

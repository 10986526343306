@use "src/Styles/_vars.scss" as c;

.article_offre {
  background-color: c.$fond-page;
  // .p-paginator.p-component {
  //   background-color: c.$fond-page;
  // }
  padding: 0 7rem;
  h1 {
    padding-top: 4rem;
  }

  &__container {
    .sous_titre_offre {
      top: -3rem;
      position: relative;
      margin-bottom: -2.5rem;
    }
    h1 {
      margin-bottom: 1rem;
      font-family: c.$font-gros-titres;
      font-size: 3.5rem !important;
      color: c.$bleu-paris1;
      padding-bottom: 2rem;
      font-weight: 600;
    }
    h2 {
      font-family: c.$font-sous-menu;
      color: c.$bleu-paris1;
      padding-bottom: 1rem;
    }
    p {
      margin-bottom: 2rem;
    }

    .p-inputtextarea {
      font-family: c.$font-global;
      padding-bottom: 1rem;
      display: flex;
      flex-direction: column;
      position: relative;
      width: 80%;
    }

    .p-multiselect {
      border-radius: 2rem !important;
      font-family: c.$font-global;
    }

    &__boutons {
      display: flex;
      justify-content: space-around;
    }

    .question {
      font-family: c.$font-sous-menu;
      padding-bottom: 1rem;
      display: flex;
      flex-direction: column;
      position: relative;
      width: 80%;

      .btn-blanc {
        width: fit-content !important;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }

      .logos_univ {
        margin-top: 1rem;
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: flex-start;
        flex-wrap: wrap;

        .logo_univ_1 {
          border: 1px solid c.$gris;
          border-radius: 0.5rem;
          background-color: c.$blanc;
          width: 6rem;
          height: 5rem;
          display: flex;
          justify-content: space-around;
          align-items: center;

          img {
            width: 5rem;
            height: fit-content;
          }
        }
        .logo_hover {
          border: 2px solid c.$bleu-paris1;
          border-radius: 0.5rem;
          background-color: c.$blanc;
          width: 6rem;
          height: 5rem;
          display: flex;
          justify-content: space-around;
          align-items: center;

          img {
            width: 5rem;
            height: fit-content;
          }
        }
      }

      label {
        padding-top: 1rem;
        font-family: c.$font-global;
      }
      .salaire {
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        width: 80%;

        .input {
          width: 40%;
        }
        &__montant {
          display: flex;
          justify-content: center;
          flex-direction: column;
          width: 50%;
          position: relative;
        }
      }

      &__input {
        border-radius: 5rem;
        margin-top: 1rem;
        padding: 1rem 1rem 1rem 2.5rem;
        border: none;
        font-size: 1rem;
        width: 100%;
      }
    }
    .description {
      padding-bottom: 1rem;
    }
  }
  &__button {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .offre_title {
    h1 {
      font-family: c.$font-gros-titres;
      color: c.$bleu-paris1;
      font-size: xx-large !important;
    }
  }
  .btn-bleu {
    background-color: c.$bleu-paris1;
    border-color: c.$bleu-paris1;
    margin-bottom: 4rem;
    margin-top: 2rem;
  }

  &__offres {
    display: grid;
    // grid-template-columns: repeat(4, minmax(0, auto));
    grid-template-columns: repeat(auto-fit, minmax(15rem, 23rem));
    gap: 1rem 3.7rem;
    grid-template-rows: auto;
    justify-content: center;
    margin-top: 3rem;
    .offre {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
  }
}

.modal {
  .pi-send {
    color: c.$vert-icon;
    display: flex;
    justify-content: center;
    font-size: 4rem;
    margin-top: 1.5rem;
  }
  .pi-trash {
    color: c.$vert-icon;
    display: flex;
    justify-content: center;
    font-size: 4rem;
  }
  .description {
    padding-top: 1rem;
  }
}

@media screen and (max-width: c.$large) {
  .article_offre {
    &__offres {
      // justify-content: center;
      // grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}

@media screen and (max-width: c.$medium) {
  .article_offre {
    &__offres {
      // justify-content: center;
      // grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$small) {
  .article_offre {
    padding: 1rem 2rem;
    h1 {
      padding-top: 1rem;
    }
    .salaire {
      display: flex;
      justify-content: space-between;
      flex-direction: column !important;
    }
    &__boutons {
      display: flex;
      flex-direction: column;
      .btn-blanc {
        margin: 0.5rem;
      }
      .btn-bleu {
        margin: 0.5rem;
      }
    }
    &__offres {
      display: grid;
      gap: 1rem 1rem;
      grid-template-rows: auto;
    }
  }
}

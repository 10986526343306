@use "src/Styles/_vars.scss" as c;

.group-list {
  width: 100%;
  height: fit-content;
  &__card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2.5svh 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;

    &__info {
      display: flex;
      align-items: center;
      flex: 1;

      &-name {
        margin-right: 10px;
        color: c.$bleu-paris1;
        flex: 1;
      }

      .icon {
        color: #777;
        margin-left: auto;
      }
    }
  }
}

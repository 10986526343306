@use "src/Styles/_vars.scss" as c;

.groupe_card_member_container {
  display: flex;
  background-color: transparent;
  margin-top: 1.5rem;
  justify-content: space-between;
  .groupe_image {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      margin-left: 1rem;
      width: 3rem;
      height: 3rem;
      border-radius: 2rem;
      margin-right: 1rem;
      image-rendering: -webkit-optimize-contrast;
    }
    h4 {
      padding-top: 1rem;
      margin-bottom: 0.5rem;
      font-family: c.$font-global;
      font-size: c.$font-size-normal;
      color: c.$bleu-paris1;
    }
  }
  .groupe_description {
    p {
      font-family: c.$font-global;
      font-size: c.$font-size-small;
    }
  }
  .groupe_menu {
    margin-left: 1rem;
    .btn-rond {
      background-color: transparent;
      color: c.$bleu-paris1;
      height: 3rem;
      width: 3rem;
      margin-right: 1rem;
      &:hover {
        background-color: c.$fond-page-clair;
        color: c.$bleu-paris1;
      }
    }
  }
}

.p-menu-separator {
  width: 80%;
  margin: 0 10% 0.5rem 10% !important;
}

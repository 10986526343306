@use "src/Styles/_vars.scss" as c;

.p-divider .p-divider-content {
  background-color: c.$fond-page !important;
}
.p-tag {
  background-color: c.$bleu-paris1 !important;
  font-size: medium !important;
}

.p-divider.p-component.p-divider-horizontal.p-divider-solid.p-divider-left {
  margin-top: 2rem;
}

.p-dialog.p-component.modal.p-dialog-enter-done {
  // width: 40% !important;
  height: fit-content;
}
.modal_description_charte {
  box-sizing: border-box;
  display: flex;
  text-align: center;
  margin-top: 2rem;
  .form_charte {
    display: flex;
    flex-direction: column;
  }
  .charte_modal {
    color: c.$bleu-paris1;
    font-weight: 700;
  }
}

.charter_link {
  text-decoration: underline;
  font-family: "font-global";
  cursor: pointer;
  transition: color 1s;
  &:hover {
    color: c.$bleu-secondaire;
  }
}

@use "src/Styles/_vars.scss" as c;

.form-demo {
  .card {
    border: none;
    background: c.$fond-page;

    form {
      padding: 2rem;

      .ask_connexion_name,
      .ask_connexion_university,
      .ask_connexion_coordinates,
      .ask_connexion_country,
      .checkboxes {
        display: flex;
        flex-direction: row;
      }
    }

    .checkboxes {
      .checkbox_student,
      .checkbox_alumni {
        margin-right: 0.5rem;
      }
    }

    .field {
      margin: 0.5rem;
      width: 70%;

      &.half {
        width: 35%;
      }

      &.centered {
        margin: 0 auto;
      }

      &.full {
        width: 98%;
      }

      & h2 {
        margin-bottom: 0.5rem;
        font-size: 15px;
      }

      .datepicker_create_account .MuiInputBase-root {
        margin-top: 0;
      }
    }
  }
}

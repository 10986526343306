@use "src/Styles/_vars.scss" as c;

@keyframes tonext {
  75% {
    left: 0;
  }
  95% {
    left: 100%;
  }
  98% {
    left: 100%;
  }
  99% {
    left: 0;
  }
}

@keyframes tostart {
  75% {
    left: 0;
  }
  95% {
    left: -300%;
  }
  98% {
    left: -300%;
  }
  99% {
    left: 0;
  }
}

@keyframes snap {
  96% {
    scroll-snap-align: center;
  }
  97% {
    scroll-snap-align: none;
  }
  99% {
    scroll-snap-align: none;
  }
  100% {
    scroll-snap-align: center;
  }
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

* {
  -ms-overflow-style: none;
}

.carousel__navigation,
.carouselContainer {
  ol,
  li {
    list-style: none;
    margin: 0 !important;
    padding: 0 !important;
  }
}

// Réécriture

.carouselContainer {
  position: relative;
  filter: drop-shadow(0 0 10px #0003);
  perspective: 100px;
  height: 30rem;
  z-index: 0;
  &__viewport {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    overflow-x: scroll;
    counter-reset: item;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    height: 31rem;
  }
  &__slide {
    position: relative;
    flex: 0 0 100%;
    width: 100%;
    background-image: url("../../../assets/headerCarousel1.webp");
    counter-increment: item;
  }
  &__slide:nth-child(2) {
    background-image: url("../../../assets/headerCarousel2.webp");
  }
  &__slide:nth-child(3) {
    background-image: url("../../../assets/headerCarousel3.webp");
  }
  &__slide::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -40%, 70px);
    color: c.$blanc;
    font-size: 2em;
  }
  &__snapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    scroll-snap-align: center;
  }
  .carousel__navigation {
    position: absolute;
    right: 0;
    bottom: 2rem;
    left: 0;
    text-align: center;
  }

  .carousel__navigation-list,
  .carousel__navigation-item {
    display: inline-block;
  }

  .carousel__navigation-button {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background-color: c.$blanc;
    background-clip: content-box;
    border: 0.25rem solid transparent;
    border-radius: 50%;
    font-size: 0;
    transition: transform 0.1s;
  }

  .carousel::before,
  .carousel::after,
  .carousel__prev,
  .carousel__next {
    position: absolute;
    top: 0;
    margin-top: 37.5%;
    width: 4rem;
    height: 4rem;
    transform: translateY(-50%);
    border-radius: 50%;
    font-size: 0;
    outline: 0;
  }

  .carousel::before,
  .carousel__prev {
    left: -1rem;
  }

  .carousel::after,
  .carousel__next {
    right: -1rem;
  }

  .carousel::before,
  .carousel::after {
    content: "";
    z-index: 1;
    background-color: #333;
    background-size: 1.5rem 1.5rem;
    background-repeat: no-repeat;
    background-position: center center;
    color: c.$blanc;
    font-size: 2.5rem;
    line-height: 4rem;
    text-align: center;
    pointer-events: none;
  }
  // &__navigation {
  //   &__list {
  //     &__item {
  //       .carouselButton {
  //       }
  //     }
  //   }
  // }
}

@media (hover: hover) {
  .carouselContainer {
    &__snapper {
      animation-name: tonext, snap;
      animation-timing-function: ease;
      animation-duration: 10s;
      animation-iteration-count: infinite;
    }

    &__slide:last-child &__snapper {
      animation-name: tostart, snap;
    }
  }
}

//

@media (prefers-reduced-motion: reduce) {
  .carousel__snapper {
    animation-name: none;
  }
}

.carousel:hover .carousel__snapper,
.carousel:focus-within .carousel__snapper {
  animation-name: none;
}

.carousel__navigation {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
}

.carousel__navigation-list,
.carousel__navigation-item {
  display: inline-block;
}

.carousel__navigation-button {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #333;
  background-clip: content-box;
  border: 0.25rem solid transparent;
  border-radius: 50%;
  font-size: 0;
  transition: transform 0.1s;
}

.carousel::before,
.carousel::after,
.carousel__prev,
.carousel__next {
  position: absolute;
  top: 0;
  margin-top: 37.5%;
  width: 4rem;
  height: 4rem;
  transform: translateY(-50%);
  border-radius: 50%;
  font-size: 0;
  outline: 0;
}

.carousel::before,
.carousel__prev {
  left: -1rem;
}

.carousel::after,
.carousel__next {
  right: -1rem;
}

.carousel::before,
.carousel::after {
  // content: "";
  // z-index: 1;
  // background-color: #333;
  // background-size: 1.5rem 1.5rem;
  // background-repeat: no-repeat;
  // background-position: center center;
  // color: #fff;
  // font-size: 2.5rem;
  // line-height: 4rem;
  // text-align: center;
  // pointer-events: none;
  display: none;
}

.carousel::before {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='0,50 80,100 80,0' fill='%23fff'/%3E%3C/svg%3E");
}

.carousel::after {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='100,50 20,100 20,0' fill='%23fff'/%3E%3C/svg%3E");
}

// end

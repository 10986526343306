@use "src/Styles/_vars.scss" as c;

.membership_container {
  padding: 2rem 2rem;
  box-shadow: 2px 3px 6px lightgray;
  border-radius: 1rem;
  background-color: c.$fond-page-clair;
  margin-bottom: 2rem;
  .membership_style_h2 {
    font-size: c.$font-size-medium;
    font-family: c.$font-sous-menu;
    color: c.$bleu-paris1;
  }
  .membership_input_text {
    width: 70%;
    height: 2.5rem;
    padding-left: 2.5rem;
    border-radius: 1rem;
    box-shadow: 0px 2px 4px lightgray !important;
    background-color: c.$fond-page-clair;
  }
  .searchBar_groupe_echange_icon {
    position: relative;
    color: c.$gris-picto;
    font-size: 1.5rem;
    top: 2rem;
    margin-left: 0.7rem;
  }
  .membership_groupe_cards {
    padding-bottom: 2rem;
    padding-top: 1rem;
  }
  .p-scrollpanel-bar.p-scrollpanel-bar-y {
    background-color: none;
    opacity: unset;
  }
  .p-scrollpanel .p-scrollpanel-bar {
    background: c.$gris-picto;
  }

  .p-scrollpanel-content {
    overflow-x: scroll;
  }
  .p-inputtext.p-component {
    margin-bottom: 1rem;
  }
  .divider_mobile {
    display: none;
  }
  .divider_desktop {
    display: flex;
    width: 100%;
  }

  .p-divider.p-component.p-divider-horizontal.p-divider-solid.p-divider-left.divider_desktop {
    width: 100%;
    .p-divider-content {
      width: 100%;
    }
  }
}
@media screen and (max-width: c.$medium) {
  .membership_container {
    width: 100%;
    .p-scrollpanel.p-component {
      width: 100% !important;
      height: 7rem !important;
    }
    .p-scrollpanel-content {
      display: flex;
      flex-direction: row;
    }
    .p-scrollpanel-bar.p-scrollpanel-bar-x {
      background-color: none;
      opacity: unset;
    }
    .p-divider.p-divider-vertical {
      margin: 0 0.5rem;
    }

    .groupe_card_member_container {
      width: 15rem;
    }
    .membership_groupe_cards {
    }
    .divider_mobile {
      display: inline;
      height: 80%;
      width: 1px;
    }
    .divider_desktop {
      display: none;
    }
  }
}

@use "src/Styles/_vars.scss" as c;

#header {
  .menu {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 20rem;
    background-blend-mode: multiply;
    &__connected {
      width: 100%;
    }
    &__notConnected {
      min-width: 100% !important;
    }
  }
}

@media screen and (max-width: c.$small) {
  #header {
    .menu {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: none !important;
      height: fit-content;
      min-height: unset;
      &__connected {
        background-image: none;
        background-color: c.$bleu-paris1;
      }
      &__notConnected {
        background-color: c.$bleu-paris1;
        background-image: none;

        background-blend-mode: multiply;
      }
    }
  }
}

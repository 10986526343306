@use "src/Styles/_vars.scss" as c;

.groupe_publication {
  border: 3px solid c.$fond-page;
  box-shadow: 0px 2px 4px lightgray;
  margin: 2rem 0;
  .boutons_mobile {
    display: none;
  }
  &__top {
    padding: 1rem;
    &__input {
      padding: 1rem 0;
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      img {
        height: 4rem;
        margin-right: 0.5rem;
      }
      .p-inputtext.p-component {
        width: 90% !important;
        height: 4rem;
      }
    }
    h4 {
      color: c.$bleu-paris1;
      font-size: large;
    }
  }
  &__bottom {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    background-color: c.$fond-page;
    .btn-bleu {
      margin: 0;
      flex-wrap: nowrap;
    }
    .btn-bleu,
    .btn-blanc.left {
      border: none;
      margin: 0.5rem;
      height: 2rem;
    }
    .btn-blanc.left {
      background-color: transparent;
      color: c.$bleu-paris1;
    }
  }
}

// !important obligatoire car des fichiers override tout
.publications-voirPlus {
  display: flex;
  justify-content: center;
  align-items: center;
  button.publications-bouton:hover {
    background-color: transparent !important;
    text-decoration: underline;
    color: c.$bleu-paris1 !important;
  }
  button.publications-bouton {
    color: c.$bleu-paris1 !important;
  }
}

@media screen and (max-width: c.$medium) {
  .groupe_publication {
    .boutons_mobile {
      display: inline;
      svg {
        margin-right: 0;
      }
    }
    .boutons_desktop {
      display: none;
    }
  }
}

@use "src/Styles/_vars.scss" as c;

//?Pop up from Leaflet
.leaflet-popup-content-wrapper {
  padding: 0;
}

.leaflet-popup-content {
  margin: 0;
}

a.leaflet-popup-close-button {
  top: 0.2rem !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
  color: c.$blanc !important;

  & span {
    font-size: 1.5rem !important;
  }
}

.leaflet-popup-tip {
  display: none !important;
}

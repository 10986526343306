@use "src/Styles/_vars.scss" as c;

.cardHover_univ {
  margin-top: 1rem;
  margin-right: 2rem;
  padding: 1rem 1rem;
  box-shadow: 1px 1px 8px 1px c.$gris-bordure;
  &:hover {
    cursor: pointer;
    transform: scale(1.01);
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
  }
}

.carouselDesktopActus {
  .carousel-control-prev {
    width: unset;
  }
  .carousel-control-next {
    width: unset;
  }
}

.carouselEvent-button {
  display: flex;
  justify-content: center;
}
.carousel_actu_acceuil_alumni {
  .item-carousel {
    .actualite_container {
      width: 24rem !important;
    }
  }
}
// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$small) {
  .article_presentation {
    padding: 1rem 0 !important;
    p {
      margin: 0.5rem 2rem;
    }
    .article_presentation h1 {
      padding-bottom: 2rem;
    }
    .article_presentation p {
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 5rem;
      margin: 0;
    }
    .logos_univ {
      padding-left: 1rem;
      padding-bottom: 1rem;
      justify-content: center;
      padding-right: 1rem;
    }
    .p-accordion-header-text {
      margin-left: 0 !important;

      flex-wrap: wrap !important;
    }
    .p-accordion.p-component {
      width: 100%;
      .p-accordion-tab {
        width: 100%;
        border: none;
      }
      .p-accordion-toggle-icon.pi.pi-chevron-down,
      .p-accordion-toggle-icon.pi.pi-chevron-right {
        margin-left: -0.5rem !important;
      }
    }
    #pr_id_3_header_0.p-accordion-header-link {
      padding: 0;
    }
    .article_presentation {
      .logo_container {
        width: 90%;
        margin: 0 5%;
      }
      .presentation_title {
        padding: 0 1rem;
      }
    }
  }
}

@media screen and (max-width: c.$tiny) {
  .article_presentation {
    .p-accordion.p-component {
      .p-accordion-content {
        border: none !important;
        width: 100%;
        padding: 0;
        margin-left: 0rem;
      }
    }
  }
}

@media screen and (max-width: c.$medium) {
  .article_presentation {
    .logo_container {
      margin: 4% 0;
      width: 100%;
    }
  }
}

@media screen and (max-width: c.$normal) {
  .carousel-item {
    img {
      margin-top: 1rem;
      padding: 1rem 1rem;
      box-shadow: 1px 1px 8px 1px c.$gris-bordure;
    }
  }
  .carousel {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

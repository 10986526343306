@use "src/Styles/_vars.scss" as c;

.profil-form-demo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profilBarShowApercu {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  &__top,
  &__bottom {
    display: flex;
    width: 100%;
    background-color: c.$blanc;
    border-radius: 0.33rem;
    h2 {
      font-family: c.$font-gros-titres;
      margin-bottom: 0;
    }
    h3 {
      font-family: c.$font-sous-menu;
      font-size: 1.2rem;
    }
  }
  &__top {
    margin-bottom: 2rem;
    padding: 2rem;
    &__middle {
      margin: 0 1rem;
      &__parrain {
        background-color: c.$fond-parrain;
        color: c.$texte-parrain;
        border-radius: 0.33rem;
        height: 1.5rem;
        display: flex;
        align-items: center;
        width: 8.5rem;
        p {
          text-transform: uppercase;
          margin: 0.5rem 0.5rem 0.5rem 0.8rem;
          font-size: x-small !important;
        }
      }
    }
    &__image {
      width: 8rem;
      height: 8rem;
      margin: 0 2rem;
      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
        border-radius: 50%;
        border: 2px solid c.$bleu-paris1;
      }
    }
  }
  &__bottom {
    align-items: center;
    padding: 1rem;
    &__item {
      margin-left: 2rem;
      font-size: medium;
      padding-top: 0.5rem;
      label {
        margin-bottom: 0.25rem;
      }
    }
    &__logos {
      display: flex;
      margin-left: 2rem;
      a {
        margin-left: 0.25rem;
      }
    }
  }
}

@media screen and (max-width: c.$medium) {
  .profilBarShowApercu {
    &__bottom {
      flex-direction: column;
      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
@media screen and (max-width: c.$small) {
  .profilBarShowApercu {
    &__top {
      align-items: center;
      flex-direction: column;
      &__image {
        margin-bottom: 1rem;
      }
    }
  }
}

@use "src/Styles/_vars.scss" as c;

.groupe_card_suggestion {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  background-color: white;
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;
  img {
    margin-left: 1rem;
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    margin-right: 1rem;
  }
  .boutons_mobile {
    display: none;
  }
  img {
    height: 4rem;
    margin-right: 1rem;
  }
  &__content {
    h6 {
      font-family: c.$font-global;
      color: c.$bleu-paris1;
      font-size: c.$font-size-normal;
      font-weight: 600;
    }
    h6.description {
      font-weight: 500;
      font-family: c.$font-global;
    }
    .btn-blanc.left {
      margin: 0;
      height: 2rem;
      padding: 0 1rem;
    }
  }
}
@media screen and (max-width: c.$medium) {
  .groupe_card_suggestion {
    .boutons_mobile {
      display: inline;
    }
    .boutons_desktop {
      display: none;
    }
  }
}

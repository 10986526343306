@use "src/Styles/_vars.scss" as c;

.groupe_card_find_container:hover {
  box-shadow: 6px 8px 20px grey;
  cursor: pointer;
  transition: 0.3s;
}

.groupe_card_find_container {
  background-color: white;
  box-shadow: 3px 4px 10px lightgrey;
  height: 6.5rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  &__logo {
    width: 12%;
    margin-right: 0.5rem;
    img {
      width: 64px;
      height: 64px;
      margin-left: 1rem;
      height: 4rem;
      border-radius: 2rem;
      margin-right: 0.5rem;
      image-rendering: -webkit-optimize-contrast;
    }
  }
  &__info {
    width: 65%;
    h1 {
      font-family: c.$font-global;
      font-size: medium;
      margin: 0;
      padding: 0;
    }
    h1.title {
      color: c.$bleu-paris1;
      font-family: c.$font-sous-menu;

      font-size: medium;
      margin: 0;
      padding: 0;
    }
    h6 {
      font-family: c.$font-global;
      color: c.$bleu-paris1;
      font-size: medium;
      margin-bottom: 0.5rem;
      margin: 0 0 0.5rem 0;
      padding: 0;
    }
    h6.title {
      color: c.$bleu-paris1;
      font-family: c.$font-sous-menu;
      font-size: medium;
      margin: 0;
      padding: 0;
    }
    &__bottom {
      display: flex;
      justify-content: flex-start;
      color: c.$gris-picto;

      h2 {
        margin-right: 1rem;
        font-size: small;
        font-family: c.$font-global;
      }
    }
  }
  &__bouton {
    margin-right: 1rem;
    min-width: 10rem;
    .btn-blanc.left {
      margin: 0;
    }
  }
}

@media screen and (max-width: c.$small) {
  .groupe_card_find_container {
    flex-direction: column;
    height: fit-content;
    padding: 1rem;
    &__info {
      width: 100%;
      padding-top: 0.5rem;
      h6 {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &__bottom {
        justify-content: center;
      }
    }
    &__logo {
      margin-right: 0;
      width: unset;
    }
    .btn-blanc.left {
      padding: 0 0.5rem;
      height: 2rem;
    }
    &__bouton {
      margin-right: 0;
    }
  }
}
